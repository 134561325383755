import axios from "../../axios";
import {
  GET_TOKEN,
  GET_CRIF_DATA,
  GET_CRIF_DB_DATA,
  VERIFY_OTP_URL,
} from "../../constants";

// * API URLs (NEW)
import {
  LOAN_OFFER_AGREEMENT,
  GET_PROFILE_DETAILS,
  SEND_SMS_TO_CUSTOMER,
  RESEND_OTP_URL,
  GET_OFFER_SETTINGS,
  ADD_LOAN_OFFFER_DETAIL,
  GET_PRE_APPROVED_OFFER,
  REAL_TIME_LEAD_PUSH_URL,
  UPDATE_JOURNEY_LAST_VISITED_SLIDE,
  UPDATE_TIMESTAMP_APPLY_LINK,
  GET_LEAD_DETAILS,
  GET_CONSENT,
  CREATE_TIMELINE,
  SEND_LEAD_DETAILS_TO_ADMING_VIA_MAIL,
  ADD_JOURNEY,
  UPDATE_LEAD_STAGE_STATUS,
  GET_UPDATED_CUSTOMER_DETAILS,
} from "../../../constants/urls";

// * API to add journey data
export const verifyLeadOtp = async (payload) => {
  const { headers, data } = payload;

  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${VERIFY_OTP_URL}`,
    data,
    { headers }
  );
  return res?.data;
};

// * API to add journey data
export const updateLeadData = async (payload) => {
  const { headers, data, params } = payload;
  console.log("params=>", params);
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${ADD_JOURNEY}${params.appId}`,
    data,
    { headers }
  );
  return res?.data;
};

// * API to to send the SMS to customer
const sendSMSToCustomer = async (Payload) => {
  const { phone, headers } = Payload;
  const res = await axios.post(SEND_SMS_TO_CUSTOMER, { phone }, { headers });
  return res?.data;
};

// * API to re-send OTP to customer
const resendOTP = async (Payload) => {
  const { payload, headers } = Payload;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${RESEND_OTP_URL}`,
    payload,
    { headers }
  );
  return res?.data;
};

// * API to get LSP/CI profile details (Disabled)
const getLenderProfileDetails = async (Payload) => {
  const headers = { ...Payload };

  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}${GET_PROFILE_DETAILS}`,
    { headers }
  );
  return res?.data;
};

const getCRIFData = async (Payload) => {
  // * Get Auth token before executing API
  let authToken = null;
  authToken = localStorage.getItem("authToken");

  if (!authToken) {
    // * Auth token not availabe in local storage then fetch it.
    authToken = await getToken();
  }

  const headers = {
    ...Payload.headers,
    Authorization: `Bearer ${authToken}`,
  };
  delete Payload.headers;

  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${GET_CRIF_DATA}`,
    Payload,
    { headers }
  );
  return res?.data;
};

const getCRIFData_DB = async (Payload) => {
  // * Get Auth token before executing API
  let authToken = null;
  authToken = localStorage.getItem("authToken");

  if (!authToken) {
    // * Auth token not availabe in local storage then fetch it.
    authToken = await getToken();
  }
  const headers = {
    ...Payload.headers,
    Authorization: `Bearer ${authToken}`,
  };
  delete Payload.headers;

  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${GET_CRIF_DB_DATA}`,
    Payload,
    {
      headers,
    }
  );
  return res?.data;
};

const getToken = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}${GET_TOKEN}`
  );
  let authToken = res?.data.data.token;
  localStorage.setItem("authToken", authToken);

  return authToken;
};

const getOfferSettings = async (Payload) => {
  // * Get Auth token before executing API
  let authToken = null;
  authToken = localStorage.getItem("authToken");

  if (!authToken) {
    // * Auth token not availabe in local storage then fetch it.
    authToken = await getToken();
  }

  const headers = {
    ...Payload.headers,
    Authorization: `Bearer ${authToken}`,
  };

  delete Payload.headers;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${GET_OFFER_SETTINGS}`,
    Payload,
    { headers }
  );
  return res?.data;
};

const getLoanOfferAgreement = async (Payload) => {
  const { headers, pipeline_id } = Payload;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}${LOAN_OFFER_AGREEMENT}/${pipeline_id}`,
    { headers }
  );

  return res?.data;
};

const addLoanOfferDetails = async (Payload) => {
  const headers = Payload.headers;
  delete Payload.headers;

  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${ADD_LOAN_OFFFER_DETAIL}`,
    Payload,
    { headers }
  );

  return res?.data;
};

const getPreApprovedOffers = async (Payload) => {
  const headers = Payload.headers;
  delete Payload.headers;

  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${GET_PRE_APPROVED_OFFER}`,
    Payload,
    { headers }
  );

  return res?.data;
};

const pushRealTimeLead = async (Payload) => {
  const headers = Payload.headers;
  delete Payload.headers;

  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${REAL_TIME_LEAD_PUSH_URL}`,
    Payload,
    { headers }
  );

  return res?.data;
};

const updateJourneyLastVisitedSlide = async (Payload) => {
  const headers = Payload.headers;
  delete Payload.headers;

  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${UPDATE_JOURNEY_LAST_VISITED_SLIDE}`,
    Payload,
    { headers }
  );

  return res?.data;
};

// * API to update timestamp
const updateTimeStamp = async (Payload) => {
  const headers = Payload.headers;
  delete Payload.headers;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${UPDATE_TIMESTAMP_APPLY_LINK}`,
    Payload,
    { headers }
  );
  return res?.data;
};

// * API to get Lead Details
const getLeadDetails = async (Payload) => {
  const { pipelineId, leadId, headers } = Payload;

  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}${GET_LEAD_DETAILS}/${pipelineId}/data/${leadId}`,
    { headers }
  );
  return res?.data;
};

// * API to get Lead Details
const getCustomerConsent = async (Payload) => {
  const { pipeline_id, lead_id, contact_id, headers } = Payload;
  const data = { pipeline_id, lead_id, contact_id };

  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${GET_CONSENT}`,
    data,
    { headers }
  );
  return res?.data;
};

// * API to create a Timeline
const createTimeline = async (Payload) => {
  const { data, headers } = Payload;

  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${CREATE_TIMELINE}`,
    data,
    { headers }
  );
  return res?.data;
};

// * API to create a Timeline
const sendLeadDetailsToAdminViaMail = async (Payload) => {
  const { data, headers } = Payload;

  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${SEND_LEAD_DETAILS_TO_ADMING_VIA_MAIL}`,
    data,
    { headers }
  );
  return res?.data;
};

// * API to create a Timeline
const updateLeadStageStatus = async (Payload) => {
  const { data, headers } = Payload;

  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${UPDATE_LEAD_STAGE_STATUS}`,
    data,
    { headers }
  );
  return res?.data;
};

// * To fetch updated Customer Details
const updatedCustomerDetails = async (Payload) => {
  const { jsonData, headers } = Payload;

  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${GET_UPDATED_CUSTOMER_DETAILS}`,
    jsonData,
    { headers }
  );
  return res?.data;
};

export {
  updateLeadStageStatus,
  sendLeadDetailsToAdminViaMail,
  getLenderProfileDetails,
  getCRIFData,
  getCRIFData_DB,
  getToken,
  updateTimeStamp,
  sendSMSToCustomer,
  resendOTP,
  getOfferSettings,
  getLoanOfferAgreement,
  addLoanOfferDetails,
  getPreApprovedOffers,
  pushRealTimeLead,
  updateJourneyLastVisitedSlide,
  getLeadDetails,
  getCustomerConsent,
  createTimeline,
  updatedCustomerDetails,
};
