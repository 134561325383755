import style from "../style.module.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const Feature = ({ content }) => {
  return (
    <div className={style.loanBenefitHeading}>
      <CheckCircleOutlineIcon sx={{ color: "#2e3092", fontSize: "18px" }} />
      <h5>{content}</h5>
    </div>
  );
};

export default function LoanDetails({ scrollPosition }) {
  return (
    <div
      className={`${style.personalLoanDetails} ${
        scrollPosition > 20
          ? style.showPersonalLoanDetails
          : style.hidePersonalLoanDetails
      }`}
    >
      <div className={style.personalLoanDetailsBox}>
        <h4 className={style.heading}>Personal Loan Features And Benefits</h4>
        <h5>Loan tenor & interest rate</h5>
        <div className={style.features}>
          <Feature content="Instant loan upto 1 lac." />
          <Feature content="Tenor - 4 to 6 month" />
          <Feature content="ROI - starting from 2% p.m" />
        </div>
      </div>

      <section className={style.section}>
        <h4 className={style.heading}>Fast Approval</h4>
        <div className={style.features}>
          <Feature content="We'll give you a fast outcome on your application" />
        </div>
      </section>

      <section className={style.section}>
        <h4 className={style.heading}>Other Charges</h4>
        <div className={style.features}>
          <Feature content="Processing fees - 6%" />
          <Feature content="File charge - 100 rs" />
          <Feature content="Nach bounce 1000 rs per bounce" />
          <Feature content="Late payment charges - 3%" />
          <Feature content="Foreclosure charges - 4%" />
          <Feature content="GST - 18%" />
        </div>
      </section>

      <section className={style.section}>
        <h4 className={style.heading}>Personal Loan Eligibility</h4>
        <div className={style.list}>
          <ol>
            <li>You must be a salaried or self employed</li>
            <li>Age: 18yrs to 60yrs</li>
            <li>
              Must be an Indian citizen, minimum monthly income should be 20,000
            </li>
          </ol>
        </div>
      </section>

      <section className={style.section}>
        <h4 className={style.heading}>How It Works?</h4>
        <div className={style.list}>
          <ol>
            <li>
              100% digital online application which takes less than 5 minutes
            </li>
            <li>
              Get the loan amount disbursed within hours of signing the
              agreement
            </li>
            <li>
              Enjoy the flexibility of manageable monthly installments tailored
              to fit your budget.
            </li>
          </ol>
        </div>
      </section>

      <section className={style.section}>
        <h4 className={style.heading}>Documents required</h4>
        <div className={style.list}>
          <ol>
            <li>
              KYC documents: Aadhaar/passport/voter's ID/ driving license/Letter
              of National Population Register/NREGA job card
            </li>
            <li>PAN card</li>
            <li>Bank account statements of the previous 3 months</li>
            <li>Utility or Broadband/Wi-fi Bill</li>
          </ol>
        </div>
      </section>
    </div>
  );
}
