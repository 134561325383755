import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
// * Styles
import style from "./SalarySlip.module.css";
import queryString from "query-string";
// * Components
import ImageInputField from "../../../DataInputFields/ImageInputField/ImageInputField";
import DocumentPreview from "../../../DocumentPreview/DocumentPreview";
import ContinueButton from "../../../Buttons/SlideContinueButton/ContinueButton";
// * Icons
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import InfoIcon from "@mui/icons-material/Info";
// import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
// * API Handler
import { useMutation } from "@tanstack/react-query";
// * APIs
import {
  uploadCustomerDocument,
  verifyCustomerDocument,
  analyzeSalarySlipAuthBridge,
} from "../../../../api/services/documents";
// * Helpers
import {
  convertBase64,
  getLocationPermission,
} from "../../../../helpers";
import { useLocation } from "react-router-dom";
// * Notificaiton
import { toast } from "react-toastify";
// * Constants
import { DOMAIN_NAME, TOTAL_SLIDES } from "../../../../constants";
// * Contexts
import CustomerContext from "../../../../context/CustomerContext";

export default function SalarySlipVerificationSlide({
  switchToNextSlide,
  updateLastVisitSlide,
}) {
  // * Number of Salary Slips need to be taken from customer
  const SALARY_SLIPS = 3;
  const { apiKey, apiSecret } = useParams();
  const { customerDetails } = useContext(CustomerContext);

  const [documentsUploaded, setDocumentsUploaded] = useState(0);
  const [document, setDocument] = useState([]);

  const [documentStatus, setDocumentStatus] = useState({
    error: false,
    hasDocument: false,
  });

  useEffect(() => {
    if (documentsUploaded >= 3) {
      const slidePayload = {
        headers: { apiKey, apiSecret },
        leadId: customerDetails?.leadId,
        slideName: "Bank Statement",
        slideIndex: 21,
        slideIcon: "Bank Statement",
        totalSlides: TOTAL_SLIDES,
        journeyVersion: JourneyVersion,
      };
      // * Update Journey last visit slide
      updateLastVisitSlide(slidePayload);

      // * Mark that we have got all our required documents
      setDocumentStatus({ error: false, hasDocument: true });

      switchToNextSlide();
    }
  }, [documentsUploaded]);

  // const compareSalaryResponses = () => {
  //   if (
  //     salaryResponses.length === 3 &&
  //     customerDetails.userType === "Credit Institution"
  //   ) {
  //     console.log("salaryResponses : ", salaryResponses);
  //     let allBankAccountNumbersSame = salaryResponses.every((salary) => {
  //       if (salary["bank_ac_no"]) {
  //         return salary["bank_ac_no"] === salaryResponses[0]["bank_ac_no"];
  //       } else {
  //         return false;
  //       }
  //     });

  //     console.log("allBankAccountNumbersSame : ", allBankAccountNumbersSame);
  //     if (allBankAccountNumbersSame) {
  //       setCustomerDetails((prev) => ({
  //         ...prev,
  //         salarySlipBankDetails: salaryResponses[0],
  //       }));
  //       setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 26, { path: CookiePath });
  //       switchToNextSlide();
  //     } else {
  //       toast.error(
  //         "Bank details in the salary slips doesn't match, please upload salary slips with same bank details."
  //       );
  //     }
  //   }
  // };

  // * AuthBridge Salary Slip Analyzer
  const analyzeSalarySlipAuthBridgeAPI = useMutation({
    mutationFn: (payload) => analyzeSalarySlipAuthBridge(payload),
    onSuccess: (data) => handleAnalyzeSalarySlipSuccess(data),
    retry: false,
  });
  const handleAnalyzeSalarySlipSuccess = (data) => {
    console.log("Salary Slip : ", data);
  };
  const handleAnalyzeSalarySlip = (documentId) => {
    const headers = { apiKey, apiSecret };
    const data = { documentId };
    const payload = { headers, data };

    analyzeSalarySlipAuthBridgeAPI.mutate(payload);
  };

  // * API to verify the document
  const verifyCustomerDocumentAPI = useMutation({
    mutationKey: ["uploadCustomerDocument"],
    mutationFn: (Payload) => verifyCustomerDocument(Payload),
    onSuccess: (data) => handleVerifyCustomerDocumentSuccess(data),
    onError: (error) => handleVerifyCustomerDocumentError(error),
    retry: false,
  });

  const handleVerifyCustomerDocumentSuccess = (data) => {
    console.log("analyzedSalary : ", data.data.data);

    // setSalaryResponses((prev) => [...prev, analyzedSalary]);
  };
  const handleVerifyCustomerDocumentError = (error) => {
    toast.error("Somethign went wrong, please try again.");
  };

  // * API to upload Bank Statement only as document
  const uploadCustomerDocumetAPI = useMutation({
    mutationKey: ["uploadCustomerDocument"],
    mutationFn: (Payload) => uploadCustomerDocument(Payload),
    onSuccess: (data) => handleDocumentUploadSuccess(data),
    onError: (error) => handleDocumentUploadError(error),
    retry: false,
  });

  let url = useLocation().search;
  let queryValues = queryString.parse(url);
  const JourneyVersion = queryValues?.jrnyTyp;

  const handleDocumentUploadSuccess = (data) => {
    setDocumentsUploaded((prev) => prev + 1);

    if (
      customerDetails.userType === "Credit Institution" ||
      DOMAIN_NAME === "consultancymars1"
    ) {
      // const headers = {
      //   apiKey,
      //   apiSecret,
      // };
      // ! (OLD) Verify the document
      // verifyCustomerDocumentAPI.mutate({
      //   ContactID: data.data.contact_id,
      //   DocumentID: data.data.id,
      //   headers,
      // });

      // (NEW - AuthBridge)
      handleAnalyzeSalarySlip(data.data.id);
    }
  };
  const handleDocumentUploadError = (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("authToken");
      saveSalarySlip();
    } else {
      toast.error("Something went wrong! please try again.");
    }
  };

  // * Function to save customer's salary slip
  const saveSalarySlip = async () => {
    if (!document.length) {
      setDocumentStatus({ error: true, hasDocument: false });
      return;
    }

    const location = await getLocationPermission();

    for (let i = 0; i < document.length; i++) {
      await convertBase64(document[i]).then((base64) => {
        const headers = {
          apiKey,
          apiSecret,
        };
        let Payload = {
          contact_id: customerDetails.contactID,
          pipeline_id: customerDetails.pipelineID,
          lead_id: customerDetails.leadId,
          docUpload: base64,
          fileName: document[i].name,
          docType: "salarySlip",
          docFormat: "",
          docNumber: "",
          source_name: "Journey",
          headers,
        };

        if (location) {
          Payload = { ...Payload, ...location };
        }

        if (document[i].type.includes("image")) {
          Payload = { ...Payload, docFormat: "image" };
        } else if (document[i].type === "application/pdf") {
          Payload = { ...Payload, docFormat: "pdf" };
        } else {
          toast.error("Please upload document in Image or PDF only.");
          return;
        }

        uploadCustomerDocumetAPI.mutate(Payload);
      });
    }
  };

  const removeDocument = (documentName) => {
    const documents = document.filter((doc) => doc.name !== documentName);
    if (!documents.length) {
      setDocumentStatus({ error: true, hasDocument: false });
      setDocument([]);
    } else setDocument(documents);
  };

  const validateDocs = () => {
    const { hasDocument } = documentStatus;

    if (hasDocument) {
      switchToNextSlide();
    } else if (!hasDocument && document.length === 0) {
      setDocumentStatus({ ...documentStatus, error: true });
    } else if (!hasDocument && document.length > 0) {
      saveSalarySlip();
    }
  };

  return (
    <div className="carouselSlideWrapper salarySlipAndBankStatementSlideWrapper">
      <div className="typographyContainer">
        <h1>Income Verification</h1>
        <p>Please upload your last 3 months salary slips</p>
      </div>

      <div className="dataFieldsContainer imageInputContainer">
        <div className={style.documentInputBox}>
          {document
            ? document.map((doc, index) => (
                <DocumentPreview
                  key={`${doc.name}_${index}`}
                  document={doc}
                  removeDocument={removeDocument}
                  removeTitle="Delete Salary Slip"
                />
              ))
            : null}
          <ImageInputField
            label="Upload Salary Slip"
            icon={
              <ReceiptOutlinedIcon
                style={{ fontSize: "45px", color: "rgb(189, 205, 255)" }}
              />
            }
            accept="image/png, image/jpeg, image/png, application/pdf"
            allowedFileTypes="jpg, jpeg, png, pdf"
            file={document}
            setFile={setDocument}
            // setFile={handleSetDocument}
            // handler={saveSalarySlip}
            // handler={() =>
            //   setDocumentStatus({ error: false, hasDocument: true })
            // }
            isDocAvailable={documentStatus.error}
            isLoading={uploadCustomerDocumetAPI.isPending ? true : false}
            hasDocument={documentStatus.hasDocument}
            multiple={true}
            max={SALARY_SLIPS}
          />
        </div>
        <div className="">
          <ul>
            <li>
              <InfoIcon style={{ color: "#404040", fontSize: "20px" }} />
              Please do not upload password protected file.
            </li>
          </ul>
        </div>
      </div>

      <div className="imageInputContinueBtn">
        <div className={style.continueBtn}>
          <ContinueButton
            // label={documentStatus.hasDocument ? "Continue" : "Upload Documents"}
            label="Next"
            loading={
              uploadCustomerDocumetAPI.isPending
              // verifyCustomerDocumentAPI.isPending ||
              // analyzeSalarySlipAuthBridgeAPI.isPending
            }
            handler={validateDocs}
            disabled={document.length < SALARY_SLIPS ? true : false}
          />
        </div>
      </div>
    </div>
  );
}
