// * Style
import style from "./DocumentPreview.module.css";
// * Icons
import ClearIcon from "@mui/icons-material/Clear";

export default function DocumentPreview({
  document = false,
  removeTitle,
  removeDocument,
}) {
  return (
    <div className={style.preview}>
      {document && document.type.includes("image") && (
        <div className={style.imagePreview}>
          <img src={URL.createObjectURL(document)} alt="Preview" />
        </div>
      )}

      {document && document.type == "application/pdf" && (
        <div className={style.pdfPreview}>
          <embed
            src={URL.createObjectURL(document)}
            className={style.previewDoc}
            width="90"
            height="90"
          ></embed>
        </div>
      )}

      <button
        className={style.deleteDocPreviewBtn}
        title={removeTitle ? removeTitle : "Remove"}
        onClick={() => removeDocument(document.name)}
      >
        <ClearIcon className={style.deleteDocPreviewBtnIcon} />
      </button>
    </div>
  );
}
