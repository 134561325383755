export const GET_OTP = "GET_OTP";
export const VERIFY_OTP = "VERIFY_OTP";
export const ADD_JOURNEY = "ADD_JOURNEY";
export const RESEND_OTP = "RESEND_OTP";
export const GET_BUREAU_AUTO_PULL = "GET_BUREAU_AUTO_PULL";
export const GET_BUREAU_AUTO_PULL_SUCCESS = "GET_BUREAU_AUTO_PULL_SUCCESS";
export const GET_JOURNEY_FIELDS = "GET_JOURNEY_FIELDS";
export const GET_JOURNEY_FIELDS_SUCCESS = "GET_JOURNEY_FIELDS_SUCCESS";
export const GET_REAL_TIME_LEAD_PUSH = "GET_REAL_TIME_LEAD_PUSH";
export const GET_REAL_TIME_LEAD_PUSH_SUCCESS =
  "GET_REAL_TIME_LEAD_PUSH_SUCCESS";
export const GET_REAL_TIME_LEAD_PUSH_ERROR = "GET_REAL_TIME_LEAD_PUSH_ERROR";
export const GET_PROFILE_DETAILS = "GET_PROFILE_DETAILS";
export const GET_PROFILE_DETAILS_SUCCESS = "GET_PROFILE_DETAILS_SUCCESS";
