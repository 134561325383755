export default function ConsentV2() {
  return (
    <div className="policyContainer">
      <h2>CONSENT</h2> Consent in favour of HDFC Bank Ltd. (“ Bank ”) (given
      indirectly or through service provider or any platform or app) 1. In this
      Consent the following capitalized terms shall have the meanings assigned
      to them hereunder: “Data” shall mean all personal data, sensitive personal
      data or information, transactional data, Derivative Data, any other
      information, etc., in relation to me/us, including the following including
      in relation to past Products: (a) know your customer (KYC)/anti money
      laundering (AML) data; (b) information submitted while making any
      application or request to the Bank for any Product; (c) any transactional
      data generated during the relationship or as a result of any transaction,
      statements, entries, logs, in relation thereto; (d) any information
      obtained/received by the Bank from any other source; (e) any Derivative
      Data. “Derivative Data” shall mean any credit scores, credit information,
      behavioural projections, profiling, analytical results, reports (prepared
      by the Bank internally or other persons) including through any algorithms,
      analytics, software, automations, profiling etc., and whether such
      derivative is from the information collected from me/us or in combination
      with any other information sourced from any other person, database or
      source whether by the Bank or other persons. The process of arriving at
      and generation of such Derivative Data involving or through any of the
      above sub-processes/methods, shall be referred to as “Derivation”.
      “Specified Purposes” shall collectively mean, credit assessment, risk
      assessment, risk analysis, obtaining credit information reports, scores,
      scrubs, fraud checks, fraud detections, fraud prevention, detecting and
      preventing crime including crime/ terror funding, detecting malpractices
      or discrepant documents or information, prevention of misuse, assessment
      of credit worthiness, financial standing, due diligence, background check,
      physical and other inspections, verifications, obtaining any reports for
      any of the above, KYC/ AML checks, customer service, monitoring,
      collections, default detection, default prevention, default investigation,
      recovery, any legal proceedings, actions, enquiries, investigations,
      pursuing any remedies, enforcing rights, reporting including credit
      reporting, KYC reporting, default reporting, filing, perfections etc.,
      whether any of these are undertaken internally or through any credit
      information company, bureau, service provider, consultant, vendor, agent,
      fintech entity, co- brand entity/partner, distributor, selling/ marketing
      agent, any partner, other player/ intermediary in any ecosystem of which
      the Bank is a part, TPAP (for whom the Bank acts as PSP bank),
      collaborator, co-lender, co- originator, merchant, aggregator, lead
      generator, sourcing entity, client, customer or other person with whom the
      Bank has a tie-up or contract for any products or services, person or
      through a combination of multiple options. Each of such credit information
      company, bureau, service provider, consultant, vendor, agent, fintech
      entity, co- brand entity/partner, person, and their respective service
      providers, consultants, vendors, etc is referred to as a “Processing
      Entity”. “Product(s)” shall mean products, services and/or businesses of
      the Bank/ of subsidiaries/ affiliates, or where the Bank/ its
      subsidiaries/ affiliates distribute, refer or act as agent or act as a
      sponsor bank or a PSP bank etc. in relation to any products or services
      (including where the initiation of any transaction is not directly with
      the Bank but is with a relevant Processing Entity like in case of a UPI
      transfer through a TPAP where the account is not with the Bank but it is a
      PSP bank), whether the Bank is in direct relationship or indirect
      relationship through any other intermediary/ entity, vis-à-vis me/us, as
      also if I/we are an authorised signatory or authorised person or
      representative of a non-individual applicant/ customer/ user of any
      services, whether direct or indirect. The Products, which have been
      applied/ requested by or availed by, me/us (including where the initiation
      of any transaction is not directly with the Bank but is with a relevant
      Processing Entity like in case of a UPI transfer through a TPAP where
      my/our account is not with the Bank but the Bank is a PSP bank), shall be
      referred to as 112022.LSP. V.2.0 “Requested Products”, and the Products
      (including any future products or services) other than the Requested
      Products, shall be referred to as “Other Products”. 2. I have read,
      understood and hereby accept the Privacy Policy of HDFC Bank Limited
      (“Bank” which which expression shall be deemed to include its successors
      and assigns), available at www.hdfcbank.com. 3. I/we hereby authorize
      Roopya to share Data with the Bank or to its order. 4. I/we hereby further
      authorize the Bank to: a. process, use, store, retain, share with
      Processing Entities or collect from any Processing Entities or other
      databases, sources, persons/entities, the Data or any part thereof, for
      any of the Specified Purposes: i. for Derivation and sharing any
      Derivative Data (between Bank and any Processing Entity) in connection
      with the aforesaid purposes; ii. contacting, establishing contact,
      whereabouts, including through email, postal address, telephone, social
      media, banners on applications/ electronic platforms, notifications,
      website, premises of third parties/ other persons in connection with the
      aforesaid purposes; iii. deploying any analytics, automated processing,
      algorithms, robotics, profiling, encryptions, coding, anonymizations,
      etc., for any of the aforesaid. b. For conveying to, displaying or
      communicating with, marketing, selling, cross-selling to me/us, by the
      Bank internally or externally, any Products and for such purpose for
      assessing your credit worthiness or your eligibility through such means as
      feasible and for such activity sharing the Data with/ receive from third
      parties, including through or on the Bank’s apps/ platforms, any other
      channel(s) of the Bank, TPAP, service providers, agents, or otherwise
      through notifications, emails or other means of communications, whether
      online or offline or telecommunications, the availability or eligibility
      or offer, whether in principle or otherwise, of any of the Other Products,
      and for this purpose all authorisations mentioned in (i) to (iii) of 3 (a)
      above in relation thereto. 5. I/we authorize the Processing Entities to
      (to the extent required by the Bank) process, use, store, retain, share
      with the Bank or the other Processing Entities or collect from Bank, any
      other Processing Entities or other databases, sources, persons/entities,
      the Data or any part thereof, for any of the aforesaid consented purposes.
      6. For the aforesaid consents, authorizations and purposes covered above,
      it shall be deemed that I/we have furnished all the Data separately under
      this consent. 7. I/we agree that the aforesaid consents/ authorizations
      for the aforesaid purposes shall survive beyond the validity of such
      application/ tenure of the Product/ consummation of any transaction. 8.
      The consents given or denied under this document do not limit any other
      consents obtained or given. 112022.LSP. V.2.0{" "}
    </div>
  );
}
