import { PDFDocument } from "pdf-lib";

export function calculteEMI(loanAmount, interestRate, loanTenureMonths) {
  if (!loanAmount || !interestRate || !loanTenureMonths) return;

  // Convert annual interest rate to monthly rate and percentage to decimal
  const monthlyInterestRate = interestRate / 12 / 100;

  let emi;
  // Calculate EMI
  const numerator =
    loanAmount *
    monthlyInterestRate *
    Math.pow(1 + monthlyInterestRate, loanTenureMonths);
  const denominator = Math.pow(1 + monthlyInterestRate, loanTenureMonths) - 1;
  emi = numerator / denominator;

  return Math.ceil(emi);
}

export function calculateEMIWithLoanAmount(
  loanAmount,
  interestRate,
  loanTenureMonths,
  FOIR_Limit,
  loanDecreasingStep,
  monthlyObligations,
  monthlyIncome
) {
  let emi,
    maxLoanAmount = loanAmount;

  // Loop until get the best loan amount that FOIR doesn't exceed the FOIR limit
  do {
    // Calculate EMI
    emi = calculteEMI(maxLoanAmount, interestRate, loanTenureMonths);

    // Calculate new FOIR after adding new EMI in already running EMIs
    let NewCustomerFOIR = Math.ceil(
      ((monthlyObligations + emi) * 100) / monthlyIncome
    );

    // New FOIT Limit is less than or equal to FOIR limit then break
    if (NewCustomerFOIR <= FOIR_Limit) break;

    // Decrease the loan amount if customer's latest FOIR is exceeding the FOIR limit
    maxLoanAmount = maxLoanAmount - loanDecreasingStep;
  } while (true);

  return { emi: emi, maxLoanAmount: maxLoanAmount };
}

export function calculateEMI(loanAmount, annualInterestRate, tenureMonths) {
  const monthlyInterestRate = annualInterestRate / 12 / 100; // Convert annual rate to monthly and percentage to decimal
  const emi =
    (loanAmount *
      monthlyInterestRate *
      Math.pow(1 + monthlyInterestRate, tenureMonths)) /
    (Math.pow(1 + monthlyInterestRate, tenureMonths) - 1);

  return {
    installments: tenureMonths,
    EMI: parseFloat(emi.toFixed(2)), // Rounds the EMI to 2 decimal places
  };
}

// * Function to create the form data (Used in Add Course , Edit Course APIs)
export function multiPartData(data, type = "") {
  let multiPart = new FormData();
  for (let key in data) {
    if (type === "multiple" && key === "blogImage") {
      data[key].forEach((file) => {
        multiPart.append(key, file);
      });
    } else if (key !== "blogImage") {
      multiPart.append(key, data[key]);
    }
  }

  return multiPart;
}

export function getFileSize(file) {
  if (!file) {
    throw new Error("No file selected");
  }
  const fileSize = file.size; // Size in bytes
  // Convert bytes to kilobytes (1 KB = 1024 bytes)
  const fileSizeInKB = Math.floor(fileSize / 1024);
  // You can also convert to other units like megabytes if needed
  return fileSizeInKB;
}

export function getCustomerMonthlyObligation(CRIF_Data) {
  let monthlyObligations = 0;
  if (CRIF_Data) {
    let data = CRIF_Data["B2C-REPORT"].RESPONSES;
    for (let i = 0; i < data.length; i++) {
      let loan = data[i].RESPONSE["LOAN-DETAILS"];

      let monthlyInstallment =
        loan?.["ACCOUNT-STATUS"] === "Active"
          ? loan?.["INSTALLMENT-AMT"]
          : null;
      if (monthlyInstallment) {
        monthlyInstallment = getNumberFromString(monthlyInstallment);
        monthlyObligations = monthlyObligations + monthlyInstallment;
      }
    }

    return monthlyObligations;
  }
  return monthlyObligations;
}

function getNumberFromString(stringWithNumber) {
  return parseInt(stringWithNumber.replace(/[^0-9]/g, ""));
}

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      alert("please add image/pdf file");
    };
  });
};

export const getBase64Value = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result.split(",")[1]);
    };

    fileReader.onerror = (error) => {
      alert("please add image/pdf file");
    };
  });
};

export function calculateAge2(dob) {
  // Parse the date of birth string into a Date object
  var dobDate = new Date(dob);

  // Get the current date
  var currentDate = new Date();

  // Calculate the difference in milliseconds
  var ageInMilliseconds = currentDate - dobDate;

  // Convert the age from milliseconds to years
  var ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);

  // Round down to the nearest whole number
  var age = Math.floor(ageInYears);

  return age;
}

const calculateImageLuminance = (img) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.width = img.width;
  canvas.height = img.height;
  ctx.drawImage(img, 0, 0);
  const imageData = ctx.getImageData(0, 0, img.width, img.height);
  const data = imageData.data;
  let sum = 0;

  for (let i = 0; i < data.length; i += 4) {
    // Calculate luminance (average of red, green, and blue)
    const luminance = (data[i] + data[i + 1] + data[i + 2]) / 3;
    sum += luminance;
  }

  const averageBrightness = sum / (data.length / 4);
  return averageBrightness;
};

// Function to calculate average brightness of an image
export function validateImageQuality(imageFile) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    if (typeof imageFile === "string") {
      img.src = imageFile;
    } else {
      img.src = URL.createObjectURL(imageFile);
    }

    // Wait for the image to load
    img.onload = function () {
      const averageBrightness = calculateImageLuminance(img);
      resolve(averageBrightness);
    };

    img.onerror = function (error) {
      reject(error); // In case of an error, reject the promise
    };
  });
}

export function removeAllCookies(path) {
  const cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie =
      name +
      `=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=${path ? path : "/"}`;
  }
}

export function isCookieExpired(cookieName) {
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${cookieName}=`))
    ?.split("=")[1];

  if (cookieValue) {
    const expirationDate = new Date(cookieValue);
    return expirationDate.getTime() < Date.now();
  }

  return true; // Cookie not found, so it's considered expired
}

export function getCookiePathFromURL(url) {
  var match = url.match(/^https?:\/\/[^\/]+(\/[^\?]+)/);
  if (match) {
    return match[1];
  }
  return null;
}

export const formatNumber = (number) => {
  const formatter = new Intl.NumberFormat("en-IN", {
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    compactDisplay: "long",
  });
  return formatter.format(number);
};

export const getLocationPermission = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          resolve({ latitude, longitude });
        },
        (error) => {
          // reject(`Error getting location: ${error.message}`);
          resolve(null);
        }
      );
    } else {
      // reject("Geolocation is not supported by this browser");
      resolve(null);
    }
  });
};
// * Input : 2023-10-09 18:27:40.997669 IST (GMT +0530) | Output : October 9, 2023 at 11:57:40 PM
export function parseCustomDate(dateTimeString) {
  const inputDate = new Date(dateTimeString);
  // * Add 5 Hours and 30 Minutes
  const adjustedDate = new Date(inputDate.getTime());

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const month = months[adjustedDate.getMonth()];
  const day = adjustedDate.getDate();
  const year = adjustedDate.getFullYear();
  const hours = String(adjustedDate.getHours() % 12 || 12).padStart(2, "0"); // Convert to 12-hour format
  const minutes = String(adjustedDate.getMinutes()).padStart(2, "0");
  const seconds = String(adjustedDate.getSeconds()).padStart(2, "0");
  const ampm = adjustedDate.getHours() >= 12 ? "PM" : "AM"; // Determine AM or PM

  const formattedDate = `${month} ${day}, ${year} at ${hours}:${minutes}:${seconds} ${ampm}`;
  return formattedDate;
}

export const hasSpecialCharacters = (string) => {
  const specialCharacterRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  return specialCharacterRegex.test(string);
};

export const hasNumbers = (string) => {
  const numbersRegex = /\d/;
  return numbersRegex.test(string);
};

export const isAlphanumeric = (string) => {
  const alphanumericRegex = /^[a-zA-Z0-9]+$/;
  return alphanumericRegex.test(string);
};

export const containsOnlyNumbers = (string) => {
  const numbersRegex = /^\d+$/;
  return numbersRegex.test(string);
};

export const containsOnlyCharacters = (string) => {
  const charactersRegex = /^[a-zA-Z]+$/;
  return charactersRegex.test(str);
};

export const isBlank = (string) => (string ? false : true);

export const trimString = (string) => string?.replace(/\s+/g, " ")?.trim();

export const isPDFPasswordProtected = async (pdfBytes) => {
  try {
    const pdfDoc = await PDFDocument.load(pdfBytes, { ignoreEncryption: true });
    return pdfDoc.isEncrypted();
  } catch (error) {
    return true; // If an error occurs, it is likely due to encryption
  }
};

const units = [
  "",
  "One",
  "Two",
  "Three",
  "Four",
  "Five",
  "Six",
  "Seven",
  "Eight",
  "Nine",
  "Ten",
  "Eleven",
  "Twelve",
  "Thirteen",
  "Fourteen",
  "Fifteen",
  "Sixteen",
  "Seventeen",
  "Eighteen",
  "Nineteen",
];

const tens = [
  "",
  "",
  "Twenty",
  "Thirty",
  "Forty",
  "Fifty",
  "Sixty",
  "Seventy",
  "Eighty",
  "Ninety",
];

const convertHundreds = (number) => {
  let result = "";

  if (number > 99) {
    result += units[Math.floor(number / 100)] + " Hundred ";
    number %= 100;
  }

  if (number > 19) {
    result += tens[Math.floor(number / 10)] + " ";
    number %= 10;
  }

  if (number > 0) {
    result += units[number] + " ";
  }

  return result.trim();
};

export const convertNumberToWords = (number) => {
  if (number === 0) return "Zero";

  let word = "";

  if (number >= 10000000) {
    // Crore
    word += convertHundreds(Math.floor(number / 10000000)) + " Crore ";
    number %= 10000000;
  }

  if (number >= 100000) {
    // Lakh
    word += convertHundreds(Math.floor(number / 100000)) + " Lakh ";
    number %= 100000;
  }

  if (number >= 1000) {
    // Thousand
    word += convertHundreds(Math.floor(number / 1000)) + " Thousand ";
    number %= 1000;
  }

  if (number > 0) {
    word += convertHundreds(number);
  }

  return word.trim();
};
export const extractNumber = (str) => {
  const cleanedStr = str.replace(/,/g, "");

  const num = cleanedStr.match(/-?\d+(\.\d+)?/g);
  return num ? parseFloat(num[0]) : null;
};
