import {
  Document,
  Page,
  View,
  StyleSheet,
  Text,
  Font,
} from "@react-pdf/renderer";

// * Components
import MyFinDocHeader from "../Agreements/HeaderAndFooter/MyFinDoc/Header";
import MyFinDocFooter from "../Agreements/HeaderAndFooter/MyFinDoc/Footer";

// * Fonts
import ShadowsIntoLight from "../../fonts/Shadow Into Light.ttf";
import Caveat from "../../fonts/Caveat.ttf";
import Abel from "../../fonts/Abel.ttf";
import PlayfairDisplay from "../../fonts/Playfair Display.ttf";
import Satisfy from "../../fonts/Satisfy.ttf";

// * Constants
import { DOMAIN_NAME } from "../../constants";

Font.register({
  family: "Shadows Into Light",
  src: ShadowsIntoLight,
});
Font.register({
  family: "Caveat",
  src: Caveat,
});
Font.register({
  family: "Abel",
  src: Abel,
});
Font.register({
  family: "Playfair Display",
  src: PlayfairDisplay,
});
Font.register({
  family: "Satisfy",
  src: Satisfy,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 35,
    height: "100%",
  },
  header: {
    marginBottom: 10,
  },
  body: {
    flex: 1,
  },
  pageNumber: {
    fontSize: 10,
    textAlign: "center",
    color: "grey",
  },
  footer: {
    marginTop: 10,
  },
  footerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: 6,
    textAlign: "center",
    color: "grey",
  },
});

const PDF = ({ size, body, headerContent, footerContent }) => {
  const getHeaderByDomain = () => {
    switch (DOMAIN_NAME) {
      case "myfindoc":
        return <MyFinDocHeader headerContent={headerContent} />;
      default:
        return "";
    }
  };
  const getFooterByDomain = () => {
    switch (DOMAIN_NAME) {
      case "myfindoc":
        return <MyFinDocFooter footerContent={footerContent} />;
      default:
        return null;
    }
  };

  return (
    <Document>
      <Page size={size} style={styles.page}>
        {/* Document Header */}
        <View style={styles.header} fixed>
          {getHeaderByDomain()}
        </View>

        {/* Document Body */}
        <View style={styles.body}>{body}</View>

        {/* Document Footer */}
        <View style={styles.footer} fixed>
          {getFooterByDomain()}

          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
          />
        </View>
      </Page>
    </Document>
  );
};

export default PDF;
