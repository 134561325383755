import addJourneySaga from "./addJourneySaga";
import { all } from "redux-saga/effects";
import getOtpSaga from "./getOtpSaga";
import journeyFieldsSaga from "./journeyFieldsSaga";
import resendOtpSaga from "./resendOtpSaga";
import verifyOtpSaga from "./verifyOtpSaga";
import realTimeLeadPushSaga from "./realTimeLeadPushSaga";
import profileSaga from "./profileSaga";
import autoBureauPullSaga from "./autoBureauPullSaga";
export default function* rootSaga() {
  yield all([
    journeyFieldsSaga(),
    getOtpSaga(),
    verifyOtpSaga(),
    autoBureauPullSaga(),
    addJourneySaga(),
    resendOtpSaga(),
    realTimeLeadPushSaga(),
    profileSaga(),
  ]);
}
