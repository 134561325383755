import style from "./Signature.module.css";

import { useEffect, useRef, useState, useContext } from "react";

import Webcam from "react-webcam";
import SignatureCanvas from "react-signature-canvas";
import HtmlToReact from "html-to-react";

// * Components
import DropDownField from "../DataInputFields/DropDownField/DropDownField";
import ContinueButton from "../Buttons/SlideContinueButton/ContinueButton";
import PDF from "../PDF/PDF";

// * Contexts
import CustomerContext from "../../context/CustomerContext";

// * Utilities
import {
  fetchDocumentVariables,
  handleDocumentVariableChange,
  removeExtraSpaces,
  renderHtmlElement,
} from "../../utils/SanctionLetter/SanctionLetter.utils";

// * Constants
import { signModeOptions } from "../../constants";
import { toast } from "react-toastify";
import { pdf } from "@react-pdf/renderer";
import { blobToBase64 } from "../../utils/ImageCompress";
// import { formatDateWithTime } from "../../helpers/Date/Date.helpers";

export default function Signature({
  loading,
  documentType,
  document,
  setDocument,
  uploadGeneratedSanctionAndAgreement,
  handleUploadDocumentToDigio,
  uploadCustomerSelfie,
}) {
  const htmlToReactParser = new HtmlToReact.Parser();
  const { customerDetails } = useContext(CustomerContext);

  const signatureCanvasRef = useRef(null);
  const signaturePadRef = useRef(null);
  const webcamRef = useRef(null);

  const initialCustomSignature = {
    signature: null,
    font: null,
  };
  const [customSignature, setCustomSignature] = useState(
    initialCustomSignature
  );
  const [conversionLoading, setConversionLoading] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(400);
  const [signatureMode, setSignatureMode] = useState("signatureMode");
  const videoConstraints = {
    facingMode: "user",
  };

  useEffect(() => {
    // Function to handle resizing
    const handleResize = () => {
      if (signatureCanvasRef.current) {
        setCanvasWidth(signaturePadRef.current?.offsetWidth);
      }
    };

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const clearCustomSignature = () => setCustomSignature(initialCustomSignature);
  const clearSignature = () => signatureCanvasRef.current.clear();

  const handleModeChange = (mode) => {
    setSignatureMode(mode);
    if (customSignature.signature) clearCustomSignature();
  };
  const handleCameraAccessFailure = (error) => {
    console.log("Camera Access Error : ", error);
  };

  const handleSubmit = async () => {
    let signature;
    // const timestamp = formatDateWithTime(new Date());
    if (signatureMode === "signatureMode") {
      const signatureImage = signatureCanvasRef.current.toDataURL();
      signature = `                                                                     <img src=${signatureImage} alt="customer signature"/>`;
    } else {
      if (customSignature.signature) {
        signature = `                                                                               <p style="font-family: ${customSignature.font};font-size: 15px;">${customSignature.signature}</p>`;
        // signature = removeExtraSpaces(signature);
        // console.log("Updated Signature : ", signature);
      } else {
        toast.error("Please choose a signature.");
        return;
      }
    }

    let documentHtml = document.document;
    const content = document.content; // * Contains Header/Footer Images and Text
    const { footerContent, headerContent } = content;

    documentHtml = removeExtraSpaces(documentHtml);

    const borrowerSignatureVariables = fetchDocumentVariables(documentHtml, {
      "Borrower Signature": signature,
    });
    
    let updatedDocumentHtml = documentHtml;
    if (borrowerSignatureVariables && borrowerSignatureVariables.length) {
      borrowerSignatureVariables.forEach((variable) => {
        const { template } = handleDocumentVariableChange(
          updatedDocumentHtml,
          borrowerSignatureVariables,
          variable.value,
          variable
        );
        updatedDocumentHtml = template;
      });
    }

    setDocument((prev) => ({ ...prev, documentWithSign: updatedDocumentHtml }));

    // * Convert normal HTML to JSX
    const htmlContentJSX = htmlToReactParser.parse(updatedDocumentHtml);
    // console.log("HTML Content JSX : ", htmlContentJSX);

    // * Render updated HTML content
    const htmlContentPDFCode = htmlContentJSX.map((ele) =>
      renderHtmlElement(ele)
    );
    // console.log("HTML Content PDF Code : ", htmlContentPDFCode);

    // * Generate PDF
    const blob = await pdf(
      <PDF
        size="A4"
        body={htmlContentPDFCode}
        headerContent={headerContent}
        footerContent={footerContent}
      />
    ).toBlob();

    // stampCustomerSignature(blob);
    const base64 = await blobToBase64(blob);
    // console.log("base64 : ", base64);

    const contact = customerDetails?.leadDetails?.Contact;
    setConversionLoading(true);

    let documentPdfName;
    if (documentType === "agreement") {
      documentPdfName = `${contact?.full_name}_agreement`;
    } else {
      documentPdfName = `${contact?.full_name}_sanction_letter`;
    }

    if (base64) {
      setConversionLoading(false);
      let payloadDetails = {};
      if (documentType === "agreement") {
        payloadDetails = {
          fileName: documentPdfName,
          documentName: "Agreement",
          docType: "loanOfferAgreement",
        };
      } else {
        payloadDetails = {
          fileName: documentPdfName,
          documentName: "Sanction Letter",
          docType: "sanctionLetter",
        };
      }

      uploadGeneratedSanctionAndAgreement(base64, payloadDetails);

      const clickedSelfie = webcamRef.current.getScreenshot();
      uploadCustomerSelfie(clickedSelfie);

      let fileName = "";
      if (documentType === "agreement") {
        fileName = `${customerDetails.fullName}_agreement.pdf`;
      } else {
        fileName = `${customerDetails.fullName}_sanction_letter.pdf`;
      }
      handleUploadDocumentToDigio(base64.split("base64,")[1], fileName);
    }
  };
  const handleCustomSignature = (font, signature) => {
    setCustomSignature({ signature, font });
  };

  return (
    <>
      <div className={style.cameraContainer}>
        <Webcam
          audio={false}
          videoConstraints={videoConstraints}
          imageSmoothing={true}
          screenshotQuality={1}
          ref={webcamRef}
          mirrored={false}
          className={style.webCam}
          onUserMediaError={(error) => handleCameraAccessFailure(error)}
          screenshotFormat="image/jpeg"
        />
      </div>

      <div className={style.signModeSelector}>
        <DropDownField
          label="Select Signature Mode"
          name="signatureMode"
          required={true}
          options={signModeOptions}
          defaultValue={signatureMode}
          nameKey="name"
          valueKey="value"
          handler={(value) => handleModeChange(value)}
        />
      </div>

      {signatureMode === "signatureMode" ? (
        <div className={style.signatureMode}>
          <div className={style.signaturePad} ref={signaturePadRef}>
            <SignatureCanvas
              ref={signatureCanvasRef}
              penColor="black"
              dotSize={0.5}
              canvasProps={{
                width: canvasWidth ? canvasWidth : 400,
                height: 200,
              }}
            />
          </div>
          <div className={style.signatureActions}>
            <button
              className={`${style.signatureActionBtn} ${style.secondaryBtn}`}
              onClick={clearSignature}
              disabled={conversionLoading || loading}
            >
              Clear
            </button>
          </div>
        </div>
      ) : (
        <div className={style.textMode}>
          {customSignature.signature ? (
            <div className={style.customSignatureBox}>
              <div className={style.customSignature}>
                <p
                  style={{
                    fontSize: "2.5rem",
                    fontFamily: customSignature.font,
                  }}
                >
                  {customSignature.signature}
                </p>
              </div>
              <button
                className={`${style.signatureActionBtn} ${style.secondaryBtn}`}
                onClick={clearCustomSignature}
                disabled={conversionLoading || loading}
              >
                Clear
              </button>
            </div>
          ) : (
            <>
              <p className={style.textModeHeading}>Choose a signature</p>
              <div className={style.signatures}>
                <button
                  style={{ fontFamily: "Shadows Into Light" }}
                  onClick={() =>
                    handleCustomSignature(
                      "Shadows Into Light",
                      customerDetails?.leadDetails?.Contact?.full_name
                    )
                  }
                >
                  {customerDetails?.leadDetails?.Contact?.full_name}
                </button>
                <button
                  style={{ fontFamily: "Caveat" }}
                  onClick={() =>
                    handleCustomSignature(
                      "Caveat",
                      customerDetails?.leadDetails?.Contact?.full_name
                    )
                  }
                >
                  {customerDetails?.leadDetails?.Contact?.full_name}
                </button>
                <button
                  style={{ fontFamily: "Abel" }}
                  onClick={() =>
                    handleCustomSignature(
                      "Abel",
                      customerDetails?.leadDetails?.Contact?.full_name
                    )
                  }
                >
                  {customerDetails?.leadDetails?.Contact?.full_name}
                </button>
                <button
                  style={{ fontFamily: "Playfair Display" }}
                  onClick={() =>
                    handleCustomSignature(
                      "Playfair Display",
                      customerDetails?.leadDetails?.Contact?.full_name
                    )
                  }
                >
                  {customerDetails?.leadDetails?.Contact?.full_name}
                </button>
                <button
                  style={{ fontFamily: "Satisfy" }}
                  onClick={() =>
                    handleCustomSignature(
                      "Satisfy",
                      customerDetails?.leadDetails?.Contact?.full_name
                    )
                  }
                >
                  {customerDetails?.leadDetails?.Contact?.full_name}
                </button>
              </div>
            </>
          )}
        </div>
      )}

      <div className={style.slideFooter}>
        <ContinueButton
          loading={conversionLoading || loading}
          handler={handleSubmit}
        />
      </div>
    </>
  );
}
