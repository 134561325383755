import "../../JourneySuccessSlide/JourneySuccessSlide.css";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { useContext } from "react";
import CustomerContext from "../../../../context/CustomerContext";
import { DOMAIN_NAME } from "../../../../constants";

export default function SuccessSlide() {
  const { customerDetails } = useContext(CustomerContext);

  const handleFinish = (redirectURL) => { 
    if (DOMAIN_NAME === "loanpocket") {
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      fbq("init", "1097990381529085");
      fbq("track", "PageView");
    }
    let anchor = document.createElement("a");
    anchor.setAttribute("href", redirectURL);
    anchor.click();
  };
  return (
    <div className="carouselSlideWrapper succesJourneySlide">
      <div className="successMsgBox">
        <div className="successIconBox">
          <div className="successIcon">
            <CheckOutlinedIcon sx={{ color: "#fff", fontSize: "50px" }} />
          </div>
        </div>
        <div className="successMessage">
          <h5>Yay!! Your application has been submitted successfully</h5>.
        </div>
      </div>
      <div className="finishJourneyButton">
        {customerDetails?.customerRedirectURL ? (
          <div className="continueBtnContainer">
            {/* <a
              href={customerDetails?.customerRedirectURL}
              className="continueBtn"
            >
              Finish
            </a> */}
            <button
              className="continueBtn"
              onClick={() => handleFinish(customerDetails?.customerRedirectURL)}
            >
              Finish
            </button>
          </div>
        ) : (
          <div className="closeWindow">
            <p>You may now close this window.</p>
          </div>
        )}
      </div>
    </div>
  );
}