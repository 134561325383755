import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function SuccessCard({gstin}) {
    return (
        // <Box 
        //     sx={{ 
        //         display: 'flex', 
        //         justifyContent: 'center', 
        //         alignItems: 'center', 
        //         height: '100vh',
        //         backgroundColor: '#f5f5f5'
        //     }}
        // >
            <Card sx={{ minWidth: 275, textAlign: 'center', padding: 2 }}>
                <CardContent sx={{paddingBottom:"0px"}}>
                    <CheckCircleIcon sx={{ fontSize: 50, color: 'green' }} />
                    <Typography variant="h5" component="div" sx={{ marginTop: 2,fontSize:"18px" }}>
                        {`Consent successfully obtained for GSTIN ${gstin}.`}
                    </Typography>
                </CardContent>
            </Card>
        // </Box>
    );
}

export default SuccessCard;