import * as actions from "../actions/types";
import * as urls from "../../urls";
import { all, call, fork, takeLatest, put } from "redux-saga/effects";
import axios from "axios";
import { DOMAIN_NAME } from "../../constants";

function getProfileDetails(params) {
  let headerObj = { apiKey: params.apiKey, apiSecret: params.apiSecret };

  return axios.request({
    withCredentials: true,
    method: "get",
    url: `${urls.GET_PROFILE_DETAILS}`,
    headers: {
      domain_name: DOMAIN_NAME,
      ...headerObj,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Methods": "*",
    },
  });
}

export function* getProfileDetailsEffect(getProfileAction) {
  const { payload, resolve, reject } = getProfileAction;

  try {
    const { data } = yield call(getProfileDetails, payload);

    if (data) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.GET_PROFILE_DETAILS_SUCCESS,
        data,
      });
    } else {
      reject(data);
    }
  } catch (e) {
    if (reject) reject(e);
  }
}

export function* getProfileDetailsWatcher() {
  yield takeLatest(actions.GET_PROFILE_DETAILS, getProfileDetailsEffect);
}

export default function* rootSaga() {
  yield all([fork(getProfileDetailsWatcher)]);
}
