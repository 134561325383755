import { useRef, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../Loader/Loader";
import { MdVerified } from "react-icons/md";
// * Packages
import { useCookies } from "react-cookie";
import queryString from "query-string";
// * Styles
import SlideStyle from "../../../../styles/Slide/style.module.css";
// * Micro Components
import TextInputField from "../../../DataInputFields/TextInputField/TextInputField";
import ContinueButton from "../../../Buttons/SlideContinueButton/ContinueButton";
// ** Error messagres aand error types
import * as Errors from "../../../../utils/errors";
// * Helpers
import {
  calculateAge2,
  getCookiePathFromURL,
  trimString,
} from "../../../../helpers";
import { calculateAge } from "../../../../utils/common";
// * Utilities
import { validatePAN, formatDate } from "../../../../utils";
// * Contexts
import CustomerContext from "../../../../context/CustomerContext";
import ErrorContext from "../../../../context/ErrorProvider";
// * Constants
import { CUSTOMER_AGE, TOTAL_SLIDES } from "../../../../constants";
// * APIs and API Handler
import { useMutation } from "@tanstack/react-query";
import { checkPhoneOnPan, getCompanyDetails, verifyUdyamRegNo } from "../../../../api/services/documents";
import { toast } from "react-toastify";
import { getLocationPermission } from "../../../../helpers";
import DateInputField from "../../../DataInputFields/DateInputField/DateInputField";

const UdyamRegistrationSlide = ({
  ageRef,
  loading,
  setLoading,
  activeSlide,
  switchToNextSlide,
  setCustomerDetailsCookies,
  updateJourneyData,
}) => {
  const CookiePath = getCookiePathFromURL(window.location.href);
  let queryValues = queryString.parse(window.location.search);
  const JourneyVersion = queryValues?.jrnyTyp;
  const { apiKey, apiSecret } = useParams();

  // * Cookies
  const [_, setCookie] = useCookies(["user"]);

  const udyamRef = useRef("");

  const { customerDetails, setCustomerDetails } = useContext(CustomerContext);
  // * State to handle Journey error
  const { error, setError } = useContext(ErrorContext);

  const [verifyMsg,setVerifyMsg] = useState();

  const [isVerified,setIsVerified] = useState(false);


  const verifyRegSuccess = (data) => {
    if(!data?.data?.status){
      console.log('if not verified');
      setError({
        error: true,
        type: Errors.UDYAM_REGISTRATION_ERROR_TYPE,
        message: Errors.UDYAM_REGISTRATION_VERIFICATION_ERROR,
      });
      setIsVerified(false);
    }else{
      console.log('if verified')
      setError({
        error: false,
        type: "",
        message: "",
      });
      setIsVerified(true);
    }
    setVerifyMsg(data?.data?.msg);

  }

  const verifyUdyamRegNoAPI = useMutation({
    mutationFn: (payload) => verifyUdyamRegNo(payload),
    onSuccess: (data) => verifyRegSuccess(data),
    retry: false,
  });
  const handleSuccess = (data) => {
    const panData = data.data;
    if (panData.status === "1") {
      const mobileInPan = panData.result.mobile;
      const isAadhaarLinked = panData.result.aadharlink;
      if (JourneyVersion === "J3") {
        switchToNextSlide(10);
      } else {
        switchToNextSlide(activeSlide + 1);
      }
      if (mobileInPan == customerDetails.mobileNumber && isAadhaarLinked) {
      } else {
        setError({
          error: true,
          type: Errors.PAN_ERROR_TYPE,
          message: Errors.INVALID_PHONE_ERROR,
        });
      }
    } else if (
      panData.status == 2 ||
      panData.status == 3 ||
      panData.status == 4
    ) {
      setError({
        error: true,
        type: Errors.PAN_ERROR_TYPE,
        message: panData.message,
      });
    }
  };
  const handleError = (error) => {
    console.log("ERROR : ", error);
    toast.error("Something went wrong please try again.");
  };

  const successGetCompanyDet = (response) => {
    console.log('company details=>',response);
    const details = {
        compane_name:response?.data?.company_name,
        company_type:response?.data?.company_type,
        company_cin:response?.data?.cin,
        company_address1:response?.data?.company_address1,
        company_address2:response?.data?.company_address2,company_city:response?.data?.company_city,
        company_state:response?.data?.company_state,
        company_pin:response?.data?.company_pin
      };
      setCustomerDetails({
        ...customerDetails,
        ...details,
      });
      setCustomerDetailsCookies(details);
      switchToNextSlide(40)
    // setCompanyDet({...companyDet,compane_name:response?.data?.company_name,company_type:response?.data?.company_type,company_cin:response?.data?.cin})
}

  const getCompanyDetailsAPI = useMutation({
    mutationKey: ["consent"],
    mutationFn: (payload) =>
    getCompanyDetails(payload).then(successGetCompanyDet),
    enabled:
      customerDetails.pipelineID &&
      customerDetails.leadId &&
      customerDetails.contactID
        ? true
        : false,
    retry:false,
  });
  // * Main Function
  async function verifyUdyamRegistration() {
    const UdyamReg = udyamRef.current.value;
    if(UdyamReg === ""){
      switchToNextSlide(40);
      return;
    }

    if (!handleRegistrationNumber(UdyamReg, Errors.UDYAM_REGISTRATION_ERROR_TYPE,false)) {
      return false;
    }
    if (!error.error) {
      setError({
        error: false,
        type: "",
        message: "",
      });
      const data = {
        // udyam: UdyamReg.toUpperCase(),
      };
      const details = {
        contactId:customerDetails.contactID,
        udyamRegNo: "UDYAM-"+UdyamReg.toUpperCase(),
      };
      setCustomerDetails({
        ...customerDetails,
        ...details,
      });
      const slidePayload = {
        slideName: "Udyam Registration Details",
        slideIndex: 39,
        slideIcon: "Aadhaar",
        totalSlides: TOTAL_SLIDES,
      };

      const response = await updateJourneyData(data, slidePayload);
      if (response) {
    getCompanyDetailsAPI.mutate({   contactId: customerDetails.contactID,
        headers: { apiKey, apiSecret }})

      }
    }
  }

  function handleRegistrationNumber(UdyamRegNumber, errorType,isOnChange=false) {

    // if (UdyamRegNumber === "") {
    //   setError({
    //     error: true,
    //     type: errorType,
    //     message: Errors.UDYAM_REGISTRATION_ERROR,
    //   });
    //   return false;
    // }

    let value = UdyamRegNumber.replace(/[^A-Za-z0-9]/g, ''); // Remove non-alphanumeric characters
    if (value.length > 2) {
        value = value.slice(0, 2) + '-' + value.slice(2);
    }
    if(value.length > 5){
        value = value.slice(0,5) + '-' + value.slice(5);
    }
    if(value.length>=14){
          udyamRef.current.value = value.slice(0,13);
          return false;

    }else  if(value.length===13)
    {

        setError({
            error: false,
            type: "",
            message: "",
          });
          udyamRef.current.value = value;
          if(isOnChange){
        const details = {
            contactId:customerDetails.contactID,
            udyamRegNo: "UDYAM-"+udyamRef.current.value.toUpperCase(),
          };
          const headers = { apiKey, apiSecret };
    details.headers = headers;
      verifyUdyamRegNoAPI.mutate(details);
        }
        return true;
    }else{
         setError({
            error: true,
            type: errorType,
            message: Errors.INVALID_UDYAM_REGISTRATION_ERROR,
          });
          udyamRef.current.value = value;
          return false;
    }
  }

  return (
    <div className={SlideStyle.carouselSlideWrapper}>
      <div className={SlideStyle.typographyContainer}>
        <h1>Please Enter Udyam Registration Number</h1>
      </div>
      {/* Input Fields */}
      <div className={SlideStyle.dataFieldsUdyamContainer}>
        {/* Udyam Registration */}
        <span className={SlideStyle.udyamLabel}>UDYAM-</span>
        <TextInputField
          type="text"
          maxLength="15"
          minLength="15"
          placeholder="XX-XX-XXXXXXX"
          name="registrationNumber"
          id="registrationNumber"
          reference={udyamRef}
          required={true}
          uppercase={true}
          errorType={Errors.UDYAM_REGISTRATION_ERROR_TYPE}
          handler={(udyamNumber) => handleRegistrationNumber(udyamNumber, Errors.UDYAM_REGISTRATION_ERROR_TYPE,true)}
        />
        <p>{ verifyUdyamRegNoAPI?.isPending ? <Loader /> : isVerified && <MdVerified />}</p>
      </div>

      {/* Continue Slide Button */}
      <div className={SlideStyle.continueBtn}>
        <ContinueButton
          disabled={verifyUdyamRegNoAPI?.isLoading || getCompanyDetailsAPI.isLoading  ? true:false}
          isUdyamAadhaarSlide = {true}
          handler={verifyUdyamRegistration}
        />
      </div>
    </div>
  );
};

export default UdyamRegistrationSlide;