import axios from "axios";
import { DOMAIN_NAME } from "../constants";

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
    domain_name: "",
  },
});

instance.interceptors.request.use(
  (config) => {
    const domain = window.location.host.split(".")[0];

    if (window.location.host.includes("localhost")) {
      if (!window.location.hash.includes("/login")) {
        config.headers["domain_name"] = DOMAIN_NAME;
      }
    } else {
      config.headers["domain_name"] = domain === "lenders" ? "" : domain;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default instance;
