import "../../styles/Loader/Loader.css";

export default function useLoaderData() {
  return (
    <div className="loadingio-spinner-dual-ring-v08onlcayt">
      <div className="ldio-fa3eb264q1p">
        <div></div>
        <div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
