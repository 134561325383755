import axios from "../../axios";
import {
  GET_SMS_STAGE_WISE,
  SEND_STAGE_WISE_INDIVIDUAL_SMS,
} from "../../../constants/urls";

// * API to get SMS templates stage wise
export const getSmsTemplateStageWise = async (payload) => {
  const { headers, params } = payload;

  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}${GET_SMS_STAGE_WISE}/${params.pipelineId}`,
    { headers }
  );
  return res?.data;
};

// * API to get SMS templates stage wise
export const sendIndividualStageWiseSMS = async (payload) => {
  const { headers, data } = payload;

  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${SEND_STAGE_WISE_INDIVIDUAL_SMS}`,
    data,
    { headers }
  );
  return res?.data;
};
