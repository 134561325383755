import { useRef, useContext, useState } from "react";
import { useParams } from "react-router-dom";

// * Packages
import { useCookies } from "react-cookie";
import queryString from "query-string";
// * Styles
import SlideStyle from "../../../../styles/Slide/style.module.css";
// * Micro Components
import TextInputField from "../../../DataInputFields/TextInputField/TextInputField";
import ContinueButton from "../../../Buttons/SlideContinueButton/ContinueButton";
// ** Error messagres aand error types
import * as Errors from "../../../../utils/errors";
// * Helpers
import {
  calculateAge2,
  getCookiePathFromURL,
  trimString,
} from "../../../../helpers";
import { calculateAge } from "../../../../utils/common";
// * Utilities
import { validatePAN, formatDate } from "../../../../utils";
// * Contexts
import CustomerContext from "../../../../context/CustomerContext";
import ErrorContext from "../../../../context/ErrorProvider";
// * Constants
import { CUSTOMER_AGE, TOTAL_SLIDES } from "../../../../constants";
// * APIs and API Handler
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  checkPhoneOnPan,
  getCompanyDetails,
} from "../../../../api/services/documents";
import { toast } from "react-toastify";
import { getLocationPermission } from "../../../../helpers";
import DateInputField from "../../../DataInputFields/DateInputField/DateInputField";
import { useEffect } from "react";

const CompanyAddressDetailsSlide = ({
  ageRef,
  loading,
  setLoading,
  activeSlide,
  switchToNextSlide,
  setCustomerDetailsCookies,
  updateJourneyData,
}) => {
  const CookiePath = getCookiePathFromURL(window.location.href);
  let queryValues = queryString.parse(window.location.search);
  const JourneyVersion = queryValues?.jrnyTyp;
  const { apiKey, apiSecret } = useParams();

  // * Cookies
  const [_, setCookie] = useCookies(["user"]);

  // * Refs and States
  const fullnameRef = useRef("");
  // const panRef = useRef("");
  const dobRef = useRef("");
  const [dob, setDob] = useState(new Date());

  const { customerDetails, setCustomerDetails } = useContext(CustomerContext);
  let gstins = customerDetails?.customerDetails?.UdyamRegistrationDetails[0]
    ?.gstins
    ? JSON.parse(
        customerDetails?.customerDetails?.UdyamRegistrationDetails[0]?.gstins
      )
    : [];
  const [addressDet, setAddressDet] = useState({
    company_address1: "",
    company_address2: "",
    company_city: "",
    company_state: "",
    company_pin: "",
  });
  // * State to handle Journey error
  const { error, setError } = useContext(ErrorContext);

  const handleSuccess = (data) => {
    const panData = data.data;
    if (panData.status === "1") {
      const mobileInPan = panData.result.mobile;
      const isAadhaarLinked = panData.result.aadharlink;
      if (JourneyVersion === "J3") {
        switchToNextSlide(10);
      } else {
        switchToNextSlide(activeSlide + 1);
      }
      if (mobileInPan == customerDetails.mobileNumber && isAadhaarLinked) {
      } else {
        setError({
          error: true,
          type: Errors.PAN_ERROR_TYPE,
          message: Errors.INVALID_PHONE_ERROR,
        });
      }
    } else if (
      panData.status == 2 ||
      panData.status == 3 ||
      panData.status == 4
    ) {
      setError({
        error: true,
        type: Errors.PAN_ERROR_TYPE,
        message: panData.message,
      });
    }
  };
  const handleError = (error) => {
    console.log("ERROR : ", error);
    toast.error("Something went wrong please try again.");
  };

  // * Main Function
  async function addBusinessAddress() {
    console.log("inside business Address=>");
    const DOB = dob;

    if (!error.error) {
      setError({
        error: false,
        type: "",
        message: "",
      });
      setLoading(true);
      const data = {
        // full_name: trimString(fullName),
        date_of_birth: DOB,
        // pan: PAN.toUpperCase(),
      };
      // * Saving Details to access through out the Journey.
      const details = {
        // fullName: trimString(fullName),
        // dateOfBirth: DOB,
        // pan: PAN,
      };
      setCustomerDetails({
        ...customerDetails,
        ...details,
      });
      const slidePayload = {
        slideName: "Company Address Details",
        slideIndex: 41,
        slideIcon: "OfficeAddress",
        totalSlides: TOTAL_SLIDES,
      };
      const response = await updateJourneyData(data, slidePayload);

      if (response) {
        const customerAge = calculateAge2(DOB);
        // ageRef.current = customerAge;
        setCookie(CUSTOMER_AGE, customerAge, { path: CookiePath });
        // * Setting Cookies
        details.customerAge = customerAge;
        setCustomerDetailsCookies(details);

        // await handleCheckPhoneOnPan();
        if (gstins.length == 0) {
          switchToNextSlide(43);
        } else {
          switchToNextSlide(42);
        }
      }
      setLoading(false);
    }
  }
  return (
    <div className={SlideStyle.carouselSlideWrapper}>
      <div className={SlideStyle.typographyContainer}>
        <h1>Company Address Details</h1>
        {/* <p>Please fill all the details carefully.</p> */}
      </div>
      {/* Input Fields */}
      <div className={SlideStyle.dataFieldsContainer}>
        <div className={SlideStyle.inputContainer}>
          {/* Company Address 1 */}
          <div className={SlideStyle.labelContainer}>
            <label>Company Address 1</label>
            <label className={SlideStyle.labelColor}>
              {customerDetails?.customerDetails?.UdyamRegistrationDetails[0]
                ?.flat_no
                ? customerDetails?.customerDetails?.UdyamRegistrationDetails[0]
                    ?.flat_no
                : customerDetails?.customerDetails?.UdyamRegistrationDetails[0]
                    ?.building}
            </label>
          </div>

          {/* Company Address 2 */}
          <div className={SlideStyle.labelContainer}>
            <label>Company Address 2</label>
            <label className={SlideStyle.labelColor}>
              {customerDetails?.customerDetails?.UdyamRegistrationDetails[0]
                ?.building
                ? customerDetails?.customerDetails?.UdyamRegistrationDetails[0]
                    ?.building +
                  customerDetails?.customerDetails?.UdyamRegistrationDetails[0]
                    ?.block
                : ""}
            </label>
          </div>

          {/* Company City */}
          <div className={SlideStyle.labelContainer}>
            <label>City</label>
            <label className={SlideStyle.labelColor}>
              {
                customerDetails?.customerDetails?.UdyamRegistrationDetails[0]
                  ?.city
              }
            </label>
          </div>

          {/* Company State */}
          <div className={SlideStyle.labelContainer}>
            <label>State</label>
            <label className={SlideStyle.labelColor}>
              {
                customerDetails?.customerDetails?.UdyamRegistrationDetails[0]
                  ?.state
              }
            </label>
          </div>

          {/* Company Pin */}
          <div className={SlideStyle.labelContainer}>
            <label>PIN</label>
            <label className={SlideStyle.labelColor}>
              {
                customerDetails?.customerDetails?.UdyamRegistrationDetails[0]
                  ?.pin
              }
            </label>
          </div>
        </div>
      </div>

      {/* Continue Slide Button */}
      <div className={SlideStyle.continueBtn}>
        <ContinueButton handler={addBusinessAddress} />
      </div>
    </div>
  );
};

export default CompanyAddressDetailsSlide;
