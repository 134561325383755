import style from "./Agreement.module.css";

import { useState, useRef } from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import queryString from "query-string";
// * Contexts
import CustomerContext from "../../../context/CustomerContext";

// * Components
import ContinueButton from "../../Buttons/SlideContinueButton/ContinueButton";
import DocumentContent from "../../DocumentContent/DocumentContent";

import { useLocation } from "react-router-dom";

// * Constants
import { TOTAL_SLIDES } from "../../../constants";

// * Helpers
import { getCookiePathFromURL } from "../../../helpers";

import { removeAllCookies } from "../../../helpers";

export default function Agreement({
  agreement,
  updateLastVisitSlide,
  switchToNextSlide,
}) {
  let url = useLocation().search;
  let queryValues = queryString.parse(url);
  const JourneyVersion = queryValues?.jrnyTyp;
  const CookiePath = getCookiePathFromURL(window.location.href);

  const { customerDetails } = useContext(CustomerContext);
  const { apiKey, apiSecret } = useParams();

  const contentDivRef = useRef();

  const [continueButtonState, setContinueButtonState] = useState(true);

  const finishJourney = () => {
    // * NEW
    const slidePayload = {
      headers: { apiKey, apiSecret },
      leadId: customerDetails?.leadId,
      slideName: "Success : Journey Completed",
      slideIndex: 38,
      slideIcon: "Success",
      totalSlides: TOTAL_SLIDES,
    };
    // * Update Journey last visit slide
    updateLastVisitSlide(slidePayload);
    removeAllCookies(CookiePath);
    switchToNextSlide(34);
  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = contentDivRef.current;

    if (scrollTop + clientHeight >= scrollHeight - 1 && continueButtonState) {
      setContinueButtonState(false);
    }
  };

  const handleSubmitAgreement2 = () => {
    if (agreement.document) {
      const slidePayload = {
        headers: { apiKey, apiSecret },
        leadId: customerDetails?.leadId,
        slideName: "Loan Agreement",
        // slideIndex: 32,
        slideIcon: "Agreement",
        totalSlides: TOTAL_SLIDES,
        journeyVersion: JourneyVersion,
      };
      // * Update Journey last visit slide
      updateLastVisitSlide(slidePayload);

      switchToNextSlide();
    } else {
      finishJourney();
    }
  };

  return (
    <div className={style.sanctionLetterSlide}>
      <div className={style.slideBody}>
        <div className={style.slideHeader}>
          <h1>Loan Agreement</h1>
          <p>Here is your loan agreement please sign it to proceed.</p>
        </div>

        <div className={style.documentContainer}>
          {agreement.document ? (
            <DocumentContent
              content={agreement.document}
              reference={contentDivRef}
              handleScroll={handleScroll}
            />
          ) : null}
        </div>
      </div>

      <div className={style.actionBox}>
        <ContinueButton
          label="Proceed To Sign"
          handler={handleSubmitAgreement2}
          disabled={continueButtonState}
        />
      </div>
    </div>
  );
}
