import { StyleSheet, View, Text } from "@react-pdf/renderer";

export default function Footer({ footerContent }) {
  const styles = StyleSheet.create({
    footerWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: 6,
      textAlign: "center",
      color: "grey",
    },
    footerText: {
      color: "grey",
      fontSize: 8,
    },
  });

  return (
    <View style={styles.footerWrapper}>
      {footerContent && footerContent?.text && (
        <Text style={styles.footerText}>{footerContent?.text}</Text>
      )}
    </View>
  );
}
