import axiosWithDomain from "../../axios";

import {
  UPLOAD_DOCUMENT_FOR_ESIGN,
  SAVE_SIGNED_DOCUMENT,
} from "../../../constants/urls";

export const uploadDocumentForEsign = async (Payload) => {
  const { data, headers } = Payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${UPLOAD_DOCUMENT_FOR_ESIGN}`,
    data,
    { headers }
  );
  return res?.data;
};

export const saveSignedDocument = async (Payload) => {
  const { documentId, headers, data } = Payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${SAVE_SIGNED_DOCUMENT}?documentId=${documentId}`,
    data,
    { headers }
  );
  return res?.data;
};
