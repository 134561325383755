import * as actions from "../actions/types";
import * as urls from "../../urls";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { DOMAIN_NAME } from "../../constants";

function journeyFieldsApi(params) {

  let headerObj = params.headerData;
  let bodyObj = params.getOtpData;
  return axios.request({
    withCredentials: true,
    method: "get",
    url: `${urls.JOURNEY_FILEDS}`,
    headers: {
      domain_name: DOMAIN_NAME,
      ...headerObj,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Methods": "*",
    },
    data: bodyObj,
  });
}

export function* getjourneyFieldActionEffect(getJourneyFieldAction) {
  const { payload, resolve, reject } = getJourneyFieldAction;

  try {
    const { data } = yield call(journeyFieldsApi, payload);
    if (data) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.GET_JOURNEY_FIELDS_SUCCESS,
        data,
      });
    } else {
      reject(data);
    }
  } catch (e) {
    if (reject) reject(e);
  }
}

export function* journeyFieldActionWatcher() {
  yield takeLatest(actions.GET_JOURNEY_FIELDS, getjourneyFieldActionEffect);
}

export default function* rootSaga() {
  yield all([fork(journeyFieldActionWatcher)]);
}
