// * Icons
import SpeedRoundedIcon from "@mui/icons-material/SpeedRounded";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";

export default function Features() {
  return (
    <div className="featuresListBox">
      <div className="introBox">
        <p>Get Instant Credit Approval in less than 2 minutes</p>
      </div>
      <div className="featuresList">
        <div className="feature">
          <div className="featureIcon">
            <SpeedRoundedIcon sx={{ color: "#2e3092" }} />
          </div>
          <p>100% Online Credit Application</p>
        </div>
        <div className="feature">
          <div className="featureIcon">
            <CurrencyRupeeRoundedIcon sx={{ color: "#2e3092" }} />
          </div>
          <p>Pre-Approved Credit Offers</p>
        </div>
        <div className="feature">
          <div className="featureIcon">
            <VerifiedRoundedIcon sx={{ color: "#2e3092" }} />
          </div>
          <p>Lowest Interest Rate</p>
        </div>
      </div>
    </div>
  );
}
