import { useLayoutEffect, useState, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import "../../styles/JourneyLeftSection/JourneyLeftSection.css";

import queryString from "query-string";

// * Components
import DefaultFeatures from "../JourneyLeftSection/LoanFeatures/DefaultFeatures/DefaultFeatures";
import Myfindoc from "../JourneyLeftSection/LoanFeatures/Myfindoc/Myfindoc";
import NonLenderJourney from "../JourneyLeftSection/LoanFeatures/NonLenderJourney/NonLenderJourney";
import RecapitaFinance from "../JourneyLeftSection/LoanFeatures/RecapitaFinance/RecapitaFinance";

// * Images
import Feature from "../../assets/images/feature.png";

// * API handlers
import { useQuery } from "@tanstack/react-query";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// * APIs
import {
  getProfileDetails,
  getProfileDetailsSuccess,
} from "../../store/actions/profile";
import { getLenderProfileDetails } from "../../api/services/Journey";
// * Contexts
import CustomerContext from "../../context/CustomerContext";
import MaxemoCapital from "./LoanFeatures/MaxemoCapital/MaxemoCapital";
// * Constants
import { DOMAIN_NAME } from "../../constants";

function JourneyLeftSection(props) {
  const { apiKey, apiSecret } = useParams();

  let url = useLocation().search;
  let queryValues = queryString.parse(url);
  const JourneyVersion = queryValues?.jrnyTyp;

  const initialState = {
    logo: null,
    favicon: null,
  };
  const [lenderDetails, setLenderDetails] = useState(initialState);
  const { customerDetails, setCustomerDetails } = useContext(CustomerContext);

  useLayoutEffect(() => {
    document.title = `${DOMAIN_NAME[0].toUpperCase()}${DOMAIN_NAME.slice(1)}`;
  });

  useQuery({
    queryKey: ["getLenderProfileDetails"],
    queryFn: () =>
      getLenderProfileDetails({ apiKey, apiSecret }).then(handleSuccess),
    refetchOnWindowFocus: false,
    retry: false,
  });
  const handleSuccess = (data) => {
    const BaseURL = process.env.REACT_APP_DOCUMENTS_BASE_URL;

    const Logo = BaseURL + data?.data?.journeyLogoDetails?.documents?.slice(2);
    const Favicon =
      BaseURL + data?.data?.faviconLogoDetails?.org_logo?.slice(2);
    const userType = data?.data?.userType;
    props?.getUserType(data?.data?.userType);
    setCustomerDetails({
      ...customerDetails,
      userType,
      customerRedirectURL: data?.data?.postJourneyRedirectURL,
    });
    setLenderDetails({ logo: Logo, favicon: Favicon });

    const favicon = document.getElementById("favicon");
    if (Favicon) {
      favicon.href = Favicon;
    }

    return data;
  };

  const getFeatures = () => {
    if (DOMAIN_NAME === "recapitafinance") return <RecapitaFinance />;
    else if (DOMAIN_NAME === "myfindoc") return <Myfindoc />;
    else if (DOMAIN_NAME === "maxemocapital") return <MaxemoCapital />;
    else if (JourneyVersion === "J3") return <NonLenderJourney />;
    else return <DefaultFeatures />;
  };

  return (
    <div className="journeyLeftSection">
      <div className="leftSectionWrapper">
        <div className="header">
          <div className="logoBox">
            {lenderDetails.logo ? (
              <img
                src={lenderDetails.logo}
                loading="lazy"
                alt="Journey Left Logo"
              />
            ) : null}
          </div>
        </div>
        <div className="featuresContainer">
          {getFeatures()}
          <div className="featureImgBox">
            <img src={Feature} loading="lazy" alt="Featured img" />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  profileData: state.getProfileReducer.getProfileDetailsSuccess,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProfileDetails,
      getProfileDetailsSuccess,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(JourneyLeftSection);
