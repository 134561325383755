import style from "./BusinessDetails.module.css";
import { useContext, useRef } from "react";
import queryString from "query-string";
// * Components
import ContinueButton from "../../Buttons/SlideContinueButton/ContinueButton";
import DropDownField from "../../DataInputFields/DropDownField/DropDownField";
import TextInputField from "../../DataInputFields/TextInputField/TextInputField";

// * Contexts
import ErrorContext from "../../../context/ErrorProvider";

// * Constants
import { shopOwnershipTypes, TOTAL_SLIDES } from "../../../constants";
import { useLocation } from "react-router-dom";
import * as Errors from "../../../utils/errors";

// * Helpers
import { removeAllCookies, getCookiePathFromURL } from "../../../helpers";

function BusinessDetails({
  loading,
  setLoading,
  updateJourneyData,
  switchToNextSlide,
}) {
  const CookiePath = getCookiePathFromURL(window.location.href);
  const { error, setError } = useContext(ErrorContext);

  const shopOwnerShipTypeRef = useRef();
  const durationOfEstablishmentRef = useRef();

  const handleDuration = (duration) => {
    if (!duration) {
      setError({
        error: true,
        type: Errors.DURATION_OF_ESTABLISHMENT_TYPE,
        message: Errors.NULL_DURATION_OF_ESTABLISHMENT,
      });
      return false;
    }
    if (error.error) {
      setError({
        error: false,
        type: "",
        message: "",
      });
    }
    return true;
  };
  const handleShopOwnerType = (ownerType) => {
    if (!ownerType) {
      setError({
        error: true,
        type: Errors.SHOP_OWNERSHIP_TYPE,
        message: Errors.NULL_SHOP_OWNERSHIP_TYPE,
      });
      return false;
    }
    if (error.error) {
      setError({
        error: false,
        type: "",
        message: "",
      });
    }
    return true;
  };

  let url = useLocation().search;
  let queryValues = queryString.parse(url);
  const JourneyVersion = queryValues?.jrnyTyp;

  const handleSubmit = async () => {
    const durationOfEstablishment = durationOfEstablishmentRef.current?.value;
    const shopOwnershipType = shopOwnerShipTypeRef.current?.value;

    if (!handleDuration(durationOfEstablishment)) return false;
    if (!handleShopOwnerType(shopOwnershipType)) return false;

    if (!error.error) {
      setLoading(true);
      const data = {
        date_of_incorporation: durationOfEstablishment,
        company_type: shopOwnershipType,
      };
      const slidePayload = {
        slideName: "Success : Journey Completed",
        slideIndex: 38,
        slideIcon: "Success",
        totalSlides: TOTAL_SLIDES,
        journeyVersion:JourneyVersion
      };
      const response = await updateJourneyData(data, slidePayload);
      if (response) {
        removeAllCookies(CookiePath);
        switchToNextSlide(38); // * Journey Finish
      }
      setLoading(false);
    }
  };
  return (
    <div className={style.slide}>
      <div className={style.slideHeader}>
        <h1>Business Details</h1>
        <p>Please enter your business details.</p>
      </div>
      <div className={style.slideBody}>
        <TextInputField
          type="number"
          label="Duration of Establishment (Years)"
          placeholder="Duration of Establishment"
          name="durationOfEstablishment"
          id="durationOfEstablishment"
          reference={durationOfEstablishmentRef}
          required={true}
          errorType={Errors.DURATION_OF_ESTABLISHMENT_TYPE}
          handler={handleDuration}
        />
        <DropDownField
          label="Shop Ownership Type"
          name="shopOwnershipType"
          reference={shopOwnerShipTypeRef}
          required={true}
          options={shopOwnershipTypes}
          nameKey="name"
          valueKey="value"
          errorType={Errors.SHOP_OWNERSHIP_TYPE}
          handler={handleShopOwnerType}
        />
      </div>
      <div className={style.slideFooter}>
        <ContinueButton loading={loading} handler={handleSubmit} />
      </div>
    </div>
  );
}

export default BusinessDetails;
