import style from "../style.module.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const Feature = ({ content }) => {
  return (
    <div className={style.loanBenefitHeading}>
      <CheckCircleOutlineIcon sx={{ color: "#2e3092", fontSize: "18px" }} />
      <h5>{content}</h5>
    </div>
  );
};

export default function PersonalLoanDetails({ scrollPosition }) {
  return (
    <div
      className={`${style.personalLoanDetails} ${
        scrollPosition > 20
          ? style.showPersonalLoanDetails
          : style.hidePersonalLoanDetails
      }`}
    >
      {/* Personal Loan Features & Benifits */}
      <div className={style.personalLoanDetailsBox}>
        <h4 className={style.heading}>About Recapita</h4>
        <div className={style.featureAndBenefitDescription}>
          <p>
            Recapita Finance Private Limited is a Non-Banking Financial Company
            (NBFC) registered with the Reserve Bank of India. Established and
            registered in the year 2016 under the Companies Act 2013 and
            received its license from Reserve Bank of India in March 2017.
            Recapita Finance is Promoted by Mr. Amit Jain, a first-generation
            entrepreneur. He is a Qualified Chartered Accountant and having vast
            experience in the Banking & Financial Sector.
          </p>
          <p>
            Recapita started its Non–Banking operations in the Month of June
            2017 from Central India - Bhopal, Madhya Pradesh Since; Recapita has
            earned an enormous reputation as a transparent, flexible, and
            trustworthy financing partner for borrowers in various segments.
            Recapita believes that vision, hard work, management skills, and
            optimum usage of finance with our products and services, can take
            any venture into a successful model.
          </p>
        </div>
      </div>

      {/* Business Loan */}
      <section className={style.section}>
        <h4 className={style.heading}>Business Loan</h4>
        <div className={style.featureAndBenefitDescription}>
          <p>
            MSME's encounter several constraints in their growth with the
            biggest & hardest challenge are being financed,i.e. mere
            availability of the same when required. Unlike ideas, willingness,
            and intent, timing of finance is very crucial. A helping hand in
            lending at the time of need becomes even more important because of
            this challenge. Understanding these problems faced by any MSME's, we
            at Recapita have an unsecured business loan up to Rs.50 lakhs with
            no collateral. ‘Unsecured business loan’ specifically suiting the
            business requirements of our customers, helping them with the
            business continuity and ultimately boosting the business growth.
          </p>
          <div className={style.features}>
            <Feature
              content="Revolving in nature (can be renewed again after repayment or
                after the expiry of one year whichever is earlier)."
            />
            <Feature content="Amount – Up to Rs.50 lakhs." />
            <Feature content="Tenure – Up to 365 days." />
            <Feature content="Repayment – within 90 days of a drawl." />
            <Feature
              content="Stay protected by paying a nominal premium (*insurance is a
                subject matter of solicitation', which essentially means that
                insurance has to be requested or asked for, not sold)."
            />
            <Feature content="No guarantee/ security or collateral required." />
          </div>
        </div>
      </section>
      {/* Personal Loan */}
      <section className={style.section}>
        <h4 className={style.heading}>Personal Loan</h4>
        <div className={style.featureAndBenefitDescription}>
          <p>
            Dreaming of a vacation, a perfect wedding, home renovation, or a
            much-desired gadget, you no longer need to wait to realize your
            dreams. Make life picture perfect with Recapita Finance Personal
            Loans. End-use of the Recapita Personal Loan can be House
            renovation, Holidays, Purchase of consumer durables, Education,
            Marriage, Any other personal emergency. To support you, Recapita is
            providing “Unsecured Personnel Loan” to Corporate Salaried and
            Self-employed Individuals. Get the spending power you need with
            Personal Loan from Recapita. Now plan your finances confidently
            knowing your monthly payments will always remain the same. With
            Personal Loans from Recapita, you can get to know your loan amount
            instantly and get disbursal in your account in as soon as 2 business
            days.
          </p>
          <div className={style.features}>
            <Feature content="For Salaried employees." />
            <Feature content="Loan up to Rs. 25 lakhs." />
            <Feature content="Flexible tenor 12 to 36 months." />
            <Feature content="Attractive rate of interest." />
            <Feature content="Simplified documentation." />
            <Feature content="Speedy approvals." />
            <Feature
              content="Stay protected by paying a nominal premium (*insurance is a
                subject matter of solicitation', which essentially means that
                insurance has to be requested or asked for, not sold)."
            />
            <Feature content="No guarantee/ security or collateral required." />
          </div>
        </div>
      </section>
      {/* Corporate Loan */}
      <section className={style.section}>
        <h4 className={style.heading}>Corporate Loan</h4>
        <div className={style.featureAndBenefitDescription}>
          <div className={style.content}>
            <p>
              Corporate employees can avail quick and hassle-free loans from
              their desks by contacting their employer or concerning HR. Loans
              are available for multiple purposes that include, but not limited
              to wedding expenses, medical expenses, vacation, purchase of
              household appliances, etc.
            </p>
            <p>
              With corporate HR loans, employees can avail any loan starting
              from Rs 50,000/- to Rs 5,00,000/- with a minimum interest rate of
              12.49% to 20% per annum with 6 months to 3 years loan tenures.
              Such loans are available to employees of all our Networked
              Corporates irrespective of the employee’s location of posting with
              the Corporate.
            </p>
            <p>
              In today's world, where everyone is hard-pressed for time, people
              are finding it difficult to take time from their routine and go
              after banks in case of any financial need.
            </p>
            <p>
              At this point of time, Recapita addresses this, and make it a
              win-win situation for the borrower and for the company. From a
              corporate employee's perspective, it means the convenience of
              getting a loan without having to chase banks and for the
              corporate; it is a significant employee-friendly step resulting in
              increased employee satisfaction, productivity, and loyalty.
            </p>
          </div>
          <div className={style.features}>
            <h5>Corporate HR Loan Features</h5>
            <Feature content="Fulfill your Personal Need." />
            <Feature content="No collateral- No guarantor." />
            <Feature content="Minimum Document." />
            <Feature content="Easy Repayment." />
            <Feature content="Stay Protected (personal accident cover up to Rs. 5 lakhs). (*insurance is a subject matter of solicitation', which essentially means that insurance has to be requested or asked for, not sold)." />
          </div>
        </div>
      </section>
      {/* Mortage Loan/Loan Against Property */}
      <section className={style.section}>
        <h4 className={style.heading}>Mortage Loan/Loan Against Property</h4>
        <div className={style.featureAndBenefitDescription}>
          <div className={style.content}>
            <p>
              Take your business to the next level, Apply for Mortgage Loan with
              Low-Interest rates & Flexible Repayment options. With Recapita
              Finance Mortgage Loans you can unlock the property value you own
              to avail finances when you need it. Businesses or Individuals can
              ensure the availability of required funds by leveraging the
              property value through Recapita’s Mortgage loan offerings.
            </p>
          </div>
          <div className={style.features}>
            <h5>Loan Features</h5>
            <Feature content="Fulfill your Business / Personal needs." />
            <Feature content="Lower EMI with Longer Tenor." />
            <Feature content="Competitive rate of Interest." />
            <Feature content="Easy Repayment." />
            <Feature content="Stay Protected with Loan Insurance (*insurance is a subject matter of solicitation', which essentially means that insurance has to be requested or asked for, not sold)." />
          </div>
        </div>
      </section>
      {/* Working Capital Loan */}
      <section className={style.section}>
        <h4 className={style.heading}>Working Capital Loan</h4>
        <div className={style.featureAndBenefitDescription}>
          <div className={style.content}>
            <p>
              A Working Capital loan is a loan that is occupied to finance the
              daily operations of a company and organization. Working capital
              loans are not used to buy long-term assets or investments and are,
              instead, used to cover accounts Receivables, Payable &
              Inventories, etc. Recapita provides financial support by extending
              various Working Capital Loans. We at Recapita Understand MSME’s /
              Business cycles and relevant Working Capital needs. Recapita have
              structured ‘Working Capital Loans’ specifically to suit the
              business needs of our customers, and helping them with the
              business continuity & eventually boosting the business growth.
            </p>
          </div>
          <div className={style.features}>
            <h5>Why is working capital important?</h5>
            <p>
              Because working capital is needed to ensure sustaining day-to-day
              operations of your organization. It's is absolutely critical for
              any organization, be it in manufacturing or providing services. It
              also measures the business’s ability to pay off short-term
              expenses or debts.
            </p>

            <h5>What are the four main components of working capital?</h5>
            <p>
              The four components of working capital are Cash Management,
              Receivables Management, Inventory Management, and Accounts Payable
              Management. Investors and analysts assess these aspects of your
              business to evaluate a company’s cash flow.
            </p>
          </div>
        </div>
      </section>
      {/* Director Message */}
      <section className={style.section}>
        <h4 className={style.heading}>Director's Message</h4>
        <div className={style.featureAndBenefitDescription}>
          <div className={style.content}>
            <p>Dear Patrons,</p>
            <p>
              Being one of the fastest-growing portents in the alternate finance
              industry, we are driven by a very simple goal- to support the
              growth of the MSME sector in India along with the public at large.
            </p>
            <p>
              Fulfilling client’s needs is our first priority and we are
              constantly working towards customized solutions depending upon the
              changing requirements of the clients. The success of Recapita in
              providing small-ticket loans and advances with timely repayment
              has been considered to be a sure footprint of our future growth
              strategy. We have also started the Gold Loan segment in order to
              reach out to our customer’s additional requirements.
            </p>
            <p>
              A company is known by its Directors who manage it. Our Directors
              are people with more than 22 years of combined experience in the
              Banking industry, that too having held the position of Vice
              president, General Manager, etc. in Scheduled Banks. This gives us
              impetus which many of our immediate counterparts are not able to
              emulate. We also have a dedicated staff whose approach is purely
              customer-oriented and based on a personal touch with the end-user.
            </p>
            <p>
              We have in place all necessary infrastructures, systems, and
              operational procedures that are flexible enough to accommodate the
              needs of the clients. All our operations are executed while
              strictly complying with RBI’s set up guidelines, independent of
              any market risk.
            </p>
            <p>
              As a Managing Director of the Company, I am responsible for
              spearheading Recapita Finance Private Limited to grow consistently
              in the financing industry.
            </p>
            <p>Amit Jain</p>
            <p>Managing Director</p>
            {/* <p></p> */}
          </div>
        </div>
      </section>
      {/* Contact Us */}
      <section className={style.section}>
        <h4 className={style.heading}>Contact Us</h4>
        <div className={style.featureAndBenefitDescription}>
          <div className={style.content}>
            <p>
              We're available 10:00 AM – 6:00 PM IST, Monday to Friday. We do
              our best to respond to each request with a personalized reply
              within 24-48 business hours.
            </p>
          </div>
          <div className={style.features}>
            <p>
              For any information / support you can call us at 0755-4928303, +91
              9109915411 or email at customercare@recapitafinance.com,
              loans@recapitafinance.com.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
