import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SlideStyle from "../../../../styles/Slide/style.module.css";
import style from "../../Other/AadhaarOTP/style.module.css";
import TextInputField from "../../../DataInputFields/TextInputField/TextInputField";
import { generateOTPForGST, submitConsentOTP } from '../../../../api/services/documents';
import { useContext, useEffect, useRef, useState } from 'react';
import * as Errors from "../../../../utils/errors";
import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import OtpInputField from '../../../DataInputFields/OtpInputField/OtpInputField';
import { toast } from 'react-toastify';
import ErrorContext from '../../../../context/ErrorProvider';
import CustomerContext from '../../../../context/CustomerContext';
import SuccessCard from './ConsentSuccessCard';


export const ConsentCard = ({data}) => {

    const { apiKey, apiSecret } = useParams();

    // * State to handle journey error
  const { error, setError } = useContext(ErrorContext);

  const [success,setSuccess] = useState(false);

//   * State handle
const { customerDetails, setCustomerDetails } = useContext(CustomerContext);

useEffect(() => {
    setCustomerDetails({...customerDetails,requestId:""});
},[]);


    const usernameRef = useRef();

    const headers = {
        apiKey,
        apiSecret,
      };

    const [state,setState] = useState({
        gstin:data,
        username:"",
        requestId:""
    });
    // const [error,setError] = useState({
    //     username:"",
    //     otp1:"",
    //     otp2:"",
    //     otp3:"",
    //     otp4:"",
    //     otp5:"",
    //     otp6:""
    // })

     // * Refs
  const otpRefKey1 = useRef(null);
  const otpRefKey2 = useRef(null);
  const otpRefKey3 = useRef(null);
  const otpRefKey4 = useRef(null);
  const otpRefKey5 = useRef(null);
  const otpRefKey6 = useRef(null);

    const handleSuccessGenerateOTP = (response) => {
        console.log('Success Data=>',response?.data?.data);
        if(response?.data?.isValidUsername){
            setCustomerDetails({...customerDetails,requestId:response?.data?.data?.requestId});
            setState({...state,requestId:response?.data?.data?.requestId});
            setCus
        }else{
            // setError({...error,username:"Invalid username"});
            toast.error(response?.message);
        }
    }

    const handleSubmitConsent = (response) => {
        console.log('submit response=>',response)
        if(response?.data?.status === "SUCCESS"){
            setSuccess(true);
            setCustomerDetails({...customerDetails,withGSTConsent:true});
        }else
        if(response?.data?.status === "FAILED"){
          toast.error('Invalid OTP')
        }else
        {
            setCustomerDetails({...customerDetails,withGSTConsent:false}); 
        }
    }
    console.log('CustomerDetails=>',customerDetails);
    // generateOTPForGST
    const generateOTPForGSTAPI = useMutation({
        mutationKey: ["generateOTPForGST"],
        mutationFn: (Payload) => generateOTPForGST(Payload),
        onSuccess: (data) => handleSuccessGenerateOTP(data),
        // onError: (error) => handleDocumentUploadError(error),
        retry: false,
      });

    const submitConsentOTPAPI = useMutation({
        mutationKey: ["submitConsentOTP"],
        mutationFn: (Payload) => submitConsentOTP(Payload),
        onSuccess: (data) => handleSubmitConsent(data)
    })
    const onChangeUsername = (e) => {
        const {value} =usernameRef.current;
        setState({...state,username:value});
        if(value.length === 0){
            setError({...error,username:"Please enter username"})
        }else{
            setError({...error,username:""})
        }
    }

    const submitHandler = () => {
        if(error?.username?.length >0 || state?.username?.length === 0){
            setError({
                error: true,
                type: Errors.CONSENT_OTP_ERROR_TYPE,
                message: "Please enter username",
              });
            return;
        }else{
            setError({
                error: false,
                type: "",
                message: "",
              });
          let payload = {
            username:state.username,
            gstin:state.gstin,
            headers
          }
        generateOTPForGSTAPI.mutate(payload);
        }
    }

    const handleOTPSubmit = async () => {
        const otpKey1 = otpRefKey1.current.value;
        const otpKey2 = otpRefKey2.current.value;
        const otpKey3 = otpRefKey3.current.value;
        const otpKey4 = otpRefKey4.current.value;
        const otpKey5 = otpRefKey5.current.value;
        const otpKey6 = otpRefKey6.current.value;

        let otpConsentErrorObj = error;

        if (otpKey1 === "") {
            otpConsentErrorObj = {
            error: true,
            type: Errors.CONSENT_OTP_ERROR_TYPE,
            message: Errors.CONSENT_OTP_ERROR,
          };
        }
        if (otpKey2 === "") {
            otpConsentErrorObj = {
            error: true,
            type: Errors.CONSENT_OTP_ERROR_TYPE,
            message: Errors.CONSENT_OTP_ERROR,
          };
        }
        if (otpKey3 === "") {
            otpConsentErrorObj = {
            error: true,
            type: Errors.CONSENT_OTP_ERROR_TYPE,
            message: Errors.CONSENT_OTP_ERROR,
          };
        }
        if (otpKey4 === "") {
            otpConsentErrorObj = {
            error: true,
            type: Errors.CONSENT_OTP_ERROR_TYPE,
            message: Errors.CONSENT_OTP_ERROR,
          };
        }
        if (otpKey5 === "") {
            otpConsentErrorObj = {
            error: true,
            type: Errors.CONSENT_OTP_ERROR_TYPE,
            message: Errors.CONSENT_OTP_ERROR,
          };
        }
        if (otpKey6 === "") {
            otpConsentErrorObj = {
            error: true,
            type: Errors.CONSENT_OTP_ERROR_TYPE,
            message: Errors.CONSENT_OTP_ERROR,
          };
        }    
        if (
          !otpConsentErrorObj.error &&
          otpConsentErrorObj.message !== Errors.CONSENT_OTP_ERROR_TYPE
        ) {
          const OTP = otpKey1 + otpKey2 + otpKey3 + otpKey4 + otpKey5 + otpKey6;
          const headers = {
            apiKey: apiKey,
            apiSecret: apiSecret,
          };
          console.log('Submitted OTP=>',OTP)

        let payload = {
            requestId:customerDetails?.requestId,
            otp:OTP,
            contact_id:customerDetails?.contactID,
            gstin:state.gstin,
            headers
        }
          // let payload = {
          //   requestId:"d54a68ee-5dd5-4340-89fb-58d483d4221a",
          //   otp:"454686",
          //   contact_id:customerDetails.contactID,
          //   gstin:state.gstin,
          //   headers
          // }
        submitConsentOTPAPI.mutate(payload);
        //   verifyAadhaarOtpAPI.mutate(Payload);
        } else {
          setError(otpConsentErrorObj);
        }
      };

      function handleOtp(otpKey, reference) {
        if (otpKey === "") {
          setError({
            error: true,
            type: Errors.OTP_ERROR_TYPE,
            message: Errors.NULL_OTP_ERROR,
          });
          reference.current.value = "";
          return false;
        }
        if (isNaN(otpKey)) {
          setError({
            error: true,
            type: Errors.OTP_ERROR_TYPE,
            message: Errors.INVALID_OTP_DIGIT_ERROR,
          });
          reference.current.value = "";
          return false;
        }
        if (error.error && error.type === Errors.OTP_ERROR_TYPE) {
          setError({
            error: false,
            type: "",
            message: "",
          });
        }
        if (reference.current.nextSibling) reference.current.nextSibling.focus();
        return true;
      }
    return(
        <>
        {customerDetails?.withGSTConsent ? (
            <SuccessCard gstin={data}/>
        ):(
            <Card sx={{ width:"70%",margin:"auto" }}>
            <CardContent>
            <div className={SlideStyle.dataFieldsContainer}>
      <div className={SlideStyle.inputContainer}>
         <div className={SlideStyle.labelContainer}>
            <label>
              GSTIN
            </label>
            <label className={SlideStyle.labelColor}>{data}</label>
          </div>
      <div className="dataFieldsContainer">
        <TextInputField
          type="text"
          label="Username"
          placeholder="Username"
          name="username"
          id="username"
          reference={usernameRef}
          disabled={state?.requestId?.length>0}
        //   maxLength="14"
          // minLength="12"
          errorType={Errors.USERNAME_ERROR_TYPE}
          handler={onChangeUsername}
          autoFocus={true}
          required={true}
        />
      </div>
        </div>
      </div>
      {customerDetails?.requestId?.length>0 && (
          <div className={style.consentOtpInputFields}>
          <OtpInputField
            reference={otpRefKey1}
            error={error}
            errorType={Errors.CONSENT_OTP_ERROR_TYPE}
            // onEnterHandler={handleOTPSubmit}
            handler={(otpKey) => handleOtp(otpKey, otpRefKey1)}
            size="small"
            tabIndex={1}
          />
          <OtpInputField
            reference={otpRefKey2}
            error={error}
            errorType={Errors.CONSENT_OTP_ERROR_TYPE}
            // onEnterHandler={handleOTPSubmit}
            handler={(otpKey) => handleOtp(otpKey, otpRefKey2)}
            tabIndex={2}
          />
          <OtpInputField
            reference={otpRefKey3}
            error={error}
            errorType={Errors.CONSENT_OTP_ERROR_TYPE}
            // onEnterHandler={handleOTPSubmit}
            handler={(otpKey) => handleOtp(otpKey, otpRefKey3)}
            tabIndex={3}
          />
          <OtpInputField
            reference={otpRefKey4}
            error={error}
            errorType={Errors.CONSENT_OTP_ERROR_TYPE}
            // onEnterHandler={handleOTPSubmit}
            handler={(otpKey) => handleOtp(otpKey, otpRefKey4)}
            tabIndex={4}
          />
          <OtpInputField
            reference={otpRefKey5}
            error={error}
            errorType={Errors.CONSENT_OTP_ERROR_TYPE}
            // onEnterHandler={handleOTPSubmit}
            handler={(otpKey) => handleOtp(otpKey, otpRefKey5)}
            tabIndex={5}
          />
          <OtpInputField
            reference={otpRefKey6}
            error={error}
            errorType={Errors.CONSENT_OTP_ERROR_TYPE}
            // onEnterHandler={handleOTPSubmit}
            handler={(otpKey) => handleOtp(otpKey, otpRefKey6)}
            tabIndex={6}
          />
        </div>
      ) }
      <p>{generateOTPForGSTAPI?.isPending ? "generating otp please wait...":""}</p>
            </CardContent>
            {customerDetails?.requestId?.length>0 ? (
                 <div className={SlideStyle.submitCardBtn}>
                 <div className="continueBtnContainer">
                     <button 
                    //  className={`continueBtn ${(error.username?.length>0 || state?.username?.length===0) ? "continueInActiveButton" : "continueActiveButton"}`}
                    className={`continueBtn ${
            error.error
              ? "continueInActiveButton"
              : "continueActiveButton"
          }`}
                      onClick={handleOTPSubmit}>
                     Submit OTP
                     </button>
                 </div>
                 </div>
            ):(
                <div className={SlideStyle.submitCardBtn}>
                <div className="continueBtnContainer">
                    <button className={`continueBtn ${(error.username?.length>0 || state?.username?.length===0) ? "continueInActiveButton" : "continueActiveButton"}`} onClick={submitHandler}>
                    Generate OTP
                    </button>
                </div>
                </div>
            )}

          </Card>
        )}

        </>
    )
}