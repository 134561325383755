import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

// * Style
import style from "../../IncomeVerificationSlide/SalarySlipVerification/SalarySlip.module.css";
// * Components
import DocumentPreview from "../../../DocumentPreview/DocumentPreview";
import ImageInputField from "../../../DataInputFields/ImageInputField/ImageInputField";
import ContinueButton from "../../../Buttons/SlideContinueButton/ContinueButton";
// * Icons
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import InfoIcon from "@mui/icons-material/Info";

// * API Handler
import { useMutation } from "@tanstack/react-query";
// * APIs
import {
  analyzeBankStatementAuthBridge,
  bulkCustomerUploadDocument,
  uploadCustomerDocument,
  verifyCustomerDocument,
} from "../../../../api/services/documents";
// * Helpers
import {
  convertBase64,
  getCookiePathFromURL,
  getLocationPermission,
} from "../../../../helpers";
// * Cookies
import { useCookies } from "react-cookie";
// * Notificaiton
import { toast } from "react-toastify";
// * Constants
import { DOMAIN_NAME, TOTAL_SLIDES } from "../../../../constants";
// * Contexts
import CustomerContext from "../../../../context/CustomerContext";
import DocumentUploadWithPassword from "../../../DataInputFields/ImageInputField/DocumentUploadWithPassword";

export default function CommercialBankStatement({
  switchToNextSlide,
  updateJourneyData,
  updateLastVisitSlide,
}) {
  const SALARY_SLIPS = 20;
  const [password, setPassword] = useState("");

  const [_, setCookie] = useCookies(["user"]);
  const CookiePath = getCookiePathFromURL(window.location.href);
  const { apiKey, apiSecret } = useParams();
  const { customerDetails, setCustomerDetails } = useContext(CustomerContext);

  const [documentsUploaded, setDocumentsUploaded] = useState(0);
  const [document, setDocument] = useState([]);
  const [salaryResponses, setSalaryResponses] = useState([]);
  const [documentStatus, setDocumentStatus] = useState({
    error: false,
    hasDocument: false,
  });
  useEffect(() => {
    if (documentStatus.hasDocument)
      setDocumentStatus({ error: false, hasDocument: false });
  }, [document]);
  const onUploadDoc = () => {
    console.log("on upload Doc");
    if (document && document?.length > 0) {
      setDocumentStatus({ ...documentStatus, hasDocument: true, error: false });
    }
  };
  const saveBankStatement = async () => {
    console.log("Save Bank statement", document);
    if (!document.length) {
      return;
    } else {
      setDocumentStatus({ ...documentStatus, error: false });
    }
    let docPayload = [];

    for (let doc of document) {
      if (doc && doc?.type === "application/pdf") {
        console.log(doc);
        const base64 = await convertBase64(doc);

        let Payload = {
          contact_id: customerDetails.contactID,
          pipeline_id: customerDetails.pipelineID,
          lead_id: customerDetails.leadId,
          docUpload: base64,
          fileName: doc.name,
          docType: "bankStatement",
          docFormat: "pdf",
          docNumber: "",
          source_name: "Journey",
          file_password: password,
        };
        const location = await getLocationPermission();
        if (location) {
          Payload = { ...Payload, ...location };
        }

        docPayload.push(Payload);
      }
    }
    const headers = {
      apiKey,
      apiSecret,
    };
    let completePayload = {
      data: docPayload,
      headers,
    };
    uploadCustomerDocumentAPI.mutate(completePayload);
  };
  // * API to upload the document
  const uploadCustomerDocumentAPI = useMutation({
    mutationKey: ["uploadCustomerDocument"],
    mutationFn: (Payload) => bulkCustomerUploadDocument(Payload),
    onSuccess: (data) => handleDocumentUploadSuccess(data),
    onError: (error) => handleDocumentUploadError(error),
    retry: false,
  });

  // Analyze Bank Statements
  const analyzeStatement = useMutation({
    mutationFn: (payload) => analyzeBankStatementAuthBridge(payload),
    onSuccess: (data) => analyzeStatementSuccess(data),
    onError: (error) => analyzeStatementError(error),
  });
  const analyzeStatementSuccess = (data) => {
    console.log("Analyzed response success: ", data);

    const slidePayload = {
      headers: { apiKey, apiSecret },
      leadId: customerDetails?.leadId,
      slideName: "Bank Statement",
      slideIndex: 44,
      slideIcon: "BankStatement",
      totalSlides: TOTAL_SLIDES,
    };
    console.log("success=>", slidePayload);
    // * Update Journey last visit slide
    updateLastVisitSlide(slidePayload);
    console.log("before switched to next slide success");
    switchToNextSlide();
  };

  const analyzeStatementError = (error) => {
    console.log("Analyzed response error: ", error);
    toast.error("Something went wrong, please try again.");
    const slidePayload = {
      headers: { apiKey, apiSecret },
      leadId: customerDetails?.leadId,
      slideName: "Bank Statement",
      slideIndex: 44,
      slideIcon: "BankStatement",
      totalSlides: TOTAL_SLIDES,
    };
    // * Update Journey last visit slide
    updateLastVisitSlide(slidePayload);
    console.log("error in analyze");
    switchToNextSlide();
  };

  const handleDocumentUploadSuccess = (documents) => {
    console.log("after success of upload Doc", documents);
    let statementsIds = [];
    for (let item of documents?.data) {
      let obj = {
        id: item.id,
        pwd: password,
      };
      statementsIds.push(obj);
    }

    const data = {
      statementsIds: statementsIds,
    };
    const headers = {
      apiKey,
      apiSecret,
    };
    const payload = {
      data,
      headers,
    };
    console.log("before analyze doc", payload);
    analyzeStatement.mutate(payload);
  };
  const handleDocumentUploadError = (error) => {
    console.log("inside error doc", error);
    if (error.response.status === 401) {
      localStorage.removeItem("authToken");
      saveSalarySlip();
    } else {
      toast.error("Something went wrong! please try again.");
    }
  };

  const validateDocs = async () => {
    const { hasDocument } = documentStatus;
    console.log(hasDocument);
    let data = {};
    const slidePayload = {
      slideName: "Commercial Bank Statement",
      slideIndex: 45,
      slideIcon: "CommercialBankStatement",
      totalSlides: TOTAL_SLIDES,
    };
    await updateJourneyData(data, slidePayload);
    if (hasDocument) {
      saveBankStatement();
      switchToNextSlide();
    } else if (!hasDocument && document.length === 0) {
      switchToNextSlide();
      // setDocumentStatus({ ...documentStatus, error: true });
    } else {
      saveBankStatement();
    }
  };

  const removeDocument = (documentName) => {
    const documents = document.filter((doc) => doc.name !== documentName);
    if (!documents.length) {
      setDocumentStatus({ error: true, hasDocument: false });
      setDocument([]);
    } else setDocument(documents);
  };
  return (
    <div className="carouselSlideWrapper salarySlipAndBankStatementSlideWrapper">
      <div className="typographyContainer">
        <h1>Income Verification</h1>
        <p>Upload Bank Statement(s)</p>
      </div>

      <div className="dataFieldsContainer imageInputContainer">
        <div className={style.documentInputBox}>
          {document
            ? document.map((doc, index) => (
                <DocumentPreview
                  key={`${doc.name}_${index}`}
                  document={doc}
                  removeDocument={removeDocument}
                  removeTitle="Delete Salary Slip"
                />
              ))
            : null}
          {/* <ImageInputField
            label="Upload Salary Slip"
            icon={
              <ReceiptOutlinedIcon
                style={{ fontSize: "45px", color: "rgb(189, 205, 255)" }}
              />
            }
            accept="image/png, image/jpeg, image/png, application/pdf"
            allowedFileTypes="jpg, jpeg, png, pdf"
            file={document}
            setFile={setDocument}
            isDocAvailable={documentStatus.error}
            isLoading={uploadCustomerDocumentAPI.isPending ? true : false}
            hasDocument={documentStatus.hasDocument}
            multiple={true}
            max={SALARY_SLIPS}
          /> */}
          <DocumentUploadWithPassword
            label="Upload Bank Statement"
            icon={
              <DescriptionOutlinedIcon
                style={{ fontSize: "45px", color: "rgb(189, 205, 255)" }}
              />
            }
            // accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf"
            // allowedFileTypes="excel, pdf"
            accept="application/pdf"
            allowedFileTypes="pdf"
            file={document}
            setFile={setDocument}
            // handler={saveBankStatement}
            isDocAvailable={documentStatus.error}
            isLoading={uploadCustomerDocumentAPI.isPending}
            hasDocument={documentStatus.hasDocument}
            max={SALARY_SLIPS}
            password={password}
            multiple={true}
            setPassword={setPassword}
          />
        </div>
        <div className="">
          <ul>
            <li>
              <InfoIcon style={{ color: "#404040", fontSize: "20px" }} />
              Please do not upload password protected file.
            </li>
          </ul>
        </div>
      </div>

      <div className="imageInputContinueBtn">
        <div className={style.continueBtn}>
          <ContinueButton
            label="Next"
            loading={
              uploadCustomerDocumentAPI.isPending || analyzeStatement.isPending
            }
            handler={validateDocs}
          />
        </div>
      </div>
    </div>
  );
}
