// * Data Validation Error Types

// * Text Input Error Types
export const MOBILE_NUMBER_ERROR_TYPE = "mobileNumber";
export const OTP_ERROR_TYPE = "otpError";
export const RESEND_OTP_ERROR_TYPE = "resendOtpError";
export const FULLNAME_ERROR_TYPE = "fullName";
export const DOB_ERROR_TYPE = "DOB";
export const PAN_ERROR_TYPE = "PAN";
export const UDYAM_REGISTRATION_ERROR_TYPE = "UDYAM";
export const PERSONAL_EMAIL_ERROR_TYPE = "personalEmailError";
export const PROFESSIONAL_EMAIL_ERROR_TYPE = "professionalEmailError";
export const EDUCATIONAL_QUALIFICATION_ERROR_TYPE = "emailError";
export const MONTHLY_INCOME_ERROR_TYPE = "monthlyIncomeError";
export const SALARY_CREDIT_TYPE_ERROR_TYPE = "salaryCreditTypeError";
export const USERNAME_ERROR_TYPE = "usernameError";

export const SHOP_NAME_ERROR_TYPE = "shopNameError";
export const RESIDENTIAL_ADDRESS1_ERROR_TYPE = "currentAddress1Error";
export const RESIDENTIAL_ADDRESS2_ERROR_TYPE = "currentAddress2Error";
export const RESIDENTIAL_CITY_ERROR_TYPE = "currentCityError";
export const RESIDENTIAL_STATE_ERROR_TYPE = "currentStateError";
export const RESIDENTIAL_PIN_ERROR_TYPE = "residentialPinError";

export const PERMANENT_ADDRESS1_ERROR_TYPE = "permanentAddress1Error";
export const PERMANENT_ADDRESS2_ERROR_TYPE = "permanentAddress2Error";
export const PERMANENT_CITY_ERROR_TYPE = "permanentCityError";
export const PERMANENT_STATE_ERROR_TYPE = "permanentStateError";
export const PERMANENT_PIN_ERROR_TYPE = "permanentPinError";

export const OFFICE_ADDRESS1_ERROR_TYPE = "officeAddress1Error";
export const OFFICE_ADDRESS2_ERROR_TYPE = "officeAddress2Error";
export const OFFICE_CITY_ERROR_TYPE = "officeCityError";
export const OFFICE_STATE_ERROR_TYPE = "officeStateError";
export const OFFICE_PIN_ERROR_TYPE = "officePinError";

export const COMPANY_NAME_ERROR_TYPE = "companyNameError";
export const COMPANY_TYPE_ERROR_TYPE = "companyTypeError";
export const DESIGNATION_ERROR_TYPE = "designationError";
export const WORK_EXPERIENCE_ERROR_TYPE = "workExperienceError";
export const DURATION_OF_ESTABLISHMENT_TYPE = "durationOfEstablishmentError";
export const SHOP_OWNERSHIP_TYPE = "shopOwnershipTypeError";
export const BUSSINES_NATURE_ERROR_TYPE = "businessNatureError";
export const INCORPORATION_DATE_ERROR_TYPE = "inCorporationDateError";
export const CO_APPLICANT_FULLNAME_ERROR_TYPE = "coApplicantFullNameError";
export const CO_APPLICANT_DOB_ERROR_TYPE = "coApplicantDOBError";
export const CO_APPLICANT_PAN_ERROR_TYPE = "coApplicantPANError";
export const CO_APPLICANT_MOBILE_ERROR_TYPE = "coApplicantMobileError";
export const CO_APPLICANT_EMAIL_ERROR_TYPE = "coApplicantEmailError";
export const CO_APPLICANT_RELATION_ERROR_TYPE = "coApplicantRelationError";
export const LOAN_AMOUNT_ERROR_TYPE = "loanAmountError";
export const UNKNOWN_ERROR_TYPE = "unknownErrorType";
export const AADHAR_NUMBER_ERROR_TYPE = "aadhaarErrorType";
export const AADHAR_OTP_ERROR_TYPE = "aadhaarErrorType";
export const AADHAR_OTP_RESENT_ERROR_TYPE = "aadhaarErrorType";

// * Address Verificaton Error Types
export const ADDRESS_VERIFICATION_DOCUMENT =
  "addressVerificationDocumentErrorType";

// * Selector Buttons Error Types
export const GENDER_ERROR_TYPE = "genderError";
export const PURPOSE_OF_LOAN = "purposeOfLoanError";
export const MARITAL_STATUS_ERROR_TYPE = "maritalStatusError";
export const CO_APPLICANT_TYPE_ERROR = "coApplicantAvailableError";
export const CO_APPLICANT_GENDER_TYPE_ERROR = "coApplicantGenderError";
export const EMPLOYEMENT_TYPE_ERROR = "employementTypeError";

// * Aadhaar Error Types
export const AADHAAR_OTP_ERROR_TYPE = "aadhaarOtpTypeError";

// * GST OTP Types
export const CONSENT_OTP_ERROR_TYPE = "consentOtpTypeError";

// * Penny Drop
export const ACCOUNT_NUMBER_ERROR_TYPE = "accountNumberTypeError";
export const CONFIRM_ACCOUNT_NUMBER_ERROR_TYPE =
  "confirmAccountNumberTypeError";
export const IFSC_CODE_ERROR_TYPE = "ifscTypeError";
export const BANK_ACCOUNT_ERROR_TYPE = "bankAccountTypeError";

// ! Data Validation Error Message
// * UNKNOWN Error Message
export const UNKNOWN_ERROR = "An error occured, please try again.";
// * Mobile Number Validation Error Message
export const NULL_PHONE_ERROR = "Please enter your mobile number.";
export const INVALID_DIGITS_PHONE_ERROR =
  "Moblie can only contain numeric digits.";
export const INVALID_LENGTH_PHONE_ERROR =
  "Mobile number should contain only 10 digits.";
export const INVALID_PHONE_FORMAT_ERROR =
  "Mobile number should start with 6, 7, 8 or 9";
export const DUPLICATE_PHONE_ERROR =
  "Co-Applicant mobile number cannot be same as customer mobile.";
// * OTP Validaiton Error Messages
export const INVALID_OTP_ERROR = "Invalid OTP";
export const NULL_OTP_ERROR = "Please enter OTP";
export const INVALID_OTP_DIGIT_ERROR = "OTP must be a number.";
export const OTP_ERROR = "Please enter a valid OTP.";
// * User Individual Details Error Messages
export const FULLNAME_ERROR = "Fullname is required.";
export const FULLNAME_SPECIAL_CHARACTER_ERROR =
  "Fullname cannot contain special character.";
// export const FULLNAME_DIGIT_ERROR = "Fullname cannot contain numeric values.";
export const FULLNAME_DIGIT_ERROR = "Fullname cannot start with a number.";
export const DOB_ERROR = "Date of birth is required.";
export const INVALID_DOB_ERROR = "Invalid Date of birth.";
export const DOB_NOT_ELIGIBLE_ERROR = "You are not eligible for this loan.";
export const MAX_AGE_ERROR = "Age shouldn't more than 80 years.";
export const PAN_ERROR = "PAN is required.";
export const UDYAM_REGISTRATION_ERROR = "UDYAM Registration number is required.";
export const INVALID_UDYAM_REGISTRATION_ERROR = "Invalid Udyam Registration number.";
export const UDYAM_REGISTRATION_VERIFICATION_ERROR = "Verification Failed!";
export const INVALID_PAN_ERROR = "Invalid PAN.";
export const INVALID_PHONE_ERROR =
  "Your mobile number must be linked with your PAN and Aadhaar.";
// * Email Address Validation Error Message
export const INVALID_EMAIL_ERROR = "Please enter a valid email address.";
export const NULL_EMAIL_ERROR = "Please enter a your email address.";
export const SAME_EMAIL_ERROR =
  "Personal email and professional email cannot be same.";
// * Educational Qualificatioin Error message
export const NULL_EDUCATIONAL_QUALIFICATION_ERROR =
  "Educational qualification is required.";
// * Monthly Income Validation Error Message
export const NULL_MONTHLY_INCOME_ERROR = "Please enter your monthly income.";
export const INVALID_FORMAT_MONTHLY_INCOME_ERROR =
  "Monthly income can only contain numeric values.";
export const NULL_SALARY_CREDIT_TYPE_ERROR = "Salary credit type is required.";
export const LIMIT_MONTHLY_INCOME_ERROR =
  "Monthly income cannot less than 20,000";
export const NUMBER_STARTING_FROM_ZERO = "Invalid number.";
export const ZERO_MONTHLY_INCOME_ERROR = "Monthly income cannot be 0.";
export const NEGATIVE_EXPERIENCE = "Work experience cannot be less than 0."
// export const ZERO_MONTHLY_INCOME_ERROR =
//   "Monthly income cannot be zero or negative.";
export const HIGH_MONTHLY_INCOME_ERROR =
  "Income cannot be higher than 9,00,000";
// * Residential Address Error Messages
export const NULL_SHOP_NAME_ERROR = "Shop name is required.";

export const RESIDENTIAL_ADDRESS1_ERROR = "Current address is required.";
export const RESIDENTIAL_ADDRESS2_ERROR =
  "Another Current address is required.";
export const RESIDENTIAL_CITY_ERROR = "City is required.";
export const RESIDENTIAL_STATE_ERROR = "State is required.";
export const ADDRESS_TYPE = "Address type is required.";
export const NATURE_OF_ADDRESS = "Nature of address is required.";
export const YEARS_AT_CURRENT_ADDRESS = "Months at current address is required."; // * Might be unused
export const RESIDENTIAL_PIN_ERROR = "Pin is required.";
export const INVALID_RESIDENTIAL_PINCODE_ERROR = "Invalid pincode.";
export const RESIDENTIAL_NULL_ADDRESS_TYPE_PINCODE_ERROR =
  "Address type is required.";
export const RESIDENTIAL_NULL_NATURE_OF_ADDRESS_ERROR =
  "Nature of address is required.";
export const RESIDENTIAL_NULL_YEARS_AT_CURRENT_ADDRESS_ERROR =
  "Months at current address is required.";
export const INVALID_RESIDENTIAL_YEARS_AT_CURRENT_ADDRESS_ERROR =
  "Months at current address should be a number.";
export const INVALID_RESIDENTIAL_YEARS_AT_CURRENT_ADDRESS_WITH_AGE_ERROR =
  "The months at your current address cannot exceed your age. Please enter a valid duration.";
export const NULL_ADDRESS_VERIFICATION_DOCUMENT_TYPE =
  "Verification document is required.";
// * Commont Address and Date Error Messages
export const SAME_DATE_ERROR =
  "Date of birth cannot be same or greater as current date.";
export const INVALID_NUMBER_ADDRESS_ERROR = "Invalid Address.";
export const INVALID_SPECIAL_ADDRESS_ERROR =
  "Special characters not allowed in address.";
export const NULL_CITY_STATE_ERROR = "This field is required.";
export const PINCODE_LENGTH_ERROR = "Pincode should be 6 characters long.";
export const INVALID_DIGITS_PINCODE =
  "Pincode can only contain numeric values.";
// export const INVALID_CITY_STATE_DIGIT_ERROR = "Can only contain alphabets";
export const INVALID_CITY_STATE_DIGIT_ERROR = "Cannot start with a number.";

// * Permanent Address Error Messages
export const PERMANENT_ADDRESS1_ERROR = "Permanent address is required.";
export const PERMANENT_ADDRESS2_ERROR =
  "Another permanent address is required.";
export const PERMANENT_CITY_ERROR = "City is required.";
export const PERMANENT_STATE_ERROR = "State is required.";
export const PERMANENT_PIN_ERROR = "Pin is required.";

// * Office Address Error Messages
export const OFFICE_ADDRESS1_ERROR = "Office address is required.";
export const OFFICE_ADDRESS2_ERROR = "Another Office address is required.";
export const OFFICE_CITY_ERROR = "City is required.";
export const OFFICE_STATE_ERROR = "State is required.";
export const OFFICE_PIN_ERROR = "Pin is required.";
export const INVALID_OFFICE_PINCODE_ERROR = "Invalid pincode.";
export const RESIDENTIAL_ADDRESS_TYPE_ERROR_TYPE = "residentialAddressType";
export const RESIDENTIAL_NATURE_OF_ADDRESS_ERROR_TYPE =
  "residentialNatureOfAddressError";
export const RESIDENTIAL_YEARS_AT_CURRENT_ADDRESS_ERROR_TYPE =
  "residentialYearsAtCurrentAddress";
export const ADDRESS_VERIFICATION_DOCUMENT_TYPE_ERROR_TYPE =
  "residentialDocumentTypeError";
//  Company Details Error Messages
export const NULL_COMPANY_NAME_ERROR = "Company name is required.";
export const INVALID_COMPANY_NAME_ERROR = "Invalid company name.";
export const NULL_DESIGNATION_ERROR = "Designation is requried";
export const INVALID_DESIGNATION_ERROR = "Invalid designation";
export const NULL_WORK_EXPERIENCE_ERROR_TYPE = "Work experience is required";
export const INVALID_WORK_EXPERIENCE_ERROR_TYPE = "Invalid work experience";
export const AGE_CONFLICT_WORK_EXPERIENCE_ERROR =
  "Work experience cannot be greater than age.";
export const NULL_COMPANY_TYPE_ERROR = "Company type is required.";

// * Business Details (J3)
export const NULL_DURATION_OF_ESTABLISHMENT =
  "Duration of establishment is requried.";
export const NULL_SHOP_OWNERSHIP_TYPE = "Shop ownership type is requried.";

// * Business Details Error Messages
export const BUSINESS_NATURE_ERROR = "Nature of busines s is required.";
export const INCORPORATION_DATE_ERROR =
  "Business corporation date of is required.";
export const SAME_INCORPORATION_ERROR =
  "Incorporation date cannot be same or greater as current date.";

// * Add Co-Application Error Messages
export const CO_APPLICANT_FULLNAME_ERROR = "Co-Applicant name is required.";
export const CO_APPLICANT_DOB_ERROR = "Date of birth is required.";
export const CO_APPLICANT_PAN_ERROR = "PAN is required.";
export const CO_APPLICANT_DUPLICATE_PAN_ERROR =
  "Co-Applicant PAN cannot be same as applicant PAN.";
export const CO_APPLICANT_MOBILE_ERROR = "Mobile is required.";
export const CO_APPLICANT_EMAIL_ERROR = "Please enter a valid email address.";
export const CO_APPLICANT_DUPICATE_EMAIL_ERROR =
  "Co-Applicant email cannot be same as applicant personal email.";
export const NULL_CO_APPLICANT_RELATION_ERROR = "Relation is required.";
// * Loan Amount Error
export const LOAN_AMOUNT_NULL_ERROR = "Loan amount is required.";
export const LOAN_AMOUNT_ZERO_ERROR = "Loan amount cannot be zero.";
export const LOAN_AMOUNT_LOW_LIMIT_ERROR =
  "Loan amount cannot be lower than 10,000.";
export const LOAN_AMOUNT_HIGH_LIMIT_ERROR =
  "Loan amount cannot be more than 50Cr.";
// * Aadhaar Error Messages
export const NULL_AADHAAR_NUMBER_ERROR = "Aadhaar number is required.";
// * Aadhaar OTP
export const INVALID_AADHAAR_NUMBER_ERROR = "Invalid Aadhaar number.";
export const INVALID_AADHAAR_OTP_ERROR = "Invalid OTP.";
export const NULL_AADHAAR_OTP_ERROR = "Please enter OTP.";
export const INVALID_AADHAAR_OTP_DIGIT_ERROR = "OTP must be a number.";
export const AADHAAR_OTP_ERROR = "Please enter a valid OTP.";
export const CONSENT_OTP_ERROR = "Please enter a valid OTP";

// * Penny Drop
export const NULL_BANK_ACCOUNT_ERROR = "Account number is required.";
export const CONFIRM_BANK_ACCOUNT_MISMATCH_ERROR =
  "Bank account number do not match.";
export const INVALID_BANK_ACCOUNT_ERROR =
  "Account number should contain numbers only.";
export const MISMATCH_BANK_ACCOUNT_ERROR =
  "Account number should match with bank statement account number.";
export const NULL_IFSC_CODE_ERROR = "IFSC is required.";
export const INVALID_IFSC_CODE_ERROR = "Invalid IFSC code.";
export const IFSC_MISMATCH_ERROR =
  "IFSC should match with the IFSC in bank statement.";
