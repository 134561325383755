// * Input : 2023-10-10T06:32:58.000Z | Output : 10-Oct-2023
export function formatDateTime2(inputDate) {
  const date = new Date(inputDate);

  const day = String(date.getDate()).padStart(2, "0");
  const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
    date
  );
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
}

export const formatDateWithTime = (date) => {
  return Intl.DateTimeFormat("en-IN", {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(new Date(date));
};
