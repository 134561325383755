import axiosWithDomain from "../../axios";
import {
  GET_SANCTION_LETTER_TEMPLATE,
  GET_SANCTION_LETTER_DOCUMENT,
  ADD_SANCTION_LETTER_DOCUMENT,
  DELETE_SANCTION_LETTER_DOCUMENT,
  UPDATE_SANCTION_LETTER_DOCUMENT,
  SEND_SANCTION_LETTER_MAIL,
} from "../../../constants/urls";

export const getSanctionLettertTemplate = async (Payload) => {
  const { pipelineId, headers } = Payload;

  const res = await axiosWithDomain.get(
    `${process.env.REACT_APP_API_BASE_URL}${GET_SANCTION_LETTER_TEMPLATE}/${pipelineId}`,
    { headers }
  );
  return res?.data;
};

export const getSanctionLetterDocument = async (Payload) => {
  const { leadId, headers } = Payload;

  const res = await axiosWithDomain.get(
    `${process.env.REACT_APP_API_BASE_URL}${GET_SANCTION_LETTER_DOCUMENT}/${leadId}`,
    { headers }
  );
  return res?.data;
};

export const addSanctionLetterDocument = async (Payload) => {
  const { data, headers } = Payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${ADD_SANCTION_LETTER_DOCUMENT}`,
    data,
    { headers }
  );
  return res?.data;
};

export const updateSanctionLetterDocument = async (Payload) => {
  const { data, leadId, headers } = Payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${UPDATE_SANCTION_LETTER_DOCUMENT}/${leadId}`,
    data,
    { headers }
  );
  return res?.data;
};

export const deleteSanctionLetterDocument = async (Payload) => {
  const { data, headers } = Payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${DELETE_SANCTION_LETTER_DOCUMENT}`,
    data,
    { headers }
  );
  return res?.data;
};

export const sendSanctionLetterMail = async (Payload) => {
  const { data, headers } = Payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${SEND_SANCTION_LETTER_MAIL}`,
    data,
    { headers }
  );
  return res?.data;
};
