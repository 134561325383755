import * as actions from "../actions/types";
import * as urls from "../../urls";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { DOMAIN_NAME } from "../../constants";

function realTimeLeadPushApi(params) {
  let headerObj = params.headerData;
  // let bodyObj = params.bodyDetails;
  let bodyData = params.realTimeLeadPushFun;
  // let { userToken = {} } = bodyObj;

  return axios.request({
    method: "post",
    url: `${urls.REAL_TIME_LEAD_PUSH_URL}`,
    headers: {
      domain_name: DOMAIN_NAME,
      ...headerObj,
      // Authorization: "Bearer " + userToken,
      Authorization: "Bearer " + params.authToken,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Methods": "*",
    },
    data: bodyData,
  });
}

export function* realTimeLeadPushActionEffect(realTimeLeadPushAction) {
  const { payload, resolve, reject } = realTimeLeadPushAction;

  try {
    const { data } = yield call(realTimeLeadPushApi, payload);
    if (data) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.GET_REAL_TIME_LEAD_PUSH_SUCCESS,
        payload: data,
      });
    } else {
      reject(data);
    }
  } catch (e) {
    if (reject) reject(e);
    return yield put({
      type: actions.GET_REAL_TIME_LEAD_PUSH_SUCCESS,
      payload: e,
    });
  }
}

export function* realTimeLeadPushActionWatcher() {
  yield takeLatest(
    actions.GET_REAL_TIME_LEAD_PUSH,
    realTimeLeadPushActionEffect
  );
}

export default function* rootSaga() {
  yield all([fork(realTimeLeadPushActionWatcher)]);
}
