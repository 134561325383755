import style from "./LoanOffer.module.css";
import {
  useState,
  useReducer,
  useEffect,
  useContext,
  useLayoutEffect,
} from "react";
import { useParams } from "react-router-dom";
import queryString from "query-string";
// * Components
import ContinueBtn from "../../Buttons/SlideContinueButton/ContinueButton";
import RangeInputField from "../../DataInputFields/RangeInputField/RangeInputField2/RangeInputField2";
import styleText from "../../DataInputFields/RangeInputField/RangeInputField2/RangeInputField2.module.css";
import Loader from "../../Loader/Loader";
import { useLocation } from "react-router-dom";
import {
  calculateAge2,
  calculateEMIWithLoanAmount,
  calculteEMI,
  extractNumber,
  getCustomerMonthlyObligation,
  getLocationPermission,
} from "../../../helpers";
// * Icons
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
// * API Handler
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
// * APIs
import {
  getCRIFData,
  getCRIFData_DB,
  getOfferSettings,
  addLoanOfferDetails,
  getLeadDetails,
  getCustomerConsent,
  updateLeadData,
} from "../../../api/services/Journey";
import {
  uploadCustomerDocument,
  addCustomerDocumentKycResponse,
} from "../../../api/services/documents";
import { getSanctionLettertTemplate } from "../../../api/services/SanctionLetter";
import { getAgreementTemplate } from "../../../api/services/Agreement";
import {
  getAllSalarySlips,
  getInterestRate,
} from "../../../api/services/MyFinDoc";

// * Context/Provider
// import LoanAgreementModalProvider from "../../../context/LoanAgreementModalContextProvider";
import CustomerContext from "../../../context/CustomerContext";
// * Constants
import { TOTAL_SLIDES, DOMAIN_NAME } from "../../../constants";
// * Helpers
import {
  parseCustomDate,
  removeAllCookies,
  getCookiePathFromURL,
} from "../../../helpers";
import {
  createPreSetValues,
  fetchDocumentVariables,
  getDateByOffset,
} from "../../../utils/SanctionLetter/SanctionLetter.utils";
// * Hooks
import useDebounce from "../../../hooks/useDebounce";
// * Utilities
import { handleDocumentVariableChange } from "../../../utils/SanctionLetter/SanctionLetter.utils";

import { fetchBankDetails } from "../../../api/services/Bank Verificaiton";
import { getRepaymentSchedule } from "../../../api/services/Maxemo";
import moment from "moment/moment";

const LoanOffer = ({
  pipelineSettings,
  setSanctionLetter,
  setAgreement,
  switchToNextSlide,
  updateLastVisitSlide,
  handleSendLeadDetailViaEmail,
  tenure,
  loanAmt,
  handleUpdateLeadStageStatus,
  smsTemplates,
  sendStageWiseSMS,
  rejectLead,
  handleCreateTimeline,
}) => {
  const queryClient = useQueryClient();

  const CookiePath = getCookiePathFromURL(window.location.href);
  const { apiKey, apiSecret } = useParams();

  let url = useLocation().search;
  let queryValues = queryString.parse(url);
  const JourneyVersion = queryValues?.jrnyTyp;

  const { customerDetails, setCustomerDetails } = useContext(CustomerContext);
  const [bankDetails, setBankDetails] = useState(null);
  const [offerSettings, setOfferSettings] = useState([]);

  const initialTemplateState = {
    template: null,
    updatedTemplate: null,
    content: null,
  };
  const [sanctionLetterTemplate, setSanctionLetterTemplate] =
    useState(initialTemplateState);
  const [agreementTemplate, setAgreementTemplate] =
    useState(initialTemplateState);
  const [leadDetails, setLeadDetails] = useState(null);
  const [consent, setConsent] = useState(null);
  const [netDisbursalAmt, setNetDisbursalAmt] = useState(null);
  const [interestAmt, setInterestAmt] = useState(null);
  const [emiAPI, setApiEMI] = useState(null);
  const aprParameters = {
    interestExpenses: null,
    totalFee: null,
    principalLoanAmount: null,
    tenureInDays: null,
  };
  const [aprParams, setAprParams] = useState(aprParameters);
  const [interestRate, setInterestRate] = useState(null); // For Myfindoc only
  const [emi, setEMI] = useState(null); // For Myfindoc only
  const [emiDate, setEmiDate] = useState(null); // For Myfindoc only
  const [otherSettings, setOtherSettings] = useState({});
  const [salarySlips, setSalarySlips] = useState(null);
  const [lenderOfferDetails, setLenderOfferDetails] = useState({
    minLoanAmount: null,
    maxLoanAmount: null,
    loanStep: null,
    minTenure: null,
    maxTenure: null,
    tenureStep: null,
    interestRate: null,
    FOIR_Limit: null,
    PF: null,
    overDueCharges: null,
  });

  const getFirstEMIDate = (emiType, schedule) => {
    let firstEMIDate = null;
    if (schedule && Array.isArray(schedule) && schedule.length) {
      for (let i = 0; i < schedule.length; i++) {
        if (schedule[i]?.principal && parseInt(schedule[i]?.principal) > 0) {
          firstEMIDate = schedule[i]?.end_date;
          break;
        }
      }
    }
    return firstEMIDate ? moment(firstEMIDate).format("YYYY-MM-DD") : null;
  };

  const getRepaymentScheduleAPI = useMutation({
    mutationFn: (payload) => getRepaymentSchedule(payload),
    onSuccess: (data) => handleGetRepaymentScheduleSuccess(data),
  });
  const handleGetRepaymentScheduleSuccess = (data) => {
    const disbursalDetails = data?.data?.cashFlow?.net_disbural_amt_Details;
    const repaymentSchedule = data?.data?.emiRepaymentSchedule;
    if (disbursalDetails && repaymentSchedule) {
      const schedule = {
        repaymentSchedule,
        scheduleAvailable: true,
        totalAmountPay: disbursalDetails?.totalAmountPay,
        totalCharges: disbursalDetails?.totalCharges,
        totalInterestCharge: disbursalDetails?.total_Interest_charge,
        totalDays: disbursalDetails?.total_days,
        disbursalLoanAmount: disbursalDetails?.net_disbural_amount,
        APR: disbursalDetails?.totalApr,
      };
      dispatch({
        type: "SCHEDULE-DETAILS",
        payload: schedule,
      });
    }

    let netInterestAmt =
      data?.data?.cashFlow?.net_disbural_amt_Details?.totalInterest;
    setInterestAmt(netInterestAmt);

    if (DOMAIN_NAME !== "myfindoc") {
      let netDisbursalAmt =
        data?.data?.cashFlow?.net_disbural_amt_Details?.net_disbural_amount;
      let emi = data?.data?.emiRepaymentSchedule[0]?.emi;
      setNetDisbursalAmt(netDisbursalAmt);
      setApiEMI(emi);
    } else {
      if (
        data?.data?.emiRepaymentSchedule &&
        Array.isArray(data?.data?.emiRepaymentSchedule) &&
        data?.data?.emiRepaymentSchedule.length
      ) {
        for (let ele of data?.data?.emiRepaymentSchedule) {
          if (
            ele.principal &&
            !isNaN(ele.principal) &&
            parseInt(ele.principal) > 0
          ) {
            setEMI(ele.emi.toFixed(2));
          }
        }
      }
    }

    if (data?.data?.emiRepaymentSchedule?.length) {
      let interestExpenses = 0;
      for (let i = 0; i < data.data.emiRepaymentSchedule.length; i++) {
        interestExpenses += data.data.emiRepaymentSchedule[i].interest_charge;
      }

      const firstSchedule = data?.data?.emiRepaymentSchedule[0];
      setOtherSettings((prev) => ({
        ...prev,
        "Gap Interest":
          firstSchedule?.principal === 0 ? firstSchedule?.interest_charge : 0,
      }));

      setAprParams((prev) => ({ ...prev, interestExpenses }));
    }

    // * First EMI Date
    const firstEMIDate = getFirstEMIDate(
      pipelineSettings.emi_type,
      data?.data?.emiRepaymentSchedule
    );
    setEmiDate(firstEMIDate);
  };
  const handleGetRepaymentSchedule = (interest, loanAmount, loanTenure) => {
    if (interest && loanAmount && loanTenure) {
      const {
        emi_type,
        emi_cut_off_date,
        firstCollectionDate,
        emi_disbursal_holding_days,
        emi_flat_or_reducing,
      } = pipelineSettings;

      const headers = { apiKey, apiSecret };
      let data = {
        loan: loanAmount,
        roi: interest,
        tenor: loanTenure,
        emi_cut_off_date: emi_cut_off_date,
        emi_date: firstCollectionDate,
        emi_type: emi_flat_or_reducing,
        repayment_type: emi_type,
        disbursalDate:
          emi_disbursal_holding_days !== null
            ? getDateByOffset(emi_disbursal_holding_days)
            : getDateByOffset(0),
      };

      if (customerDetails?.date_of_birth && DOMAIN_NAME === "finture") {
        const age = calculateAge2(customerDetails.date_of_birth);
        data.age = age;
      }

      let payload = {
        headers,
        data,
      };
      getRepaymentScheduleAPI.mutate(payload);
    }
  };

  let EMI = 0;
  if (loanAmt && tenure) {
    let loan = parseInt(loanAmt);
    let newTenure = parseInt(tenure);

    EMI = Math.ceil(loan / newTenure);
  }

  const [EMI_Flag, setEMI_Flag] = useState(false);
  const initialDetailsState = {
    repaymentScheduleDetails: {
      scheduleAvailable: false,
      repaymentSchedule: null,
      totalAmountPay: null,
      totalCharges: null,
      totalInterestCharge: null,
      totalDays: null,
      disbursalLoanAmount: null,
      APR: null,
    },
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case "SCHEDULE-DETAILS":
        return { ...state, repaymentScheduleDetails: action.payload };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, initialDetailsState);

  const [loanDetails, setLoanDetails] = useState({
    maxLoanAmountAvailable: DOMAIN_NAME !== "maxemocapital" ? null : loanAmt,
    loanAmount:
      DOMAIN_NAME === "maxemocapital"
        ? loanAmt
        : customerDetails.loanAmount
        ? parseInt(customerDetails.loanAmount)
        : 0,
    maxLoanAmount: DOMAIN_NAME !== "maxemocapital" ? null : loanAmt,
    monthlyIncome: customerDetails.monthlyIncome
      ? customerDetails.monthlyIncome
      : customerDetails?.contact?.your_net_monthly_income
      ? parseInt(customerDetails?.contact?.your_net_monthly_income)
      : 0,
    monthlyObligations: null,
    loanDecreasingStep: null,
    FOIR_Limit: null,
    tenureInMonths: DOMAIN_NAME !== "maxemocapital" ? null : tenure,
    interest: DOMAIN_NAME !== "maxemocapital" ? null : tenure,
    EMI: EMI,
  });
  console.log("loanDetails : ", loanDetails);

  // * For MyFinDoc Only
  const updatedLoanAmount = useDebounce(loanDetails.loanAmount, 400);
  useEffect(() => {
    if (updatedLoanAmount && DOMAIN_NAME === "myfindoc") {
      const data = {
        your_desired_loan_amount: updatedLoanAmount,
      };
      handleUpdateJourneyData(data);
    }
  }, [updatedLoanAmount]);
  // * MyFinDoc Only
  const updatedTenure = useDebounce(loanDetails.tenureInMonths, 400);
  useEffect(() => {
    if (
      (DOMAIN_NAME === "myfindoc" || DOMAIN_NAME === "finture") &&
      interestRate
    ) {
      const { tenureInMonths, loanAmount } = loanDetails;
      let interest =
        DOMAIN_NAME === "myfindoc"
          ? (interestRate * 12) / 100
          : loanDetails.interest / 100;
      // let interest =
      //   DOMAIN_NAME === "myfindoc" ? interestRate : loanDetails.interest / 100;

      handleGetRepaymentSchedule(interest, loanAmount, tenureInMonths);
    }
  }, [updatedTenure]);

  // * Get All Salary Slip (OCR Response) - (Used By: finture)
  const getAllSalarySlipsAPI = useMutation({
    mutationFn: (payload) => getAllSalarySlips(payload),
    onSuccess: (data) => handleGetAllSalarySlipsSuccess(data),
    onError: (error) => handleGetAllSalarySlipsError(error),
    enabled:
      DOMAIN_NAME === "finture" &&
      customerDetails.employment_type === "Salaried"
        ? true
        : false,
    retry: false,
  });
  const handleGetAllSalarySlipsSuccess = (data) => {
    if (
      data.data?.salarySlips &&
      Array.isArray(data.data?.salarySlips) &&
      data.data?.salarySlips?.length
    ) {
      setSalarySlips(data.data.salarySlips);
    }
  };
  const handleGetAllSalarySlipsError = (error) => {
    console.log("Error : ", error);
  };
  const handleGetSalarySlips = () => {
    const headers = { apiKey, apiSecret };
    const data = {
      pipeline_id: customerDetails?.pipelineID,
      lead_id: customerDetails?.leadId,
      last_month_count: pipelineSettings?.salarySlipDurationInMonth,
    };
    const payload = { headers, data };
    getAllSalarySlipsAPI.mutate(payload);
  };

  // * Update Lead Info
  const updateJourneyDataAPI = useMutation({
    mutationFn: (payload) => updateLeadData(payload),
    onSuccess: () => handleGetInterestRate(),
  });
  const handleUpdateJourneyData = (data) => {
    const headers = {
      apiKey,
      apiSecret,
    };
    const payload = {
      headers,
      data,
      params: { appId: customerDetails.appID },
    };
    updateJourneyDataAPI.mutate(payload);
  };
  const finishJourney = () => {
    const slidePayload = {
      headers: { apiKey, apiSecret },
      leadId: customerDetails?.leadId,
      slideName: "Success : Journey Completed",
      slideIndex: 38,
      slideIcon: "Success",
      totalSlides: TOTAL_SLIDES,
      journeyVersion: JourneyVersion,
    };
    // * Update Journey last visit slide
    updateLastVisitSlide(slidePayload);

    // * Send Lead Details to the domain owner via email.
    if (DOMAIN_NAME === "maxemocapital") {
      handleSendLeadDetailViaEmail(loanAmt);
    } else {
      handleSendLeadDetailViaEmail(loanDetails.loanAmount);
    }

    // * Finish Journey and clear all the cookies.
    removeAllCookies(CookiePath);
    switchToNextSlide(38);
  };

  // * API to get Offer Settings
  const getOfferSettingsAPI = useQuery({
    queryKey: ["offerSettings"],
    queryFn: () =>
      getOfferSettings({
        pipeline_id: customerDetails.pipelineID,
        headers: { apiKey, apiSecret },
      })
        .then(handleGetOfferSettingsSuccess)
        .catch(handleGetOfferSettingsError),
    enabled: customerDetails.pipelineID ? true : false,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const handleGetOfferSettingsSuccess = (data) => {
    const details = data.data;

    let obj = {
      minLoanAmount: null,
      maxLoanAmount: null,
      loanStep: null,
      minTenure: null,
      maxTenure: null,
      tenureStep: null,
      interestRate: null,
      FOIR_Limit: null,
      PF: null,
      overDueCharges: null,
    };
    details.forEach((offer) => {
      switch (offer.setting_type) {
        case "loanAmount":
          obj.minLoanAmount = offer.JourneyOfferSetingsDetail.min_value;
          obj.maxLoanAmount = offer.JourneyOfferSetingsDetail.max_value;
          obj.loanStep = offer.JourneyOfferSetingsDetail.action;
          break;
        case "tenure":
          obj.minTenure = offer.JourneyOfferSetingsDetail.min_value;
          obj.maxTenure = offer.JourneyOfferSetingsDetail.max_value;
          obj.tenureStep = offer.JourneyOfferSetingsDetail.action;
          break;
        case "interest":
          obj.interestRate = offer.JourneyOfferSetingsDetail.single_value;
          break;
        case "FOIR":
          obj.FOIR_Limit = offer.JourneyOfferSetingsDetail.single_value;
          break;
        case "processingFee":
          obj.PF = offer.JourneyOfferSetingsDetail.single_value;
          break;
        case "overDueCharges":
          obj.overDueCharges = offer.JourneyOfferSetingsDetail.single_value;
          break;
        default:
          return;
      }
    });

    setLenderOfferDetails(obj);
    setLoanDetails((prev) => ({
      ...prev,
      maxLoanAmount: obj.maxLoanAmount,
      maxLoanAmountAvailable: obj.maxLoanAmount,
      loanDecreasingStep: obj.loanStep,
      FOIR_Limit: obj.FOIR_Limit,
      tenureInMonths: obj.maxTenure,
      interest: obj.interestRate,
    }));

    setOfferSettings(details);

    // * Fetch Repayment Schedule
    let rateOfInterest, loan, loanTenure;

    if (DOMAIN_NAME === "maxemocapital") {
      loan = parseInt(loanAmt);
      loanTenure = tenure;
    } else {
      loan = loanDetails.loanAmount;
      loanTenure = obj.maxTenure;
    }
    rateOfInterest = obj.interestRate / 100;

    if (DOMAIN_NAME !== "myfindoc") {
      handleGetRepaymentSchedule(rateOfInterest, loan, loanTenure);
    }

    // * Fetching customer CRIF details from DB (If Exist)
    getCRIFDataDBAPI.mutate();

    return data;
  };
  const handleGetOfferSettingsError = (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("authToken");
      queryClient.invalidateQueries({ queryKey: ["offerSettings"] });
    }
    return error;
  };

  // * API to add generated Loan Offer Details
  const addLoanOfferDetailsAPI = useMutation({
    mutationKey: ["addLoanOfferDetails"],
    mutationFn: (Payload) => addLoanOfferDetails(Payload),
    retry: false,
  });

  // * API to add Kyc response for customer document
  const addKycResponseAPI = useMutation({
    mutationFn: (Payload) => addCustomerDocumentKycResponse(Payload),
    retry: false,
  });
  const handleAddKycResponse = (agreementDocId) => {
    const headers = {
      apiKey,
      apiSecret,
    };
    const Payload = {
      contactId: customerDetails?.contactID,
      documentId: agreementDocId,
      kycData: {
        customerName: customerDetails?.fullName,
        loanAmount: loanDetails?.loanAmount,
        loanTenure: loanDetails?.tenureInMonths,
        interest: loanDetails?.interest,
        emi: loanDetails?.EMI,
        executedOn: parseCustomDate(new Date()),
      },
      headers,
    };
    addKycResponseAPI.mutate(Payload);
  };

  // * API to upload the document
  const uploadCustomerDocumetAPI = useMutation({
    mutationKey: ["uploadCustomerDocument"],
    mutationFn: (Payload) =>
      uploadCustomerDocument(Payload).then(handleDocumentUploadSuccess),
    retry: false,
  });
  const handleDocumentUploadSuccess = (data) => {
    // * Kyc response for the document(Loan Offer Agreement)
    handleAddKycResponse(data.data.id);
    // * Add generated loan offer details
    handleAddGeneratedLoanOfferDetails(data.data.id);
    return data;
  };

  // * API to fetch user's CRIF data
  const getCRIFDataAPI = useMutation({
    mutationKey: ["getCRIFData"],
    mutationFn: () =>
      getCRIFData({
        contact_id: customerDetails.contactID,
        headers: { apiKey, apiSecret },
      }).then(handleCRIFDataSuccess),
    enabled: customerDetails.contactID ? true : false,
  });
  const handleCRIFDataSuccess = (CRIF_Data) => {
    // * Calculate monthly obligation
    const customerMonthlyObligation = getCustomerMonthlyObligation(CRIF_Data);

    setLoanDetails({
      ...loanDetails,
      monthlyObligations: customerMonthlyObligation,
    });
    return CRIF_Data;
  };

  // * API to fetch user's CRIF data from DB
  const getCRIFDataDBAPI = useMutation({
    mutationKey: ["getCRIFData_DB"],
    mutationFn: () =>
      getCRIFData_DB({
        contact_id: customerDetails.contactID,
        headers: { apiKey, apiSecret },
      })
        .then(handleCRIFDataDBSuccess)
        .catch(handleGetCRIFDataDBError),
    enabled: customerDetails.contactID ? true : false,
    retry: false,
  });
  const handleCRIFDataDBSuccess = (data) => {
    const CRIF_Data = data.data;
    if (CRIF_Data.status !== 401) {
      // * Calculate customer's monthly obligation.
      const customerMonthlyObligation = getCustomerMonthlyObligation(
        CRIF_Data.crfi_data
      );

      setLoanDetails({
        ...loanDetails,
        monthlyObligations: customerMonthlyObligation,
      });
    }
  };
  const handleGetCRIFDataDBError = (error) => {
    // * If CRIF data does not exist in DB, then fetch the data fromo CRIF
    getCRIFDataAPI.mutate();
    return error;
  };

  // * API to fetch Sanction Letter template
  useQuery({
    queryKey: ["sanctionLetterTemplate"],
    queryFn: () =>
      getSanctionLettertTemplate({
        pipelineId: customerDetails.pipelineID,
        headers: { apiKey, apiSecret },
      }).then(handleGetSanctionLetterSuccess),
    enabled: customerDetails.pipelineID ? true : false,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const handleGetSanctionLetterSuccess = (data) => {
    if (data.data.length) {
      const sanctionLetter = data.data[0];
      setSanctionLetterTemplate({
        content: sanctionLetter.SanctionLetterDetails[0].content,
        template: sanctionLetter.SanctionLetterDetails[0].documentText,
        updatedTemplate: sanctionLetter.SanctionLetterDetails[0].documentText,
      });
    }
    return data;
  };

  // * API to fetch Agreement template
  useQuery({
    queryKey: ["agreementTemplate"],
    queryFn: () =>
      getAgreementTemplate({
        pipelineId: customerDetails.pipelineID,
        headers: { apiKey, apiSecret },
      }).then(handleGetAgreementTemplateSuccess),
    enabled: customerDetails.pipelineID ? true : false,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const handleGetAgreementTemplateSuccess = (data) => {
    if (data.data.length) {
      const agreement = data.data[0];
      setAgreementTemplate({
        content: agreement.LenderAgreementDetails[0].content,
        template: agreement.LenderAgreementDetails[0].documentText,
        updatedTemplate: agreement.LenderAgreementDetails[0].documentText,
      });
    }
    return data;
  };

  // * API to fetch Lead Details
  useQuery({
    queryKey: ["leadDetails"],
    queryFn: () =>
      getLeadDetails({
        pipelineId: customerDetails.pipelineID,
        leadId: customerDetails.leadId,
        headers: { apiKey, apiSecret },
      }).then(handleGetLeadDetailsSuccess),
    enabled:
      customerDetails.pipelineID && customerDetails.leadId ? true : false,
    refetchOnWindowFocus: false,
    retry: false,
  });

  let data = {
    pipelineId: customerDetails.pipelineID,
    leadId: customerDetails.leadId,
    contactId: customerDetails.contactID,
  };

  useQuery({
    queryKey: ["fetchBankDetails"],
    queryFn: () =>
      fetchBankDetails({ data, headers: { apiKey, apiSecret } }).then(
        handleBankDetails
      ),
    refetchOnWindowFocus: false,
    retry: true,
  });

  const handleGetLeadDetailsSuccess = (data) => {
    setLeadDetails(data?.data?.leadData || null);

    setCustomerDetails((prev) => ({
      ...prev,
      leadDetails: data.data.leadData,
    }));

    // * Get Customer Consent
    const payload = {
      pipeline_id: customerDetails.pipelineID,
      lead_id: customerDetails.leadId,
      contact_id: customerDetails.contactID,
      headers: { apiKey, apiSecret },
    };
    getConsentAPI.mutate(payload);

    return data;
  };

  const handleBankDetails = (data) => {
    setBankDetails(data?.data?.BankDetail);
    return data;
  };

  // * API to get customer consent
  const getConsentAPI = useMutation({
    mutationKey: ["consent"],
    mutationFn: (payload) =>
      getCustomerConsent(payload).then(handleGetConsentSuccess),
    enabled:
      customerDetails.pipelineID &&
      customerDetails.leadId &&
      customerDetails.contactID
        ? true
        : false,
    retry: false,
  });
  const handleGetConsentSuccess = (data) => {
    if (data.data.leadDetails.ContactConsentDetails.length) {
      setConsent(data.data.leadDetails.ContactConsentDetails[0]);
    }
    return data;
  };

  // * API to get Interest Rate for MyFinDoc customers
  const getInterestRateAPI = useMutation({
    mutationFn: (payload) => getInterestRate(payload),
    onSuccess: (data) => haneleGetInterestRateSuccess(data),
    onError: (error) => handlleGetInterestRateError(error),
  });
  const haneleGetInterestRateSuccess = (data) => {
    const roi = data.data?.rate_of_interest || null;
    if (roi) {
      handleGetRepaymentSchedule(
        (roi * 12) / 100,
        loanDetails.loanAmount,
        loanDetails.tenureInMonths
      );
      setInterestRate(roi);
    } else {
      // * Update lead stage to reject and send reject SMS to the customer
      rejectLead();

      // * Finish Journey
      console.log("JOURNYE END : 730");
      finishJourney();
    }
  };
  const handlleGetInterestRateError = (error) => {
    console.log("Error : ", error);
  };
  const handleGetInterestRate = () => {
    const headers = { apiKey, apiSecret };
    const data = {
      pipeline_id: customerDetails.pipelineID,
      lead_id: customerDetails.leadId,
    };
    const payload = { headers, data };
    getInterestRateAPI.mutate(payload);
  };

  // * MyFinDoc Only
  useLayoutEffect(() => {
    if (DOMAIN_NAME === "myfindoc") {
      handleGetInterestRate();
    }
    if (
      DOMAIN_NAME === "finture" &&
      customerDetails.employment_type === "Salaried"
    ) {
      handleGetSalarySlips();
    }
  }, []);

  useEffect(() => {
    if (DOMAIN_NAME !== "maxemocapital" && DOMAIN_NAME !== "myfindoc") {
      if (EMI_Flag) {
        const { loanAmount, interest, tenureInMonths } = loanDetails;
        const emi = calculteEMI(loanAmount, interest, tenureInMonths);
        setLoanDetails((prev) => ({ ...prev, EMI: emi }));
      }
    }
  }, [loanDetails.loanAmount]);

  // * Calculate EMI when user change Loan Amount and Tenure
  useEffect(() => {
    if (
      loanDetails.monthlyObligations !== null &&
      DOMAIN_NAME !== "maxemocapital" &&
      DOMAIN_NAME !== "myfindoc"
    ) {
      let emi,
        income = loanDetails.monthlyIncome,
        FOIR = loanDetails.FOIR_Limit;

      if (
        DOMAIN_NAME === "finture" &&
        salarySlips &&
        Array.isArray(salarySlips) &&
        salarySlips.length
      ) {
        income = 0;
        for (let i = 0; i < salarySlips.length; i++) {
          if (salarySlips[i]?.net_pay && salarySlips[i]?.net_pay > income) {
            income += salarySlips[i]?.net_pay
              ? extractNumber(salarySlips[i]?.net_pay)
              : 0;
          }
        }

        if (income > 35000) {
          FOIR = 65;
        }

        emi = calculateEMIWithLoanAmount(
          loanDetails.maxLoanAmountAvailable,
          loanDetails.interest,
          loanDetails.tenureInMonths,
          FOIR,
          loanDetails.loanDecreasingStep,
          loanDetails.monthlyObligations,
          income
        );
      } else {
        if (DOMAIN_NAME === "finture" && income > 35000) {
          FOIR = 65;
        }

        emi = calculateEMIWithLoanAmount(
          loanDetails.maxLoanAmountAvailable,
          loanDetails.interest,
          loanDetails.tenureInMonths,
          FOIR,
          loanDetails.loanDecreasingStep,
          loanDetails.monthlyObligations,
          income
        );
      }

      if (emi.maxLoanAmount > 0) {
        console.log("Positive Loan Amount : ", emi.maxLoanAmount);
        setLoanDetails((prev) => ({
          ...prev,
          EMI: emi.emi,
          loanAmount: emi.maxLoanAmount,
          maxLoanAmount: emi.maxLoanAmount,
        }));
        setEMI_Flag(true);
      } else {
        console.log("Negative Loan Amount : ", emi.maxLoanAmount);

        // * Update lead stage to reject and send reject SMS to the customer
        rejectLead();

        console.log("JOURNYE END : 840");
        // * Finish Journey
        finishJourney();
      }
    }
  }, [
    loanDetails.monthlyIncome,
    loanDetails.tenureInMonths,
    loanDetails.monthlyObligations,
    salarySlips,
  ]);

  const updateLoanDetails = (key, value) => {
    if (!value) setLoanDetails({ ...loanDetails, [key]: "" });
    else setLoanDetails({ ...loanDetails, [key]: parseInt(value) });
  };

  const preparePreSetData = () => {
    const contact = leadDetails?.Contact;

    let loanDetailsObj = { ...loanDetails };
    if (DOMAIN_NAME === "myfindoc") {
      loanDetailsObj.interest = interestRate;
      loanDetailsObj.EMI = emi;
    }
    let preSetData = createPreSetValues(
      contact,
      leadDetails,
      consent,
      offerSettings,
      loanDetailsObj,
      bankDetails,
      DOMAIN_NAME,
      loanAmt,
      tenure,
      netDisbursalAmt,
      interestAmt,
      emiAPI,
      emiDate,
      emi,
      otherSettings,
      aprParams,
      state.repaymentScheduleDetails
    );
    return preSetData;
  };

  const generateSanctionLetter = () => {
    let preSetData = preparePreSetData();
    const foundVariables = fetchDocumentVariables(
      sanctionLetterTemplate.template,
      preSetData
    );
    let sanctionTemplate = sanctionLetterTemplate.template;
    let variables;
    for (let i = 0; i < foundVariables.length; i++) {
      const variable = foundVariables[i];
      if (variable.value !== null) {
        const { template, updatedVariables } = handleDocumentVariableChange(
          sanctionTemplate,
          foundVariables,
          variable.value,
          variable
        );
        sanctionTemplate = template;
        variables = updatedVariables;
      }
    }

    setSanctionLetter((prev) => ({
      ...prev,
      document: sanctionTemplate,
      documentVariables: variables,
      content: sanctionLetterTemplate.content,
    }));
  };

  const generateAgreement = () => {
    let preSetData = preparePreSetData();

    const foundVariables = fetchDocumentVariables(
      agreementTemplate.template,
      preSetData
    );
    let agreementTemplateString = agreementTemplate.template;
    let variables;
    for (let i = 0; i < foundVariables.length; i++) {
      const variable = foundVariables[i];
      if (variable.value) {
        const { template, updatedVariables } = handleDocumentVariableChange(
          agreementTemplateString,
          foundVariables,
          variable.value,
          variable
        );
        agreementTemplateString = template;
        variables = updatedVariables;
      }
    }

    setAgreement((prev) => ({
      ...prev,
      document: agreementTemplateString,
      documentVariables: variables,
      content: agreementTemplate.content,
    }));
  };

  const handleLoanSubmit = async () => {
    let slidePayload = {
      headers: { apiKey, apiSecret },
      leadId: customerDetails?.leadId,
      totalSlides: TOTAL_SLIDES,
      journeyVersion: JourneyVersion,
    };
    if (customerDetails.userType === "Credit Institution") {
      if (leadDetails && consent) {
        if (
          pipelineSettings.sanctionLetterGeneration &&
          sanctionLetterTemplate.template
        ) {
          generateSanctionLetter();

          if (agreementTemplate.template) generateAgreement();

          const sanctionLetterId = `${customerDetails.applicationId}/SanctionLetter`;
          setCustomerDetails((prev) => ({
            ...prev,
            loanDetails,
            sanctionLetterId,
          }));

          slidePayload.slideName = "Sanction Letter";
          // slidePayload.slideIndex = 29;
          slidePayload.slideIndex = null;
          slidePayload.slideIcon = "SanctionLetter";
        } else {
          if (agreementTemplate.template) generateAgreement();

          setCustomerDetails((prev) => ({
            ...prev,
            loanDetails,
          }));

          slidePayload.slideName = "Loan Agreement";
          slidePayload.slideIndex = null;
          slidePayload.slideIcon = "LoanAgreement";
        }

        handleAddGeneratedLoanOfferDetails();

        // * Update Journey last visit slide
        updateLastVisitSlide(slidePayload);

        // * Update lead stage to Approved
        let loanAmount =
          DOMAIN_NAME === "maxemocapital" ? loanAmt : loanDetails.loanAmount;

        let smsTemplateId = null;
        let timelineData = {
          type: "SMS",
          message: "",
        };
        // * If domain is Finture than mark lead as Logged and send a Logged stage SMS
        if (DOMAIN_NAME === "finture") {
          handleUpdateLeadStageStatus("Logged", loanAmount);

          // * Get Logged stage SMS template
          const loggedSmsTemplate = smsTemplates.filter(
            (template) => template.stage_name === "Logged"
          );
          if (
            loggedSmsTemplate &&
            loggedSmsTemplate.length &&
            loggedSmsTemplate[0].sms_template_id
          ) {
            smsTemplateId = loggedSmsTemplate[0].sms_template_id;
            timelineData.message =
              "SMS trigged as lead status updated to Logged.";
          }
        } else {
          let approveData = {};

          if (DOMAIN_NAME === "maxemocapital") {
            approveData.tenure = `${tenure}`;
            approveData.roi_amount = `${loanDetails.interest}`;
          } else if (DOMAIN_NAME === "myfindoc") {
            approveData.tenure = `${loanDetails.tenureInMonths}`;
            approveData.roi_amount = `${interestRate}`;
          } else {
            approveData.tenure = `${loanDetails.tenureInMonths}`;
            approveData.roi_amount = `${loanDetails.interest}`;
          }
          approveData.approved_amount = `${loanAmount}`;

          handleUpdateLeadStageStatus("Approved", loanAmount, approveData);

          // * Get Approved stage SMS template
          const approvedSmsTemplate = smsTemplates.filter(
            (template) => template.stage_name === "Approved"
          );

          if (
            approvedSmsTemplate &&
            approvedSmsTemplate.length &&
            approvedSmsTemplate[0].sms_template_id
          ) {
            smsTemplateId = approvedSmsTemplate[0].sms_template_id;
            timelineData.message =
              "SMS trigged as lead status updated to Approved.";
          }
        }

        // * Send stage wise individual SMS
        if (smsTemplateId) {
          sendStageWiseSMS(smsTemplateId);
        }

        // * Create a timeline
        if (timelineData.message) {
          handleCreateTimeline(timelineData);
        }

        if (pipelineSettings.sanctionLetterGeneration) {
          switchToNextSlide();
        } else {
          switchToNextSlide(33);
        }
        return;
      }

      console.log("JOURNYE END : 1043");
      finishJourney();
    } else {
      // * Add generated loan offer details
      handleAddGeneratedLoanOfferDetails();

      // * Send Lead Details to the domain owner via email.
      handleSendLeadDetailViaEmail(loanDetails.loanAmount);

      console.log("JOURNYE END : 1052");
      finishJourney();
    }
  };

  const handleAddGeneratedLoanOfferDetails = async () => {
    let monthlyEMI = null;
    if (DOMAIN_NAME === "myfindoc") {
      monthlyEMI = emi;
    } else {
      monthlyEMI = emiAPI;
    }

    let Payload = {
      contact_id: customerDetails?.contactID,
      lead_id: customerDetails?.leadId,
      pipeline_id: customerDetails?.pipelineID,
      loan_amount:
        DOMAIN_NAME === "maxemocapital" ? loanAmt : loanDetails?.loanAmount,
      loan_tenure:
        DOMAIN_NAME === "maxemocapital" ? tenure : loanDetails?.tenureInMonths,
      interest:
        DOMAIN_NAME === "myfindoc" ? interestRate : loanDetails?.interest,
      interest_frequency: DOMAIN_NAME === "myfindoc" ? "Monthly" : "Annually",
      // monthly_emi: loanDetails?.EMI,
      monthly_emi: monthlyEMI,
      emi_type: pipelineSettings?.emi_type,
      roi_type: pipelineSettings?.emi_flat_or_reducing,
      emi_cut_off_date: pipelineSettings?.emi_cut_off_date,
      emi_date: pipelineSettings?.firstCollectionDate,
      loan_accept_date: new Date(),
      headers: { apiKey, apiSecret },
    };

    const location = await getLocationPermission();
    if (location) {
      Payload = { ...Payload, ...location };
    }

    Payload.monthly_income = customerDetails.monthlyIncome
      ? customerDetails.monthlyIncome
      : customerDetails?.contact?.your_net_monthly_income;

    addLoanOfferDetailsAPI.mutate(Payload);
  };

  if (
    getOfferSettingsAPI.isPending ||
    getCRIFDataAPI.isPending ||
    getCRIFDataDBAPI.isPending
    // loanDetails.monthlyObligations !== null
  ) {
    return (
      <div className={style.loaderContainer}>
        <div className={style.loaderBox}>
          <Loader />
          <p>Please wait while we are generating the best offer for you.</p>
        </div>
      </div>
    );
  }

  return (
    <div className={style.loanOfferSlide}>
      <div className={style.typographyContainer}>
        <h1>Loan Offer</h1>
        <p>Here is your loan offer with details</p>
      </div>

      <div className={style.offerDetailsContainer}>
        {DOMAIN_NAME === "maxemocapital" ? (
          <div className={style.typographyContainer}>
            <h1>Loan Amount&nbsp;&nbsp;</h1>
            <p>&#8377;{loanAmt}</p>
            <span className={styleText.valueLimitsMaximo}>
              *The interest for the loan will be deducted upfront from the loan
              amount. Please refer to the sanction letter in the next step for
              more details.
            </span>
          </div>
        ) : (
          <RangeInputField
            label="Loan Amount"
            name="loanAmount"
            id="loanAmount"
            inputPlaceholder="Loan Amount"
            required={true}
            min={lenderOfferDetails.minLoanAmount}
            max={loanDetails.maxLoanAmount}
            step={loanDetails.loanDecreasingStep}
            value={loanDetails.loanAmount}
            handler={(loanAmount) =>
              updateLoanDetails("loanAmount", loanAmount)
            }
            inputDisabled={true}
            icon={
              <CurrencyRupeeIcon
                style={{ fontSize: "22px", color: "#2e3092" }}
              />
            }
            customerNote={`Minimum loan amount is ${lenderOfferDetails.minLoanAmount} and maximum loan amount is ${lenderOfferDetails.maxLoanAmount}`}
          />
        )}

        {DOMAIN_NAME === "maxemocapital" ? (
          <div className={style.typographyContainer}>
            <h1>Loan Tenure (In Month)&nbsp;&nbsp;</h1>
            <p>{tenure}</p>
          </div>
        ) : (
          <RangeInputField
            label="Tenure ( Months )"
            name="tenureInMonths"
            id="loanTenure"
            inputPlaceholder="Tenure"
            required={true}
            min={lenderOfferDetails.minTenure}
            max={lenderOfferDetails.maxTenure}
            step={lenderOfferDetails.tenureStep}
            // defaultValue={12}
            value={loanDetails.tenureInMonths}
            handler={(tenure) => updateLoanDetails("tenureInMonths", tenure)}
            inputDisabled={true}
            customerNote={`Minimum tenure is ${lenderOfferDetails.minTenure} Months and maximum tenure is ${lenderOfferDetails.maxTenure} Months.`}
          />
        )}
        <div className={style.loanDetails}>
          <div
            className={style.loanContent}
            style={
              DOMAIN_NAME === "maxemocapital"
                ? { justifyContent: "center" }
                : {}
            }
          >
            {DOMAIN_NAME !== "maxemocapital" && (
              <>
                <div className={style.content}>
                  {DOMAIN_NAME === "myfindoc" ? (
                    <p>Interest Per Month (%)</p>
                  ) : (
                    <p>Interest Per Annum (%)</p>
                  )}
                  {/* <p style={{ fontWeight: 500 }}>
                { loanDetails?.interest}
              </p> */}
                  <p style={{ fontWeight: 500 }}>
                    {DOMAIN_NAME === "myfindoc"
                      ? interestRate
                      : loanDetails?.interest}
                  </p>
                </div>
                <div className={style.seperator}></div>
              </>
            )}
            <div className={style.content}>
              {DOMAIN_NAME === "maxemocapital" ? (
                <p>Upfront Monthly installment (EMI)</p>
              ) : DOMAIN_NAME === "myfindoc" ? (
                <p>Estimated Equated Monthly installment (EMI)</p>
              ) : (
                <p>Equated Monthly installment (EMI)</p>
              )}

              {DOMAIN_NAME === "maxemocapital" ? (
                <p style={{ fontWeight: 500 }}>
                  <CurrencyRupeeIcon className={style.rupeeIconEMI} />
                  {EMI && !isNaN(EMI)
                    ? Intl.NumberFormat("en-US").format(EMI)
                    : "-"}
                </p>
              ) : DOMAIN_NAME === "myfindoc" ? (
                <p style={{ fontWeight: 500 }}>
                  <CurrencyRupeeIcon className={style.rupeeIcon} />
                  {emi && !isNaN(emi)
                    ? Intl.NumberFormat("en-US").format(emi)
                    : loanDetails?.EMI
                    ? Intl.NumberFormat("en-US").format(loanDetails.EMI)
                    : "-"}
                </p>
              ) : (
                <p style={{ fontWeight: 500 }}>
                  <CurrencyRupeeIcon className={style.rupeeIcon} />
                  {loanDetails?.EMI
                    ? Intl.NumberFormat("en-US").format(loanDetails.EMI)
                    : ""}
                </p>
              )}
            </div>
          </div>
        </div>

        {/* {customerDetails.userType !== "LSP" ? (
          <div className={style.offerAgreement}>
            <p className={style.offerAgreementPara}>
              By proceeding, I accept the terms of{" "}
              <button
                className={style.loanAgreementBtn}
                onClick={() => setToggleModal({ ...toggleModal, open: true })}
              >
                Loan agreement & sanction letter
              </button>{" "}
              & declare that I am not a politically exposed person
            </p>
          </div>
        ) : null} */}
      </div>

      <div className={style.acceptOfferBtn}>
        <ContinueBtn
          loading={uploadCustomerDocumetAPI.isPending}
          handler={handleLoanSubmit}
        />
      </div>
    </div>
  );
};

export default LoanOffer;
