import * as actions from "./types";

export const getAutoPullBureauDetails = (param) => {
  return {
    type: actions.GET_BUREAU_AUTO_PULL,
    payload: param,
  };
};

export const getAutoPullBureauDetailsSuccess = () => ({
  type: actions.GET_BUREAU_AUTO_PULL_SUCCESS,
});
