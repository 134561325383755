import "./DropDownField.css";
import { useContext } from "react";
import ErrorContext from "../../../context/ErrorProvider";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

export default function DropDownField({
  label,
  name,
  required,
  options,
  reference,
  defaultValue,
  valueKey,
  nameKey,
  handler,
  errorType,
}) {
  const { error } = useContext(ErrorContext);

  return (
    <div className="DropDownField">
      <label htmlFor={name && name}>
        {label && label}
        {required && required ? <span style={{ color: "red" }}>*</span> : null}
      </label>
      <div className="DropDownBox">
        <div
          className={`SelectorBox ${
            error.error && error.type === errorType
              ? "activeError"
              : "inActiveError"
          }`}
        >
          <select
            id={name && name}
            name={name && name}
            ref={reference && reference}
            required={required && required}
            defaultValue={defaultValue && defaultValue}
            onChange={handler ? (e) => handler(e.target.value) : null}
          >
            <option value="" hidden>
              Please select
            </option>
            {valueKey &&
              nameKey &&
              Array.isArray(options) &&
              options.map((option, index) => (
                <option key={index} value={option[valueKey]}>
                  {option[nameKey]}
                </option>
              ))}
          </select>
        </div>
        {error.error && error.type === errorType ? (
          <div className="errorBox">
            <ErrorOutlineRoundedIcon style={{ fontSize: "14px" }} />
            <p>{error.message}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
}
