// * Icons
import SpeedRoundedIcon from "@mui/icons-material/SpeedRounded";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";

export default function MaxemoCapital() {
  return (
    <div className="featuresListBox">
      <div className="introBox">
        <p>Benefits of Your Pre-Approved Rent-O-Cash</p>
      </div>
      <div className="featuresList">
        <div className="feature">
          <div className="featureIcon">
            <SpeedRoundedIcon sx={{ color: "#2e3092" }} />
          </div>
          <p>Advance Rent Facility: Receive up to 6 months of rent in advance.</p>
        </div>
        <div className="feature">
          <div className="featureIcon">
            <CurrencyRupeeRoundedIcon sx={{ color: "#2e3092" }} />
          </div>
          <p>Easy Repayment: Repay monthly from tenant's rent.</p>
        </div>
        <div className="feature">
          <div className="featureIcon">
            <VerifiedRoundedIcon sx={{ color: "#2e3092" }} />
          </div>
          <p>Boost Cash Flow: Improve financial liquidity instantly.</p>
        </div>
      </div>
    </div>
  );
}
