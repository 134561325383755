import React from "react";
import { v4 as uuidv4 } from "uuid";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { View, Text, Image, pdf, StyleSheet } from "@react-pdf/renderer";

// * Utilities
import { formatDateTime2 } from "../../helpers/Date/Date.helpers";
import compressBase64Image, { blobToBase64 } from "../ImageCompress";

// * Helpers
import { formatNumber } from "../../helpers/Others/Others.helpers";
import {
  calculateAge2,
  calculateEMI,
  convertNumberToWords,
} from "../../helpers";

export const PreSetVariables = {
  "Loan Amount": "Loan Amount",
  "Interest (%)": "Interest Rate",
  "Loan Tenure": "Loan Tenure",
  "Tenure (Months)": "Loan Tenure",
  "Processing Fee": "Processing Fee",
  // "Processing Fee Amount": "Processing Fee Amount",
  // "Processing Fee Amount With GST": "Processing Fee Amount With GST",
  Pan: "Pan",
  Name: "Name",
  Address: "Address",
  "Borrower Name": "Name",
  "Borrower Address": "Address",
};

export const createPreSetValues = (
  contact,
  leadDetails,
  consent,
  offerSettings,
  loanDetails,
  bankDetails,
  domainName,
  loanAmt,
  tenure,
  netDisbursalAmt,
  interestAmt,
  emiAPI,
  emiDate,
  emi,
  otherSettings,
  aprParams,
  repaymentScheduleDetails
) => {
  const {
    phone,
    pan,
    customer_id,
    email,
    CustomerDocuments,
    full_name,
    address,
    address2,
    officeaddress1,
    officeaddress2,
    city,
    pincode,
    date_of_birth,
  } = contact;
  const customerPermanentAddress = contact?.customerAddressVariations?.length
    ? contact?.customerAddressVariations[0].address_value
    : null;
  const permanentAddressDetails = customerPermanentAddress
    ? customerPermanentAddress.split("; ")
    : null;

  let preSetValues = {
    PAN: pan || "",
    "Borrower Name": full_name || "",
    "Borrower Residential Address":
      address || address2 ? `${address} ${address2}` : "",
    "Borrower Office Address":
      officeaddress1 || officeaddress2
        ? `${officeaddress1} ${officeaddress2}`
        : "NA",
    "Loan No": leadDetails.application_id,
    "Borrwer Phone Number": phone || "",
    "Executed As On": new Date(),
    "Loan Sanction Date": new Date(),
    "Borrower Id": customer_id || "",
    "Borrwer Email Id": email || "",
    "Type Of Loan": "Unsecured Loan",
    City: city,
    Year: new Date().getFullYear(),
    "Communication Address Pincode": pincode,
    "Permanent Address Pincode": permanentAddressDetails[3],
  };

  preSetValues["Date1 To31"] = new Date().getDate();

  if (date_of_birth) {
    preSetValues["Age"] = calculateAge2(date_of_birth);
  }

  // * Assign Aadhaar and PAN Number
  if (CustomerDocuments && CustomerDocuments.length) {
    const aadhaarDocument = CustomerDocuments.filter(
      (doc) => doc.docType === "aadhaar"
    );
    if (aadhaarDocument.length) {
      const aadhaar = aadhaarDocument[0];
      preSetValues["Aadhaar No"] = aadhaar.docNumber;
    }
    const panDocument = CustomerDocuments.filter(
      (doc) => doc.docType === "pan"
    );
    if (panDocument.length) {
      const pan = panDocument[0];
      const fatherName =
        pan?.customerOcrVerifiedStatuses[0]?.customerOcrVerifiedStatusDetail
          ?.father_name;
      preSetValues["Relation"] = fatherName || "NA";
    }
  }

  // * Consent
  if (consent) {
    const date = new Date(consent.otp_submitted_time);
    const dateOfApplication = date.toISOString().split("T")[0];
    preSetValues["Date Of Application"] = dateOfApplication;
  }

  // * Assigned Offer Settings
  if (offerSettings.length) {
    let settings = {};
    offerSettings.forEach((setting) => {
      const { offer_name, offer_type, JourneyOfferSetingsDetail } = setting;
      if (PreSetVariables[offer_name]) {
        const { single_value, max_value, min_value } =
          JourneyOfferSetingsDetail;

        if (offer_type === "multiple") {
          settings[PreSetVariables[offer_name]] = Math.ceil(
            (max_value + min_value) / 2
          );
        } else {
          settings[PreSetVariables[offer_name]] = single_value;
        }
      }
    });

    // * For Maximo Assign Loan Amount and Tenure
    if (domainName === "maxemocapital") {
      preSetValues["Loan Amount"] = parseInt(loanAmt);
      preSetValues["Sanction Loan Amount"] = parseInt(loanAmt);
      preSetValues["Sanction Amount In Words"] = loanAmt
        ? convertNumberToWords(parseInt(loanAmt))
        : "-";
      preSetValues["Loan Tenure"] = parseInt(tenure);
      preSetValues["Loan Tenure In Days"] = tenure
        ? parseInt(tenure) * 30.42
        : "-";
      preSetValues["Interest Rate"] = loanDetails.interest;
    } else {
      preSetValues["Loan Amount"] = loanDetails.loanAmount;
      preSetValues["Sanction Loan Amount"] = loanDetails.loanAmount;
      preSetValues["Sanction Amount In Words"] = loanDetails.loanAmount
        ? convertNumberToWords(loanDetails.loanAmount)
        : "-";
      preSetValues["Loan Tenure"] = loanDetails.tenureInMonths;
      preSetValues["Loan Tenure In Days"] = loanDetails.tenureInMonths
        ? parseInt(loanDetails.tenureInMonths) * 30.42
        : "-";

      if (domainName === "myfindoc") {
        preSetValues["Interest Rate"] = loanDetails.interest * 12;
      } else {
        preSetValues["Interest Rate"] = loanDetails.interest;
      }
    }

    // * Assign Total Interest Amount
    if (domainName === "maxemocapital") {
      preSetValues["Total Interest Amount"] = interestAmt;
    } else if (domainName === "finture") {
      preSetValues["Total Interest Amount"] = aprParams?.interestExpenses;
    } else {
      let totalInterestAmount =
        (preSetValues["Loan Amount"] *
          preSetValues["Interest Rate"] *
          (preSetValues["Loan Tenure"] / 12)) /
        100;
      preSetValues["Total Interest Amount"] = totalInterestAmount;
    }

    const currentdate = new Date();
    const currentDateWithFormat = currentdate.toISOString().split("T")[0];
    preSetValues["Datof Signing Of Loan Offer Agreement"] =
      currentDateWithFormat;
    preSetValues["First Emi Date"] = emiDate;
    preSetValues["Date Of Commencement"] = currentDateWithFormat;
    const month = currentdate.toLocaleString("default", { month: "long" });
    preSetValues["Month Jan To Dec"] = month;

    if (
      preSetValues["Loan Amount"] &&
      preSetValues["Interest Rate"] &&
      preSetValues["Loan Tenure"]
    ) {
      if (domainName === "maxemocapital") {
        let loan = preSetValues["Loan Amount"];
        let newTenure = preSetValues["Loan Tenure"];

        preSetValues.EMI = Math.ceil(loan / newTenure);
      } else if (domainName === "myfindoc") {
        preSetValues.EMI = emi;
      } else {
        const loanDetails = calculateEMI(
          preSetValues["Loan Amount"],
          preSetValues["Interest Rate"],
          preSetValues["Loan Tenure"]
        );

        preSetValues.EMI = Math.floor(loanDetails.EMI);
      }

      preSetValues.Installments =
        domainName === "maxemocapital" ? tenure : loanDetails.tenureInMonths;
    }

    // * Calculate Monthly Interest Rate
    if (preSetValues["Interest Rate"]) {
      const annualInterestRate = preSetValues["Interest Rate"];
      if (!isNaN(annualInterestRate)) {
        let monthlyInterestRate =
          (Math.pow(
            1 + annualInterestRate / 100,
            1 / preSetValues["Loan Tenure"]
          ) -
            1) *
          100;
        const factor = Math.pow(10, 3);

        if (domainName === "myfindoc") {
          preSetValues["Monthly Interest Rate"] = loanDetails.interest;
        } else {
          preSetValues["Monthly Interest Rate"] =
            Math.floor(monthlyInterestRate * factor) / factor;
        }
      }
    }

    const GSTPercentage = 18; // * 18%

    /*
                Other Charges in rupees - Insurance Charges -> Insurance Charge is being Calculated over the Loan Amount.
                Condition : 
                Age Upto 40 Insurance charges are 1% of Loan Amount+18% GST
 
                Age Above 40 Insurance charges are 2% of Loan Amount+18% GST
 
                For Example: A loan has been sanctioned for 50,000 rs to a 30 Years old Borrower so 1. Condition to be applicable 1% charge over loan amount and 18% of GST over the Charge
 
                Total Insurance Charge = (50,000 * 1% = 500) + (18% of 500 = 90) = Rs. 590
    */
    if (preSetValues["Age"] && !isNaN(preSetValues["Age"])) {
      let insuranceCharges, insuranceChargesGst;
      if (preSetValues["Age"] <= 40) {
        insuranceCharges = (preSetValues["Loan Amount"] / 100) * 1;
      } else {
        insuranceCharges = (preSetValues["Loan Amount"] / 100) * 2;
      }
      insuranceChargesGst = (insuranceCharges / 100) * GSTPercentage;

      insuranceCharges = insuranceCharges + insuranceChargesGst;

      preSetValues["Other Charges In Rupees- Insurance Charges"] =
        insuranceCharges;
    }

    // * Calculate Processing Fee Amount
    if (settings["Processing Fee"] && preSetValues["Loan Amount"]) {
      const processingFee = settings["Processing Fee"];

      const sanctionLoanAMount = preSetValues["Loan Amount"];

      const processingFeeAmountWithoutGST =
        (sanctionLoanAMount / 100) * processingFee;
      const GstAmount = (processingFeeAmountWithoutGST / 100) * GSTPercentage;
      const processingFeeAmountWithGST =
        processingFeeAmountWithoutGST + GstAmount;
      preSetValues["Processing Fee"] = processingFeeAmountWithGST;
      preSetValues["Processing Fee In Percentage"] = processingFee;
      preSetValues["Processing Fee In Words"] = processingFeeAmountWithGST
        ? convertNumberToWords(processingFeeAmountWithGST)
        : "-";

      preSetValues["Gst"] = GSTPercentage;
      preSetValues["Gst Amount"] = GSTPercentage;
    }

    // * Calculate File Charge for Disbursal Loan Amount
    const fileCharge = 100;
    const fileChargeGST = (fileCharge / 100) * GSTPercentage;
    const fileChargeAmount = fileCharge + fileChargeGST;
    preSetValues["File Charge"] = fileChargeAmount;

    // * Calculating Disbursal Loan Amount
    let disbursalLoanAmount = 0;
    if (domainName === "maxemocapital") {
      disbursalLoanAmount = netDisbursalAmt;
    } else if (domainName === "myfindoc") {
      disbursalLoanAmount =
        preSetValues["Loan Amount"] -
        preSetValues["Processing Fee"] -
        preSetValues["File Charge"];
    } else {
      disbursalLoanAmount =
        preSetValues["Loan Amount"] - preSetValues["Processing Fee"];
    }
    preSetValues["Disbursal Loan Amount"] = disbursalLoanAmount;

    // * Calculate Total Fee
    if (domainName === "finture") {
      const processingFee = settings["Processing Fee"];
      const insuranceCharges =
        preSetValues["Other Charges In Rupees- Insurance Charges"];
      let totalLoanAmount = preSetValues["Loan Amount"] + insuranceCharges;

      let processingFeeWithoutGst = (totalLoanAmount / 100) * processingFee;
      let processingFeeGSTAmount =
        (processingFeeWithoutGst / 100) * GSTPercentage;
      let processingFeeAmount =
        processingFeeWithoutGst + processingFeeGSTAmount;

      let totalFee = processingFeeAmount + insuranceCharges;

      preSetValues["Processing Fee"] = processingFeeAmount;
      preSetValues["Total Fee"] = totalFee;

      let deductions = insuranceCharges + preSetValues["Processing Fee"];
      if (
        preSetValues["Gap Interest"] &&
        !isNaN(preSetValues["Gap Interest"])
      ) {
        deductions += preSetValues["Gap Interest"];
      }
      // * Disbursal Loan Amount for Finture
      preSetValues["Disbursal Loan Amount"] = totalLoanAmount - deductions;
    } else {
      preSetValues["Total Fee"] =
        preSetValues["Processing Fee"] + preSetValues["File Charge"];
    }

    // * Calculate Total Loan Amount
    if (
      !isNaN(preSetValues["Loan Amount"]) &&
      !isNaN(preSetValues["Total Interest Amount"])
    ) {
      let totalLoanAmount;
      if (domainName === "finture") {
        totalLoanAmount =
          preSetValues["Loan Amount"] +
          preSetValues["Other Charges In Rupees- Insurance Charges"];
      } else {
        totalLoanAmount =
          preSetValues["Loan Amount"] + preSetValues["Total Interest Amount"];
      }
      preSetValues["Total Loan Amount"] = totalLoanAmount;
    }

    /*
     * Calculate APR - Annual Percentage Rate
     * Formula Used: APR = ((Interest + Fees) / Principal) * (365 / Loan_Tenure_in_days) * 100APR = ((Interest + Fees) / Principal) * (365 / Loan_Tenure_in_days) * 100
     */
    if (
      !isNaN(aprParams?.interestExpenses) &&
      !isNaN(preSetValues["Total Fee"]) &&
      !isNaN(preSetValues["Loan Amount"]) &&
      !isNaN(preSetValues["Loan Tenure"])
    ) {
      let tenureInDays = preSetValues["Loan Tenure"] * 30.42;
      let totalLoanAmount =
        domainName === "myfindoc"
          ? preSetValues["Loan Amount"]
          : preSetValues["Total Loan Amount"];
      let totalInterest = preSetValues["Total Interest Amount"];
      let totalFee = preSetValues["Total Fee"];
      console.log("Total Interest : ", totalInterest);
      console.log("Total Fee : ", totalFee);
      console.log("Total Loan Amount : ", totalLoanAmount);
      console.log("Tenure In Days : ", tenureInDays);
      const APR =
        ((totalInterest + totalFee) / totalLoanAmount) *
        ((365 / tenureInDays) * 100);

      if (!isNaN(APR)) {
        preSetValues["Apr- Annual Percentage Rate"] = APR.toFixed(2);
      }
    }
  }

  // * Assign Bank Details
  if (bankDetails) {
    preSetValues["Bank Name"] = bankDetails?.bank_name;
    preSetValues["Bank Account Number"] = bankDetails?.acct_number;
    preSetValues["Ifsc Code"] = bankDetails?.ifsccode;
    preSetValues["Bank Branch"] = bankDetails?.branch;
    preSetValues["Name Of Account Holder"] = bankDetails?.account_holder_name;
  }

  // * Generating Repyament Schedule
  if (repaymentScheduleDetails && repaymentScheduleDetails.scheduleAvailable) {
    let scheduleTableRows = ``;
    const schedule = repaymentScheduleDetails?.repaymentSchedule || {};

    let row, openingPrincipalBal, principal, interest, totalPayment;
    for (let i = 0; i < schedule.length; i++) {
      openingPrincipalBal = schedule[i]?.opening_principal_balance
        ? schedule[i]?.opening_principal_balance?.toFixed(2)
        : "-";
      principal = schedule[i]?.principal
        ? schedule[i]?.principal?.toFixed(2)
        : "-";
      interest = schedule[i]?.interest_charge
        ? schedule[i]?.interest_charge?.toFixed(2)
        : "-";
      totalPayment = schedule[i]?.total_payment
        ? schedule[i]?.total_payment?.toFixed(2)
        : "-";

      row = `<tr>
      <td>${i + 1}</td>
      <td>${openingPrincipalBal}</td>
      <td>${principal}</td>
      <td>${interest}</td>
      <td>${totalPayment}</td>
    </tr>`;

      scheduleTableRows += row;
    }

    const scheduleTable = `
<table border="1">
    <tr>
      <td>Installment No.</td>
      <td>Outstanding Principal</td>
      <td>Principal</td>
      <td>Interest</td>
      <td>Installment Amount</td>
    </tr>
    ${scheduleTableRows}
</table>
`;

    preSetValues["Repayment Schedule"] = scheduleTable;
  }

  if (Object.keys(otherSettings).length) {
    preSetValues = { ...preSetValues, ...otherSettings };
  }
  return preSetValues;
};

export function removeVariableParentheses(inputString) {
  let trimmedString = inputString.replace(/[{}]/g, "");
  let result;

  if (trimmedString === trimmedString.toUpperCase()) {
    result = trimmedString;
  } else {
    let words = trimmedString.split(/(?=[A-Z])/).map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    result = words.join(" ");
  }

  return result;
}

function getVariableDataType(variable) {
  const variableDataTypes = {
    "{loanAmount}": "number",
    "{interestRate}": "number",
    "{loanTenor}": "number",
    "{executedAsOn}": "date",
    "{installments}": "number",
    "{EMI}": "number",
    "{borrowerResidentialAddress}": "text",
    "{borrowerOfficeAddress}": "text",
    "{borrowerAddress}": "text",
    "{PAN}": "text",
    "{loanSanctionDate}": "date",
    "{loanNo}": "text",
    "{dateOfApplication}": "date",
    "{processingFee}": "text",
    "{repaymentSchedule}": "object",
  };
  if (variableDataTypes[variable]) {
    return variableDataTypes[variable];
  } else {
    return null;
  }
}

export function fetchDocumentVariables(inputString, preSetValues) {
  const regex = /\{([^}]+)\}/g;
  const variables = inputString.match(regex);

  if (variables) {
    return variables.map((variable) => {
      const label = removeVariableParentheses(variable);
      return (variable = {
        id: uuidv4(),
        label: label,
        variable: variable,
        dataType: getVariableDataType(variable),
        isChanged: false,
        location: null,
        endingLocation: null,
        value:
          preSetValues[label] || preSetValues[label] == 0
            ? preSetValues[label].toString()
            : null,
        preSet: preSetValues[label] ? "preset" : "custom",
        preSetValue:
          preSetValues[label] || preSetValues[label] == 0
            ? preSetValues[label].toString()
            : "",
        canChange: true,
      });
    });
  } else {
    return [];
  }
}

export function formatVariableOutput(variableValue, variableType) {
  let updatedVariableValue = null;
  switch (variableType) {
    case "{loanAmount}":
    case "{totalInterestAmount}":
    case "{disbursalLoanAmount}":
    case "{processingFee}":
    case "{EMI}":
      let amount;
      amount = formatNumber(variableValue);
      updatedVariableValue = amount ? `Rs.${amount}` : 0;
      break;
    case "{interestRate}":
    case "{monthlyInterestRate}":
    case "{processingFeeInPercentage}":
    case "{apr-AnnualPercentageRate}":
    case "{gst}":
      updatedVariableValue = `${variableValue}%`;
      break;
    case "{executedAsOn}":
      updatedVariableValue = variableValue
        ? formatDateTime2(variableValue)
        : "";
      break;
    case "{loanSanctionDate}":
      updatedVariableValue = variableValue
        ? formatDateTime2(variableValue)
        : "";
      break;
    case "{dateOfApplication}":
      updatedVariableValue = variableValue
        ? formatDateTime2(variableValue)
        : "";
      break;
    case "{PAN}":
      updatedVariableValue = variableValue.toString().toUpperCase();
      break;
    case "{date1To31}":
      if (variableValue && !isNaN(variableValue)) {
        updatedVariableValue = getOrdinalDate(parseInt(variableValue));
      } else {
        updatedVariableValue = variableValue;
      }
      break;
    default:
      updatedVariableValue = variableValue;
  }
  return updatedVariableValue;
}

const getOrdinalDate = (day) => {
  {
    if (typeof day !== "number" || day < 1 || day > 31) {
      throw new Error("Invalid day: please provide a number between 1 and 31.");
    }

    const suffixes = ["th", "st", "nd", "rd"];
    const remainder = day % 10;

    // Handle special cases for 11th, 12th, and 13th
    if (day >= 11 && day <= 13) {
      return `${day}th`;
    }

    return `${day}${suffixes[remainder > 3 ? 0 : remainder]}`;
  }
};

export function replaceAtLocation(
  location,
  inputString,
  searchValue,
  replaceValue
) {
  // Check if location is valid
  if (location < 0 || location >= inputString.length) {
    return {
      location: -1,
      updatedString: inputString,
    };
  }

  // Find the index of searchValue starting from the specified location
  const index = inputString.indexOf(searchValue, location);

  if (index !== -1) {
    // Replace searchValue with replaceValue
    const updatedString =
      inputString.substring(0, index) +
      replaceValue +
      inputString.substring(index + searchValue.length);

    return {
      location: index,
      updatedString: updatedString,
    };
  } else {
    // searchValue not found starting from the specified location
    return {
      location: -1,
      updatedString: inputString,
    };
  }
}

export function replaceLabel(inputString, searchValue, replaceValue) {
  const index = inputString.indexOf(searchValue);

  if (index !== -1) {
    // The searchValue was found in the inputString
    const updatedString = inputString.replace(searchValue, replaceValue);

    return {
      updatedString: updatedString,
      location: index,
    };
  } else {
    // The searchValue was not found in the inputString
    return {
      updatedString: inputString,
      location: -1,
    };
  }
}

export function handleDocumentVariableChange(
  template,
  variables,
  value,
  currentVariable
) {
  let htmlContent = template;
  let updatedContent, formattedValue;
  currentVariable = new Object(currentVariable);

  if (currentVariable.isChanged) {
    formattedValue = formatVariableOutput(value, currentVariable.variable);
    currentVariable.value = formatVariableOutput(
      currentVariable.value,
      currentVariable.variable
    );

    updatedContent = replaceAtLocation(
      currentVariable.location,
      htmlContent,
      currentVariable.value,
      formattedValue
    );
  } else {
    formattedValue = formatVariableOutput(value, currentVariable.variable);
    updatedContent = replaceLabel(
      htmlContent,
      `<span style="background-color: yellow;"> ${currentVariable.variable} </span>`,
      formattedValue
    );
  }
  const { updatedString, location } = updatedContent;

  currentVariable.location = location;
  currentVariable.endingLocation = location + (formattedValue.length - 1);
  currentVariable.isChanged = true;

  template = updatedString;

  const tempVariables = new Object(variables);
  const updatedVariables = tempVariables.map((variable) => {
    if (variable.id === currentVariable.id) {
      variable.value = value?.toString();
      variable.location = currentVariable.location;
      variable.endingLocation = currentVariable.endingLocation;
      variable.isChanged = currentVariable.isChanged;
    }
    return variable;
  });

  return { template, updatedVariables };
}

const styles = StyleSheet.create({
  strong: {
    fontSize: 11,
    fontWeight: 700,
    marginVertical: 3,
  },
  h1: {
    fontSize: 20,
    marginVertical: 3,
  },
  h2: {
    fontSize: 19,
    marginVertical: 3,
  },
  h3: {
    fontSize: 18,
    marginVertical: 3,
  },
  h4: {
    fontSize: 17,
    marginVertical: 3,
  },
  h5: {
    fontSize: 16,
    marginVertical: 3,
  },
  h6: {
    fontSize: 14,
    marginVertical: 3,
  },
  p: {
    fontWeight: 300,
    fontSize: 11,
    paddingVertical: 2,
    paddingHorizontal: 2,
    lineHeight: 1.3,
    textAlign: "justify",
  },
  a: {
    color: "#0000CD",
  },
  section: {
    margin: 10,
  },
  ol: {
    textAlign: "justify",
  },
  li: {
    marginBottom: 3,
    flexDirection: "row",
    alignItems: "flex-start",
    textAlign: "justify",
  },
  liIndex: {
    fontSize: 12,
  },
  liText: {
    fontSize: 16,
    textAlign: "justify",
  },
  table: {
    border: "1px solid black",
    borderCollapse: "collapse",
    marginVertical: 10,
  },
  tableRow: {
    flexDirection: "row",
    borderBottom: "1px solid black",
  },
  tableCell: {
    borderRight: "1px solid black",
    padding: 3,
    flex: 1,
    height: "auto",
  },
  indexTableCell: {
    width: 40,
    borderRight: "1px solid black",
    padding: 3,
    flex: 1,
  },
  sup: {
    fontSize: 10,
    lineHeight: 0,
    verticalAlign: "super",
  },
  sub: {
    fontSize: 10,
    lineHeight: 0,
    verticalAlign: "sub",
  },
  image: {
    width: 90,
    height: 20,
  },
});
export const renderHtmlElement = (element, index = 1) => {
  if (typeof element === "string") {
    return <Text style={styles.p}>{element}</Text>;
  }
  if (React.isValidElement(element)) {
    const { type, props, key } = element;

    const renderChildren = () => {
      if (Array.isArray(props.children)) {
        return props.children.map((child, idx) =>
          renderHtmlElement(child, idx + 1)
        );
      } else if (type === "p" && typeof props.children === "object") {
        return (
          <Text style={styles.p}>{renderHtmlElement(props.children)}</Text>
        );
      } else if (typeof props.children === "object") {
        return renderHtmlElement(props.children);
      } else if (
        type === "h5" &&
        typeof props.children === "string" &&
        props.children.trim() !== ""
      ) {
        return <Text style={styles.h5}>{props.children}</Text>;
      } else if (
        type === "h6" &&
        typeof props.children === "string" &&
        props.children.trim() !== ""
      ) {
        return <Text style={styles.h6}>{props.children}</Text>;
      } else if (
        type === "p" &&
        typeof props.children === "string" &&
        props.children.trim() !== ""
      ) {
        return <Text style={[styles.p, props.style]}>{props.children}</Text>;
      } else if (
        type === "strong" &&
        typeof props.children === "string" &&
        props.children.trim() !== ""
      ) {
        return (
          <Text style={[styles.strong, props.style]}>{props.children}</Text>
        );
      } else {
        return <Text style={styles.p}>{props.children}</Text>;
      }
    };

    switch (type) {
      case "strong":
        return <Text style={styles.strong}>{renderChildren()}</Text>;
      case "h1":
        return <Text style={styles.h1}>{renderChildren()}</Text>;
      case "h2":
        return <Text style={styles.h2}>{renderChildren()}</Text>;
      case "h3":
        return <Text style={styles.h3}>{renderChildren()}</Text>;
      case "h4":
        return <Text style={styles.h4}>{renderChildren()}</Text>;
      case "h5":
        return <Text style={styles.h5}>{renderChildren()}</Text>;
      case "h6":
        return (
          <Text style={styles.h6} wrap={false}>
            {renderChildren()}
          </Text>
        );
      case "p":
        return <Text style={styles.p}>{renderChildren()}</Text>;
      case "a":
        return props.children ? (
          <Text style={styles.a}>{renderChildren()}</Text>
        ) : null;
      case "div":
        return (
          <View style={[styles.section, props.style]}>{renderChildren()}</View>
        );
      case "table":
        return <View style={styles.table}>{renderChildren()}</View>;
      case "tr":
        return <View style={styles.tableRow}>{renderChildren()}</View>;
      case "td":
        return (
          <View style={key !== "0" ? styles.tableCell : styles.indexTableCell}>
            {renderChildren()}
          </View>
        );
      case "li":
        return (
          <View style={styles.li}>
            <Text style={styles.liText}>
              <Text style={styles.liIndex}>{index}. </Text> {renderChildren()}
            </Text>
          </View>
        );
      case "sup":
        return <Text style={styles.sup}>{renderChildren()}</Text>;
      case "sub":
        return <Text style={styles.sub}>{renderChildren()}</Text>;
      case "img":
        return (
          <Image style={styles.image} src={props.src}>
            {renderChildren()}
          </Image>
        );
      case "ol":
        return (
          <View style={styles.ol}>
            {props?.children &&
              props?.children?.length > 0 &&
              props?.children?.map((child, idx) => (
                <View key={idx} style={styles.listItem}>
                  {renderHtmlElement(child, idx + 1)}
                </View>
              ))}
          </View>
        );
      default:
        return <Text style={styles.p}>{renderChildren()}</Text>;
    }
  }

  return null;
};
export const generatePdfBase64 = async ({ document }) => {
  const blob = await pdf(document).toBlob();
  const base64 = await blobToBase64(blob);
  return base64;
};

export function getDateByOffset(dayOffset) {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + dayOffset);
  const formattedDate = currentDate.toISOString().split("T")[0];
  return formattedDate;
}
export const embedLinksAndEmail = (content) => {
  const urlPattern =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  const emailPattern = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;

  if (urlPattern.test(content) || emailPattern.test(content)) {
    const outputWithLinks = content.replace(urlPattern, '<a href="$1">$1</a>');
    const finalOutput = outputWithLinks.replace(
      emailPattern,
      '<a href="mailto:$1">$1</a>'
    );

    return finalOutput;
  }
  return null;
};
export const insertElementBeforeMatch = (
  elements,
  searchStrings,
  newElement
) => {
  const updatedElements = [];

  elements.forEach((element) => {
    // Convert props.children to a string if it's an array
    let childrenString = Array.isArray(element.props.children)
      ? element.props.children
          .map((child) => (typeof child === "string" ? child : ""))
          .join("")
      : element.props.children;

    // Track if newElement needs to be inserted multiple times
    let elementsToInsert = [];
    searchStrings.forEach((searchString) => {
      if (
        typeof childrenString === "string" &&
        childrenString.includes(searchString)
      ) {
        let index = 0;
        while ((index = childrenString.indexOf(searchString, index)) > -1) {
          elementsToInsert.push(newElement);
          index += searchString.length;
        }
      }
    });

    // Add the new element before the current element as many times as needed
    if (elementsToInsert.length > 0) {
      elementsToInsert.forEach(() => {
        updatedElements.push(newElement);
      });
    }

    // Add the current element to the updated list
    updatedElements.push(element);
  });

  return updatedElements;
};
export const removeExtraSpaces = (htmlString) => {
  // Remove spaces and line breaks that are outside of the tags
  return htmlString.replace(/>\s+</g, "><").trim();
};
export const calculateAnnualPercentageRate = (
  interestExpenses,
  fee,
  principalLoanAmount,
  tenureInDays
) => {
  return ((interestExpenses + fee) / principalLoanAmount / tenureInDays) * 365;
};
// export const stampCustomerSignature = async (pdfBlob) => {
//   // Convert blob to array buffer (pdfBytes)
//   const pdfBytes = await pdfBlob.arrayBuffer();

//   // Load the existing PDF
//   const pdfDoc = await PDFDocument.load(pdfBytes);

//   // Get the first page of the PDF
//   const pages = pdfDoc.getPages();
//   const firstPage = pages[0]; // Stamp on the first page, or loop over `pages` for all pages

//   // Set the stamp text
//   const { width, height } = firstPage.getSize();
//   firstPage.drawText("Stamped Text", {
//     x: width / 2 - 50, // Adjust the X position
//     y: height / 2, // Adjust the Y position
//     size: 20,
//     color: rgb(0, 0, 0),
//     opacity: 0.5, // Adjust opacity for watermark effect
//   });

//   // Save the PDF with the stamp
//   const pdfBytesWithStamp = await pdfDoc.save();

//   // Convert the updated PDF bytes back to a blob
//   const stampedBlob = new Blob([pdfBytesWithStamp], {
//     type: "application/pdf",
//   });

//   // Trigger download of the modified PDF
//   saveAs(stampedBlob, "stamped_pdf.pdf");
// };
