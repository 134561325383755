// * Style
import style from "./SelfieModal.module.css";

import { useRef, useCallback, useEffect, useState } from "react";
import Webcam from "react-webcam";
import { useFaceDetection } from "react-use-face-detection";
import FaceDetection from "@mediapipe/face_detection";
import { Camera } from "@mediapipe/camera_utils";
// * Icons
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import CancelIcon from "@mui/icons-material/Cancel";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// * Utilities
import { isMobileDevice } from "../../../../utils/Others";

const SelfieModal = ({ setSelfie, setSelfieModal }) => {
  const cameraRef = useRef();
  const isMobile = isMobileDevice();

  const initialVideConstraints = {
    facingMode: "user",
  };
  const [videoConstraints, setVideoConstraints] = useState(
    initialVideConstraints
  );

  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleCameraMode = () => {
    setVideoConstraints((prev) => ({
      ...prev,
      facingMode:
        prev.facingMode === "user" ? { exact: "environment" } : "user",
    }));
  };

  useEffect(() => {
    return () => {
      cameraRef.current?.stop(); // * Release the Camera once component is unmounted
    };
  }, [cameraRef.current]);

  const { webcamRef, boundingBox, isLoading, detected, facesDetected } =
    useFaceDetection({
      faceDetectionOptions: {
        model: "short",
      },
      faceDetection: new FaceDetection.FaceDetection({
        locateFile: (file) =>
          `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
      }),
      camera: ({ mediaSrc, onFrame }) => {
        cameraRef.current = new Camera(mediaSrc, {
          onFrame,
        });

        return cameraRef.current;
      },
    });

  // console.log("box.yCenter : ", boundingBox[0]?.yCenter * 100);
  // console.log("box.xCenter : ", boundingBox[0]?.xCenter * 100);
  // console.log("box.width : ", boundingBox[0]?.width * 100);
  // console.log("box.height : ", boundingBox[0]?.height * 100);

  // const isYAxisInRange =
  //   boundingBox[0]?.yCenter * 100 > 15 && boundingBox[0]?.yCenter * 100 < 60;
  // const isXAxisInRange =
  //   boundingBox[0]?.xCenter * 100 > 15 && boundingBox[0]?.xCenter * 100 < 60;
  // const isHeightAndWidthInRange =
  //   boundingBox[0]?.width * 100 > 20 && boundingBox[0]?.height * 100 < 35;
  // const areAllCordinatestInRange =
  //   isYAxisInRange && isXAxisInRange && isHeightAndWidthInRange;

  const isYAxisInRange =
    boundingBox[0]?.yCenter * 100 > 5 && boundingBox[0]?.yCenter * 100 < 50;
  const isXAxisInRange =
    boundingBox[0]?.xCenter * 100 > 0 && boundingBox[0]?.xCenter * 100 < 70;
  const isHeightAndWidthInRange =
    boundingBox[0]?.width * 100 > 25 && boundingBox[0]?.height * 100 > 35;
  const areAllCordinatestInRange =
    isYAxisInRange && isXAxisInRange && isHeightAndWidthInRange;

  // 27 Aug
  // const isYAxisInRange =
  //   boundingBox[0]?.yCenter * 100 > 21 && boundingBox[0]?.yCenter * 100 < 50;
  // const isXAxisInRange =
  //   boundingBox[0]?.xCenter * 100 > 30 && boundingBox[0]?.xCenter * 100 < 50;
  // const isHeightAndWidthInRange =
  //   boundingBox[0]?.width * 100 > 25 && boundingBox[0]?.height * 100 > 35;
  // const areAllCordinatestInRange =
  //   isYAxisInRange && isXAxisInRange && isHeightAndWidthInRange;

  const clickSelfie = useCallback(() => {
    const clickedSelfie = webcamRef.current.getScreenshot();
    cameraRef.current.stop();

    setSelfie(clickedSelfie);
    setSelfieModal(false);
  }, [webcamRef, setSelfie, setSelfieModal]);

  const closeSelfieModal = async () => {
    cameraRef.current.stop();
    setSelfieModal(false);
  };

  const handleCameraAccessFailure = (error) => {
    console.log("Camera Access Error : ", error);
  };
  return (
    <div className={style.webcamModal}>
      <div className={style.webcamModalWrapper}>
        <div className={style.webcamScreenBox}>
          {/* {boundingBox.map((box, index) => (
            <div
              key={`${index + 1}`}
              style={{
                border: "4px solid red",
                position: "absolute",
                top: `${box.yCenter * 100}%`,
                left: `${box.xCenter * 100}%`,
                width: `${box.width * 100}%`,
                height: `${box.height * 100}%`,
                zIndex: 1,
              }}
            />
          ))} */}
          <Webcam
            audio={false}
            videoConstraints={videoConstraints}
            imageSmoothing={true}
            screenshotQuality={1}
            ref={webcamRef}
            className={style.webCam}
            onUserMediaError={(error) => handleCameraAccessFailure(error)}
            // style={{
            //   outline: `4px solid ${
            //     detected && facesDetected === 1 && areAllCordinatestInRange
            //       ? "#4BB543"
            //       : "#ed4337"
            //   }`,
            //   outlineOffset: "6px",
            // }}
            style={{
              outline: `4px solid ${
                detected && facesDetected === 1
                  ? "#4BB543"
                  : "#ed4337"
              }`,
              outlineOffset: "6px",
            }}
            screenshotFormat="image/jpeg"
          />
        </div>

        <div className={style.selfieActionBtns}>
          <button
            className={`${style.selfieActionBtn} ${style.takeSelfieBtn}`}
            // style={{
            //   backgroundColor:
            //     detected && facesDetected === 1 && areAllCordinatestInRange
            //       ? "#2e3092"
            //       : "#cfe4ff",
            // }}
            style={{
              backgroundColor:
                detected && facesDetected === 1
                  ? "#2e3092"
                  : "#cfe4ff",
            }}
            onClick={clickSelfie}
            // disabled={
            //   detected && facesDetected === 1 && areAllCordinatestInRange
            //     ? false
            //     : true
            // }
            disabled={
              detected && facesDetected === 1
                ? false
                : true
            }
            title="Click Selfie"
          >
            <CameraAltIcon className={style.selfieBtnIcon} />
          </button>
          {isMobile ? (
            <button
              onClick={toggleCameraMode}
              className={`${style.selfieActionBtn} ${style.closeSelfieModalBtn}`}
              title="Switch Camera"
            >
              <CameraswitchIcon />
            </button>
          ) : null}
          <button
            className={`${style.selfieActionBtn} ${style.closeSelfieModalBtn}`}
            onClick={closeSelfieModal}
            title="Cancel"
          >
            <CancelIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelfieModal;
