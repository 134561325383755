import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import queryString from "query-string";
// * Style
import style from "../../IncomeVerificationSlide/SalarySlipVerification/SalarySlip.module.css";
// * Components
import DocumentPreview from "../../../DocumentPreview/DocumentPreview";
// import ImageInputField from "../../../DataInputFields/ImageInputField/ImageInputField";
import DocumentUploadWithPassword from "../../../DataInputFields/ImageInputField/DocumentUploadWithPassword";
import ContinueButton from "../../../Buttons/SlideContinueButton/ContinueButton";
// * Icons
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import InfoIcon from "@mui/icons-material/Info";
import { useLocation } from "react-router-dom";

// * API Handler
import { useMutation } from "@tanstack/react-query";
// * APIs
import {
  uploadCustomerDocument,
  verifyCustomerDocument,
  analyzeBankStatementAuthBridge,
} from "../../../../api/services/documents";
// * Helpers
import {
  convertBase64,
  getCookiePathFromURL,
  getLocationPermission,
} from "../../../../helpers";
// * Cookies
import { useCookies } from "react-cookie";
// * Notificaiton
import { toast } from "react-toastify";
// * Constants
import {
  LAST_ACTIVE_JOURNEY_SLIDE,
  TOTAL_SLIDES,
  DOMAIN_NAME,
} from "../../../../constants";
// * Contexts
import CustomerContext from "../../../../context/CustomerContext";

export default function BankStatementVerification({
  switchToNextSlide,
  // setSalariesInBankStatement,
  updateLastVisitSlide,
}) {
  const CookiePath = getCookiePathFromURL(window.location.href);
  const { apiKey, apiSecret } = useParams();
  const { customerDetails, setCustomerDetails } = useContext(CustomerContext);

  const [_, setCookie] = useCookies(["user"]);

  const [password, setPassword] = useState("");
  const [documentStatus, setDocumentStatus] = useState({
    error: false,
    hasDocument: false,
  });
  const [document, setDocument] = useState([]);
  useEffect(() => {
    if (documentStatus.hasDocument)
      setDocumentStatus({ error: false, hasDocument: false });
  }, [document]);

  // * AuthBridge bank statement analyzer API.
  const analyzeStatement = useMutation({
    mutationFn: (payload) => analyzeBankStatementAuthBridge(payload),
    onSuccess: (data) => analyzeStatementSuccess(data),
    onError: (error) => analyzeStatementError(error),
  });
  const analyzeStatementSuccess = (data) => {
    console.log("Analyzed response success: ", data);

    const slidePayload = {
      headers: { apiKey, apiSecret },
      leadId: customerDetails?.leadId,
      slideName: "Bank Statement Verification",
      slideIndex: 22,
      slideIcon: "BankStatement",
      totalSlides: TOTAL_SLIDES,
    };
    // * Update Journey last visit slide
    updateLastVisitSlide(slidePayload);

    switchToNextSlide();
  };
  const analyzeStatementError = (error) => {
    console.log("Analyzed response error: ", error);
    toast.error("Something went wrong, please try again.");
  };
  const handleAnalyzeBankStatement = (documentId) => {
    const data = {
      statementsIds: [
        {
          id: documentId,
          pwd: password,
        },
      ],
    };
    const headers = {
      apiKey,
      apiSecret,
    };
    const payload = {
      data,
      headers,
    };
    analyzeStatement.mutate(payload);
  };

  // * API to verify the document
  const verifyCustomerDocumentAPI = useMutation({
    mutationKey: ["uploadCustomerDocument"],
    mutationFn: (Payload) => verifyCustomerDocument(Payload),
    onSuccess: (data) => handleVerifyCustomerDocumentSuccess(data),
    onError: (error) => handleVerifyCustomerDocumentError(error),
    retry: false,
  });
  const handleVerifyCustomerDocumentSuccess = (data) => {
    switchToNextSlide();
    // let bankStatementData = data.data.data;
    // if (bankStatementData.income_per_month) {
    //   let salariesInBankStatement = [];
    //   let customerSalary = [];
    //   customerSalary = bankStatementData.income_per_month;

    //   for (let i = 0; i < customerSalary.length; i++) {
    //     salariesInBankStatement.push(customerSalary[i].total);
    //   }
    //   setSalariesInBankStatement(salariesInBankStatement);
    // }

    // * FOR SALARIED
    // if (
    //   customerDetails.employment_type === "Salaried" &&
    //   bankStatementData.bank_account
    // ) {
    //   const { accountHolder, accountNo, bank, ifsCode, isValidBankStatement } =
    //     bankStatementData.bank_account;

    //   /*
    //       Verify bank details fetched from salary slips
    //       with bank details fetched from Bank Statement.
    //     */
    //   const salarySlipBankDetails = customerDetails.salarySlipBankDetails;
    //   if (accountNo === salarySlipBankDetails["bank_ac_no"]) {
    //     const bankDetails = {
    //       accountHolder,
    //       accountNo,
    //       bank,
    //       ifsCode,
    //       isValidBankStatement,
    //     };
    //     setCustomerDetails((prev) => ({ ...prev, bankDetails }));
    //     setDocumentStatus({ error: false, hasDocument: true });
    //     setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 27, { path: CookiePath });
    //     switchToNextSlide();
    //   } else {
    //     toast.error(
    //       "Bank statement doesn't match with bank details received from salary slip."
    //     );
    //   }
    // }else{
    //     switchToNextSlide();
    //     setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 28, { path: CookiePath });
    // }

    // } else {
    //   switchToNextSlide();
    //   setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 28, { path: CookiePath });
    // }
  };

  const handleVerifyCustomerDocumentError = (error) => {
    console.log("Bank Statement single doc verification error : ", error);
    toast.error("Something went wrong, please try again.");
  };

  const saveBankStatement = async () => {
    if (!document.length) {
      return;
    }

    if (
      document &&
      document[0]?.type === "application/pdf" &&
      !uploadCustomerDocumetAPI.isPending
    ) {
      setDocumentStatus({ ...documentStatus, error: false });

      const base64 = await convertBase64(document[0]);
      const headers = {
        apiKey,
        apiSecret,
      };
      let Payload = {
        contact_id: customerDetails.contactID,
        pipeline_id: customerDetails.pipelineID,
        lead_id: customerDetails.leadId,
        docUpload: base64,
        fileName: document[0].name,
        docType: "bankStatement",
        docFormat: "pdf",
        docNumber: "",
        source_name: "Journey",
        file_password: password,
        headers,
      };
      const location = await getLocationPermission();
      if (location) {
        Payload = { ...Payload, ...location };
      }

      uploadCustomerDocumetAPI.mutate(Payload);
    }
  };

  // * API to upload Bank Statement only as document
  const uploadCustomerDocumetAPI = useMutation({
    mutationKey: ["uploadCustomerDocument"],
    mutationFn: (Payload) => uploadCustomerDocument(Payload),
    onSuccess: (data) => handleDocumentUploadSuccess(data),
    onError: (error) => handleDocumentUploadError(error),
    retry: false,
  });

  let url = useLocation().search;
  let queryValues = queryString.parse(url);
  const JourneyVersion = queryValues?.jrnyTyp;

  const handleDocumentUploadSuccess = (data) => {
    // toast.success("Bank statement uploaded successfully.");
    // setDocumentStatus({ error: false, hasDocument: true });
    setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 24, { path: CookiePath });

    const slidePayload = {
      headers: { apiKey, apiSecret },
      leadId: customerDetails?.leadId,
      slideName: "Bank Statement Verification",
      slideIndex: 22,
      slideIcon: "BankStatement",
      totalSlides: TOTAL_SLIDES,
      journeyVersion: JourneyVersion,
    };
    // * Update Journey last visit slide
    updateLastVisitSlide(slidePayload);

    // * Save Bank Statement Document ID for future use.
    setCustomerDetails((prev) => ({
      ...prev,
      bankStatementId: data?.data?.id,
    }));
    // * Verify document

    if (
      customerDetails.userType === "Credit Institution" ||
      DOMAIN_NAME === "consultancymars1"
    ) {
      handleAnalyzeBankStatement(data?.data?.id);
    } else {
      switchToNextSlide();
    }
    // if (
    //   customerDetails.userType === "Credit Institution" ||
    //   DOMAIN_NAME === "consultancymars1"
    // ) {
    //   const headers = {
    //     apiKey,
    //     apiSecret,
    //   };
    //   verifyCustomerDocumentAPI.mutate({
    //     ContactID: data.data.contact_id,
    //     DocumentID: data.data.id,
    //     headers,
    //   });
    // } else {
    //   switchToNextSlide();
    //   setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 27, { path: CookiePath });
    // }
  };
  const handleDocumentUploadError = (error) => {
    // console.log("Document Upload ERror : ", error);
    if (error.response.status === 401) {
      localStorage.removeItem("authToken");
      saveBankStatement();
    } else {
      toast.error("Something went wrong! please try again.");
    }
  };

  const validateDocs = () => {
    const { hasDocument } = documentStatus;

    if (hasDocument) {
      switchToNextSlide();
      setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 27, { path: CookiePath });
    } else if (!hasDocument && document.length === 0) {
      setDocumentStatus({ ...documentStatus, error: true });
    } else {
      saveBankStatement();
    }
  };

  return (
    <div className="carouselSlideWrapper salarySlipAndBankStatementSlideWrapper">
      <div className="typographyContainer">
        <h1>Income Verification</h1>
        {customerDetails.employment_type !== "Self-Employed" ? (
          <p>Upload your main bank statement</p>
        ) : (
          <p>Upload your salary account bank statement.</p>
        )}
      </div>

      <div className="dataFieldsContainer imageInputContainer">
        <div className={style.documentInputBox}>
          {document
            ? document.map((doc) => (
                <DocumentPreview
                  key={doc.name}
                  document={doc}
                  removeDocument={() => setDocument([])}
                  removeTitle="Delete Salary Slip"
                />
              ))
            : null}

          <DocumentUploadWithPassword
            label="Upload Bank Statement"
            icon={
              <DescriptionOutlinedIcon
                style={{ fontSize: "45px", color: "rgb(189, 205, 255)" }}
              />
            }
            // accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf"
            // allowedFileTypes="excel, pdf"
            accept="application/pdf"
            allowedFileTypes="pdf"
            file={document}
            setFile={setDocument}
            // handler={saveBankStatement}
            isDocAvailable={documentStatus.error}
            isLoading={uploadCustomerDocumetAPI.isPending}
            hasDocument={documentStatus.hasDocument}
            max={1}
            password={password}
            setPassword={setPassword}
          />
        </div>
        <div className="">
          <ul>
            <li>
              <InfoIcon style={{ color: "#404040", fontSize: "20px" }} />
              Please do not upload password protected file.
            </li>
          </ul>
        </div>
      </div>

      <div className="imageInputContinueBtn">
        <ContinueButton
          label={document.length ? "Continue" : "Upload"}
          loading={
            uploadCustomerDocumetAPI.isPending ||
            verifyCustomerDocumentAPI.isPending ||
            analyzeStatement.isPending
          }
          handler={validateDocs}
          disabled={document.length ? false : true}
        />
      </div>
    </div>
  );
}
