import PreApprovedOffer from "./PreApprovedOffer/PreApprovedOffer";
import style from "./PreApprovedOffers.module.css";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import CustomerContext from "../../../context/CustomerContext";
import { TOTAL_SLIDES } from "../../../constants";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export default function PreApprovedOffers({
  loanOffers,
  updateLastVisitSlide,
}) {
  // const loanOfferss = [1, 1, 1, 1];
  const { apiKey, apiSecret } = useParams();
  const { customerDetails } = useContext(CustomerContext);

  
  let url = useLocation().search;
  let queryValues = queryString.parse(url);
  const JourneyVersion = queryValues?.jrnyTyp;

  const updateJourneyLastVisit = () => {
    const slidePayload = {
      headers: { apiKey, apiSecret },
      leadId: customerDetails?.leadId,
      slideName: "Pre-Approved Loan Offers",
      // slideIndex: 29,
      slideIcon: "PreApprovedLoanOffers",
      totalSlides: TOTAL_SLIDES,
      journeyVersion:JourneyVersion
    };
    updateLastVisitSlide(slidePayload);
  };

  return (
    <div className={style.slide}>
      <div className={style.slideWrapper}>
        <h1 className={style.slideHeading}>Loan Offers</h1>
        <div className={style.loanOffers}>
          {loanOffers.map((offer, index) => (
            <PreApprovedOffer
              key={`offer_${index}`}
              content={{
                bankName: offer.bank_name,
                applyLink: offer.apply_link,
                interest: offer.interest_rate,
                bankImageName: offer.bank_image_name,
                approvedAmount: offer.approved_amount,
                leadID: offer.lead_id,
                status: offer.log_status,
              }}
              // content={{
              //   bankName: "MoneyView",
              //   bankImageName: "earlysalary",
              //   applyLink: "",
              //   interest: "10",
              //   approvedAmount: 6000,
              //   tenor: 20,
              // }}
              updateJourneyLastVisit={updateJourneyLastVisit}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
