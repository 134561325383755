import { v4 as uuidV4 } from "uuid";
import { StyleSheet, View, Image } from "@react-pdf/renderer";

export default function Header({ headerContent }) {
  const styles = StyleSheet.create({
    headerWrapper: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    image1: {
      width: 120,
      height: 25,
    },
  });

  return (
    <View style={styles.headerWrapper}>
      {headerContent &&
      headerContent.images &&
      Array.isArray(headerContent.images) &&
      headerContent?.images[0] ? (
        <Image
          key={uuidV4()}
          style={styles.image1}
          src={`data:image/${headerContent.images[0].type};base64,${headerContent.images[0].base64}`}
        />
      ) : null}
      {headerContent &&
      headerContent.images &&
      Array.isArray(headerContent.images) &&
      headerContent?.images[1] ? (
        <Image
          key={uuidV4()}
          style={styles.image1}
          src={`data:image/${headerContent.images[1].type};base64,${headerContent.images[1].base64}`}
        />
      ) : null}
    </View>
  );
}
