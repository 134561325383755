import style from "./LoanAmount.module.css";
import { useContext } from "react";
import TextInputField from "../../DataInputFields/TextInputField/TextInputField";
import ContinueButton from "../../Buttons/SlideContinueButton/ContinueButton";
import * as Errors from "../../../utils/errors";
import ErrorContextt from "../../../context/ErrorProvider";
import { DOMAIN_NAME } from "../../../constants";

export default function LoanAmount({
  loanAmt,
  addLoanAmount,
  validateLoanAmount,
  loading,
}) {
  const { setError } = useContext(ErrorContextt);

  const handleLoanAmount = (loanAmount) => {
    if (!loanAmount) {
      setError({
        error: true,
        type: Errors.LOAN_AMOUNT_ERROR_TYPE,
        message: Errors.LOAN_AMOUNT_NULL_ERROR,
      });
    } else {
      validateLoanAmount(loanAmount);
    }
  };

  return (
    <div className={style.slide}>
      <div className={style.slideWrapper}>
        <div className={style.slideHeader}>
          <h1 className={style.slideHeading}>Loan Amount</h1>
          <p>Enter your desired loan amount.</p>
        </div>

        <div className={style.inputFields}>
          {DOMAIN_NAME === "maxemocapital" ? (
            <TextInputField
              type="number"
              label="Loan Amount"
              placeholder="Enter Loan Amount"
              name="loanAmount"
              id="loanAmount"
              value={loanAmt}
              disabled={true}
              // reference={loanAmountRef}
              handler={handleLoanAmount}
              required={true}
              errorType={Errors.LOAN_AMOUNT_ERROR_TYPE}
            />
          ) : (
            <TextInputField
              type="number"
              label="Loan Amount"
              placeholder="Enter Loan Amount"
              name="loanAmount"
              id="loanAmount"
              // reference={loanAmountRef}
              handler={handleLoanAmount}
              required={true}
              errorType={Errors.LOAN_AMOUNT_ERROR_TYPE}
            />
          )}
        </div>

        <div className={style.btnBox}>
          <ContinueButton loading={loading} handler={addLoanAmount} />
        </div>
      </div>
    </div>
  );
}
