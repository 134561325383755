import style from "./SanctionLetter.module.css";

import { useState, useRef } from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import queryString from "query-string";
// * Contexts
import CustomerContext from "../../../context/CustomerContext";

// * Components
import ContinueButton from "../../Buttons/SlideContinueButton/ContinueButton";
import DocumentContent from "../../DocumentContent/DocumentContent";

// * Constants
import { TOTAL_SLIDES, DOMAIN_NAME } from "../../../constants";

// * Helpers
import { getCookiePathFromURL } from "../../../helpers";
import { useLocation } from "react-router-dom";

// * Utilities
import { removeAllCookies } from "../../../helpers";

export default function SanctionLetterSlide({
  sanctionLetter,
  updateLastVisitSlide,
  switchToNextSlide,
  loanAmt,
}) {
  const CookiePath = getCookiePathFromURL(window.location.href);

  const { customerDetails } = useContext(CustomerContext);
  const { apiKey, apiSecret } = useParams();

  const contentDivRef = useRef();

  const [continueButtonState, setContinueButtonState] = useState(true);

  let url = useLocation().search;
  let queryValues = queryString.parse(url);
  const JourneyVersion = queryValues?.jrnyTyp;

  const finishJourney = () => {
    // * NEW
    const slidePayload = {
      headers: { apiKey, apiSecret },
      leadId: customerDetails?.leadId,
      slideName: "Success : Journey Completed",
      slideIndex: 47,
      slideIcon: "Success",
      totalSlides: TOTAL_SLIDES,
      journeyVersion: JourneyVersion,
    };
    // * Update Journey last visit slide
    updateLastVisitSlide(slidePayload);
    removeAllCookies(CookiePath);
    switchToNextSlide(37);
  };

  const handleSubmitSanction2 = () => {
    if (sanctionLetter.document) {
      const slidePayload = {
        headers: { apiKey, apiSecret },
        leadId: customerDetails?.leadId,
        slideName: "Sanction Letter",
        // slideIndex: 29,
        slideIcon: "Sanction Letter",
        totalSlides: TOTAL_SLIDES,
        journeyVersion: JourneyVersion,
      };
      // * Update Journey last visit slide
      updateLastVisitSlide(slidePayload);

      switchToNextSlide();
    } else {
      finishJourney();
    }
  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = contentDivRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 1 && continueButtonState) {
      setContinueButtonState(false);
    }
  };

  return (
    <div className={style.sanctionLetterSlide}>
      <div className={style.slideBody}>
        <div className={style.slideHeader}>
          <h1>Sanction Letter</h1>
          <p>Here is sanction letter please sign it to proceed.</p>
        </div>

        <div className={style.documentContainer}>
          {sanctionLetter.document ? (
            DOMAIN_NAME === "maxemocapital" ? (
              <DocumentContent
                details={{
                  name: customerDetails.fullName || "",
                  documentId: customerDetails.sanctionLetterId || "",
                  loanAmount: loanAmt,
                }}
                content={sanctionLetter.document}
                reference={contentDivRef}
                handleScroll={handleScroll}
              />
            ) : (
              <DocumentContent
                details={{
                  name: customerDetails.fullName || "",
                  documentId: customerDetails.sanctionLetterId || "",
                  loanAmount: customerDetails.loanDetails.loanAmount,
                }}
                content={sanctionLetter.document}
                reference={contentDivRef}
                handleScroll={handleScroll}
              />
            )
          ) : null}
        </div>
      </div>

      <div className={style.actionBox}>
        <ContinueButton
          label="Proceed To Sign"
          handler={handleSubmitSanction2}
          disabled={continueButtonState}
        />
      </div>
    </div>
  );
}
