import * as actions from "../actions/types";

const initialState = {
  journeyFieldData: [],
};

const journeyFieldReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_JOURNEY_FIELDS:
      return {
        ...state,
        journeyFieldData: action.data,
      };
    case actions.GET_JOURNEY_FIELDS_SUCCESS:
      return {
        ...state,
        journeyFieldData: action.data,
      };
    default: {
      return state;
    }
  }
};
export default journeyFieldReducer;
