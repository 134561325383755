import "./SelectorButton.css";
import { useState } from "react";

export default function SelectorButton({ label, icon, handler, elementValue }) {
  const [isButtonClicked, setButtonClicked] = useState(false);

  const handleClick = () => {
    if (!isButtonClicked) {
      setButtonClicked(true);
      handler(label);

      setTimeout(() => {
        setButtonClicked(false);
      }, 1000);
    }
  };

  return (
    <button
      className={`selectorBtn ${
        label === elementValue ? "activeButton" : null
      }`}
      onClick={handleClick}
      value={label}
      disabled={isButtonClicked}
    >
      {icon && icon}
      {"  "}
      {label}
    </button>
  );
}
// ${
//   error.error && error.type === errorType ? "activeErrorButton" : null
// }
