import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import queryString from "query-string";
// * Style
import style from "../../AddressVerification/PANVerificationSlide/style.module.css";
// * Icons
// import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
// * Components
import ImageInputField2 from "../../../DataInputFields/ImageInputField2/ImageInputField2";
import ContinueButton from "../../../Buttons/SlideContinueButton/ContinueButton";
import { useLocation } from "react-router-dom";
// * API Handler
import { useMutation } from "@tanstack/react-query";
// * APIs
import {
  uploadCustomerDocument,
  verifyCustomerDocument,
} from "../../../../api/services/documents";
// * Helpers
import {
  convertBase64,
  getCookiePathFromURL,
  getLocationPermission,
  getFileSize,
} from "../../../../helpers";
// * Cookies
import { useCookies } from "react-cookie";
// * Notificaiton
import { toast } from "react-toastify";
// * Constants
import { LAST_ACTIVE_JOURNEY_SLIDE, TOTAL_SLIDES } from "../../../../constants";
// * Contexts
import CustomerContext from "../../../../context/CustomerContext";

const PANVerificationSlide = ({ switchToNextSlide, updateLastVisitSlide }) => {
  const { apiKey, apiSecret } = useParams();
  const CookiePath = getCookiePathFromURL(window.location.href);
  const { customerDetails } = useContext(CustomerContext);

  let url = useLocation().search;
  let queryValues = queryString.parse(url);
  const JourneyVersion = queryValues?.jrnyTyp;

  const [_, setCookie] = useCookies(["user"]);

  const initialState = {
    error: false,
    hasDocument: false,
    isVerified: false,
  };
  const [documentStatus, setDocumentStatus] = useState(initialState);

  const [pan, setPan] = useState(null);

  // * API to verify the document
  const verifyCustomerDocumentAPI = useMutation({
    mutationKey: ["verifyCustomerDocument"],
    mutationFn: (Payload) => verifyCustomerDocument(Payload),
    onSuccess: (data) => handleVerifyCustomerDocumentSuccess(data),
    onError: (error) => handleVerifyCustomerDocumentError(error),
    retry: false,
  });
  const handleVerifyCustomerDocumentSuccess = (data) => {
    setDocumentStatus({ ...documentStatus, error: false, isVerified: true });
    handlePanDelete();
    
    if(customerDetails.userType === "Credit Institution"){
      switchToNextSlide();
      setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 23, { path: CookiePath });
    }else{
      switchToNextSlide(25);
      setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 24, { path: CookiePath });
    }
  };

  const handleVerifyCustomerDocumentError = (error) => {
    handlePanDelete();
    toast.error(error.response.data.message);
  };

  const saveDocument = async () => {
    if (pan && !uploadCustomerDocumetAPI.isPending) {
      let base64 = null;

      if (typeof pan === "object") {
        base64 = await convertBase64(pan);
      } else {
        base64 = pan;
      }

      const headers = {
        apiKey,
        apiSecret,
      };
      let Payload = {
        contact_id: customerDetails.contactID,
        pipeline_id: customerDetails.pipelineID,
        lead_id: customerDetails.leadId,
        docUpload: base64,
        fileName: pan.name,
        docType: "pan",
        docFormat: "image",
        docNumber: "",
        source_name: "Journey",
        headers,
      };

      const location = await getLocationPermission();
      if (location) {
        Payload = { ...Payload, ...location };
      }

      uploadCustomerDocumetAPI.mutate(Payload);
    }
  };

  const uploadCustomerDocumetAPI = useMutation({
    mutationKey: ["uploadCustomerDocument"],
    mutationFn: (Payload) => uploadCustomerDocument(Payload),
    onSuccess: (data) => handleDocumentUploadSuccess(data),
    onError: (error) => handleDocumentUploadError(error),
    retry: false,
  });

  const handleDocumentUploadSuccess = (data) => {
    setDocumentStatus({ ...documentStatus, error: false, hasDocument: true });

    const headers = {
      apiKey,
      apiSecret,
    };
    const slidePayload = {
      headers,
      leadId:customerDetails?.leadId,
      slideName: "PAN",
      slideIndex: 23,
      slideIcon: "Pan",
      totalSlides: TOTAL_SLIDES,
      journeyVersion:JourneyVersion
    };
    // * Update Journey last visit slide
    updateLastVisitSlide(slidePayload);
    // * Verify document
    verifyCustomerDocumentAPI.mutate({
      ContactID: data.data.contact_id,
      DocumentID: data.data.id,
      headers,
    });
  };
  const handleDocumentUploadError = (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("authToken");
      saveDocument();
    } else {
      handlePanDelete();
      toast.error("Something went wrong! please try again.");
    }
  };

  const validateDocs = () => {
    const { hasDocument, isVerified } = documentStatus;

    if (hasDocument && isVerified) {
      switchToNextSlide();
    } else if (!hasDocument && pan.length === 0) {
      setDocumentStatus({ ...documentStatus, error: true });
    } else {
      saveDocument();
    }
  };

  const handlePanDelete = () => {
    setPan(false);
    setDocumentStatus(initialState);
  };

  const handlePan = (file) => {
    const fileSize = getFileSize(file);
    if (fileSize > 700) {
      toast.error("File size cannot be more than 700kb.");
    } else {
      setPan(file);
    }
  };

  return (
    <div className="carouselSlideWrapper salarySlipAndBankStatementSlideWrapper">
      <div className="typographyContainer">
        <h1>Identity Verification</h1>
        <p>Please upload your PAN Card</p>
      </div>

      <div className="dataFieldsContainer imageInputContainer">
        <div className={style.documentInputBox}>
          <ImageInputField2
            accept="image/jpg, image/jpeg, image/png"
            allowedFileTypes="jpeg, jpg, png"
            id="panUpload"
            file={pan}
            handler={handlePan}
            handleDelete={handlePanDelete}
            error={documentStatus.error}
            isLoading={
              uploadCustomerDocumetAPI.isPending ||
              verifyCustomerDocumentAPI.isPending
            }
          />
          <div className={style.instruction}>
            <ul>
              <li>*Please make sure that image is clear.</li>
              <li>**File size shouldn't be more than 700 KB.</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="imageInputContinueBtn">
        <ContinueButton
          label="Next"
          loading={
            uploadCustomerDocumetAPI.isPending ||
            verifyCustomerDocumentAPI.isPending
          }
          handler={validateDocs}
          disabled={pan ? false : true}
        />
      </div>
    </div>
  );
};

export default PANVerificationSlide;
