import * as actions from "./types";

export function addJourney(param, resolve, reject) {
  return {
    type: actions.ADD_JOURNEY,
    payload: param,
    resolve,
    reject,
  };
}
