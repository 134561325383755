import { useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import queryString from "query-string";
// ** Micro Components
import TextInputField from "../../../../DataInputFields/TextInputField/TextInputField";
import ContinueButton from "../../../../Buttons/SlideContinueButton/ContinueButton";
// * Contexts
import ErrorContext from "../../../../../context/ErrorProvider";
import CustomerContext from "../../../../../context/CustomerContext";
// * Errors and Error Types
import * as Errors from "../../../../../utils/errors";
// * API Handler
import { useMutation } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
// * APIs
import { sendAadhaarOtp } from "../../../../../api/services/documents";
// * Others
import { toast } from "react-toastify";
import { TOTAL_SLIDES, AADHAAR_FRONT_DOC_ID } from "../../../../../constants";

const AadhaarNumberSlide = ({ switchToNextSlide, updateLastVisitSlide }) => {
  const { apiKey, apiSecret } = useParams();
  // * Context to store customer details
  const { customerDetails, setCustomerDetails } = useContext(CustomerContext);
  // * State to handle journey error
  const { error, setError } = useContext(ErrorContext);
  // * Refs
  const aadhaarNumberRef = useRef();

  let url = useLocation().search;
  let queryValues = queryString.parse(url);
  const JourneyVersion = queryValues?.jrnyTyp;

  const sendAadhaarOtpAPI = useMutation({
    mutationKey: ["sendAadhaarOtp"],
    mutationFn: (Payload) => sendAadhaarOtp(Payload),
    onSuccess: (data) => handleSendAadhaarOTPSuccess(data),
    onError: (error) => handleSendAadhaarOTPError(error),
    retry: false,
  });
  const handleSendAadhaarOTPSuccess = (data) => {
    const res = data?.data;
    if (!res) {
      toast.warning("Please try again after sometime.");
      return;
    }
    if (res.provider === "Cashfree") {
      if (res.status === "SUCCESS") {
        setCustomerDetails({
          ...customerDetails,
          referenceId: data.data.ref_id,
        });

        const headers = { apiKey, apiSecret };
        const slidePayload = {
          headers,
          leadId: customerDetails?.leadId,
          slideName: "Aadhaar Number",
          slideIndex: 26,
          slideIcon: "AadhaarNumber",
          totalSlides: TOTAL_SLIDES,
          journeyVersion:JourneyVersion
        };
        // * Update Journey last visit slide
        updateLastVisitSlide(slidePayload);

        switchToNextSlide();
      } else {
        toast.error(res.message);
      }
    }
  };
  const handleSendAadhaarOTPError = (error) => {
    toast.error(error?.response?.data?.message);
  };

  const validateAadhaarNumber = () => {
    let aadhaarNumber = aadhaarNumberRef.current.value;
    aadhaarNumber = aadhaarNumber.replace(/[^0-9]/g, "");

    if (aadhaarNumber === "") {
      setError({
        error: true,
        type: Errors.AADHAR_NUMBER_ERROR_TYPE,
        message: Errors.NULL_AADHAAR_NUMBER_ERROR,
      });
      return false;
    }

    const aadhaarRegex =
      /^([0-9]{4}[0-9]{4}[0-9]{4}$)|([0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|([0-9]{4}-[0-9]{4}-[0-9]{4}$)/;
    if (
      /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(aadhaarNumber) ||
      !aadhaarRegex.test(aadhaarNumber)
    ) {
      setError({
        error: true,
        type: Errors.AADHAR_NUMBER_ERROR_TYPE,
        message: Errors.INVALID_AADHAAR_NUMBER_ERROR,
      });
      aadhaarNumberRef.current.value = aadhaarNumber.replace(
        /(.{4})(?=.)/g,
        "$1-"
      );
      return false;
    }

    if (error.error && error.type === Errors.AADHAR_NUMBER_ERROR_TYPE) {
      setError({
        error: false,
        type: "",
        message: "",
      });
      return true;
    }

    return true;
  };

  const submitAadhaarNumber = () => {
    const aadhaarNumber = aadhaarNumberRef.current?.value?.replace(
      /[^0-9]/g,
      ""
    );
    const isAadhaarValid = validateAadhaarNumber(aadhaarNumber);

    console.log("isAadhaarValid : ", isAadhaarValid);

    if (isAadhaarValid) {
      setCustomerDetails({
        ...customerDetails,
        aadhaarNumber: aadhaarNumber.split(" ").join(""),
      });
      const Headers = { apiKey, apiSecret };
      const Payload = {
        aadhaarNumber: aadhaarNumber.split(" ").join(""),
        documentId: customerDetails[AADHAAR_FRONT_DOC_ID],
        headers: Headers,
      };

      sendAadhaarOtpAPI.mutate(Payload);
    }
  };

  return (
    <div className="carouselSlideWrapper">
      <div className="typographyContainer">
        <h1>Aadhaar Number</h1>
        <p>Please enter your Aadhaar number.</p>
      </div>

      <div className="dataFieldsContainer">
        <TextInputField
          type="text"
          label="Aadhaar number"
          placeholder="XXXX-XXXX-XXXX"
          name="aadhaarNumber"
          id="aadhaarNumber"
          reference={aadhaarNumberRef}
          maxLength="14"
          // minLength="12"
          errorType={Errors.AADHAR_NUMBER_ERROR_TYPE}
          handler={validateAadhaarNumber}
          autoFocus={true}
          required={true}
        />
      </div>

      <div>
        <ContinueButton
          label="Next"
          loading={sendAadhaarOtpAPI.isPending}
          handler={submitAadhaarNumber}
          // handler={switchToNextSlide}
          disabled={sendAadhaarOtpAPI.isPending}
        />
      </div>
    </div>
  );
};

export default AadhaarNumberSlide;
