import style from "./SanctionLetterSign.module.css";

import { useState, useContext } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";

// * Contexts
import CustomerContext from "../../../context/CustomerContext";
import queryString from "query-string";
// * APIs and API Handler
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getSanctionLetterDocument,
  addSanctionLetterDocument,
  updateSanctionLetterDocument,
} from "../../../api/services/SanctionLetter";
import { createTimeline } from "../../../api/services/Journey";
import {
  addCustomerDocumentKycResponse,
  uploadCustomerDocument,
} from "../../../api/services/documents";
import {
  uploadDocumentForEsign,
  saveSignedDocument,
} from "../../../api/services/eSign";
import { useLocation } from "react-router-dom";

// * Utilities
import { getLocationPermission } from "../../../helpers";
import { formatVariableOutput } from "../../../utils/SanctionLetter/SanctionLetter.utils";
import Signature from "../../Signature/Signature";

// * Constants
import { DIGIO_ENVIRONMENT, TOTAL_SLIDES } from "../../../constants";
import { base64ToBlob } from "../../../utils/Others";

export default function SanctionLetterSign({
  activeSlide,
  sanctionLetter,
  setSanctionLetter,
  switchToNextSlide,
  updateLastVisitSlide,
  setSignedDocument,
}) {
  const queryClient = useQueryClient();
  const { apiKey, apiSecret } = useParams();

  let url = useLocation().search;
  let queryValues = queryString.parse(url);
  const JourneyVersion = queryValues?.jrnyTyp;

  const { customerDetails } = useContext(CustomerContext);

  const [existingSanctionLetter, setExistingDocument] = useState(null);

  // * API to get uploaded document
  const saveSignedDocumentAPI = useMutation({
    mutationFn: (payload) => saveSignedDocument(payload),
    onSuccess: (data) => handleGetUploadedDocumentSuccess(data),
    onError: (data) => handleGetUploadedDocumentError(data),
    retry: false,
  });
  const handleGetUploadedDocumentSuccess = async (data) => {
    const blob = base64ToBlob(data.data.document_contents, "application/pdf");
    const url = URL.createObjectURL(blob);

    setSignedDocument((prev) => ({
      ...prev,
      sanctionLetter: url,
    }));

    const slidePayload = {
      headers: { apiKey, apiSecret },
      leadId: customerDetails?.leadId,
      slideName: "Sanction Letter Sign",
      // slideIndex: 30,
      slideIcon: "SanctionLetterSign",
      totalSlides: TOTAL_SLIDES,
      journeyVersion: JourneyVersion,
    };
    // * Update Journey last visit slide
    updateLastVisitSlide(slidePayload);

    switchToNextSlide();
  };
  const handleGetUploadedDocumentError = (error) => {
    console.log("Uploaded Document Error : ", error);
    toast.error("Something went wrong, please try again.");
  };

  // * API to upload documents for eSign and eStamp(Digio)
  const uploadDocumentToDigio = useMutation({
    mutationFn: (payload) => uploadDocumentForEsign(payload),
    onSuccess: (data) => handleUploadDocumentToDigioSuccess(data),
    onError: (error) => handleUploadDocumentToDigioError(error),
    retry: false,
  });
  const handleUploadDocumentToDigioSuccess = (data) => {
    const response = data.data;
    var options = {
      environment: DIGIO_ENVIRONMENT,
      is_iframe: true,
      callback: getUploadedDocumentFromDigioAfterSign,
    };
    var digioInstance = new window.Digio(options);
    digioInstance.init();
    digioInstance.submit(
      response.id,
      response.signing_parties[0].identifier,
      response.access_token.id
    );
  };
  const handleUploadDocumentToDigioError = (error) => {
    console.log("Digio eSign error : ", error);
    toast.error("Something went wrong, please try again.");
  };
  const handleUploadDocumentToDigio = (documentBase64, fileName) => {
    const data = {
      contactId: customerDetails.contactID,
      pipelineId: customerDetails.pipelineID,
      documentBase64,
      reason: "Sanction Letter",
      fileName,
      operationType: "eSign",
    };
    const headers = {
      apiKey,
      apiSecret,
    };
    const payload = {
      data,
      headers,
    };
    uploadDocumentToDigio.mutate(payload);
  };

  const getUploadedDocumentFromDigioAfterSign = async (response) => {
    const location = await getLocationPermission();
    if (response?.error_code && response?.error_code === "CANCELLED") {
      switchToNextSlide(activeSlide - 1);
      return;
    }

    // * Create timeline for Sanction Letter generation
    const timelineData = {
      type: "Agreement",
      message: "Customer signed the Agreement.",
    };
    handleCreateTimeline(timelineData);

    let data = {
      contactId: customerDetails.contactID,
      pipelineId: customerDetails.pipelineID,
      leadId: customerDetails.leadId,
      documentType: "sanctionLetterSigned",
      customerName: customerDetails.fullName,
    };
    if (location) {
      data = { ...data, ...location };
    }
    let payload = {
      data,
      documentId: response.digio_doc_id,
      headers: { apiKey, apiSecret },
    };
    saveSignedDocumentAPI.mutate(payload);
  };

  // * API to create a Timeline
  const createTimelineAPI = useMutation({
    mutationFn: (Payload) => createTimeline(Payload),
    retry: false,
  });
  const handleCreateTimeline = (data) => {
    data.leadId = customerDetails.leadId;
    const headers = {
      apiKey,
      apiSecret,
    };
    const Payload = {
      data,
      headers,
    };
    createTimelineAPI.mutate(Payload);
  };

  // * API to get sanction letter
  useQuery({
    queryKey: ["sanctionLetterDocument"],
    queryFn: () =>
      getSanctionLetterDocument({
        leadId: customerDetails.leadId,
        headers: { apiKey, apiSecret },
      })
        .then(handleGetSanctionLetterDocumentSuccess)
        .catch(handleGetSanctionLetterDocumentError),
    enabled: customerDetails.leadId ? true : false,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const handleGetSanctionLetterDocumentSuccess = (data) => {
    setExistingDocument(() => data.data);
    return data;
  };
  const handleGetSanctionLetterDocumentError = (error) => {
    return error;
  };

  // * API to add sanction letter
  const addSanctionLetterDocumentAPI = useMutation({
    mutationKey: ["addSancitonLetterDocument"],
    mutationFn: (payload) =>
      addSanctionLetterDocument(payload)
        .then(handleAddSanctionLetterDocumentSuccess)
        .catch(handleAddSanctionLetterDocumentError),
    enabled: customerDetails.leadId ? true : false,
    retry: false,
  });
  const handleAddSanctionLetterDocumentSuccess = (data) => {
    // * Refetch generated sanction letter
    queryClient.invalidateQueries({ queryKey: ["sanctionLetterDocument"] });

    // * Create timeline for Sanction Letter generation
    const timelineData = {
      type: "Sanction Letter",
      message: "Sanction letter generated.",
    };
    handleCreateTimeline(timelineData);

    return data;
  };
  const handleAddSanctionLetterDocumentError = (error) => {
    console.log("ERROR2 : ", error);

    if (error?.response?.status === 400) {
      console.log("Sanction letter already exists.");
    } else {
      toast.error("Something went wrong, please try again.");
    }
    return error;
  };
  const handleAddSanctionLetterDocument = (document_id) => {
    const { leadId, contactID, pipelineID } = customerDetails;

    const headers = {
      apiKey,
      apiSecret,
    };
    const data = {
      contact_id: contactID,
      lead_id: leadId,
      pipeline_id: pipelineID,
      document_id,
      document: sanctionLetter.documentWithSign,
      offerGeneratedAt: new Date(),
      status: "Generated",
      remark: "Sanction letter generated successfully.",
      sanction_letter_id: customerDetails.sanctionLetterId,
    };
    const Payload = {
      headers,
      data,
    };
    addSanctionLetterDocumentAPI.mutate(Payload);
  };

  // * API to update sanction letter document.
  const updateSanctionLetterDocumentAPI = useMutation({
    mutationKey: ["updateSanctionLetterDocument"],
    mutationFn: (payload) =>
      updateSanctionLetterDocument(payload).then(
        handleUpdateSanctionLetterDocumentSuccess
      ),
    enabled: customerDetails.leadId ? true : false,
    retry: false,
  });
  const handleUpdateSanctionLetterDocumentSuccess = (data) => {
    // * Refetch generated sanction letter
    queryClient.invalidateQueries({ queryKey: ["sanctionLetterDocument"] });

    return data;
  };
  const handleUpdateSanctionLetterDocument = (data) => {
    const headers = {
      apiKey,
      apiSecret,
    };
    const Payload = {
      leadId: customerDetails.leadId,
      data,
      headers,
    };
    updateSanctionLetterDocumentAPI.mutate(Payload);
  };

  // * API to add KYC Response for customer document
  const addKycResponseAPI = useMutation({
    mutationFn: (Payload) => addCustomerDocumentKycResponse(Payload),
    retry: false,
  });
  const handleAddKycResponse = (documentDetails) => {
    let kycData = {};
    const variables = documentDetails.variables;

    if (variables && variables.length) {
      variables.forEach((variable) => {
        kycData[variable.label] = formatVariableOutput(
          variable.value,
          variable.variable
        );
      });
    }
    kycData[documentDetails.stageKey] = "Generated";

    const headers = {
      apiKey,
      apiSecret,
    };
    const Payload = {
      contactId: customerDetails.contactID,
      documentId: documentDetails.documentId,
      kycData,
      headers,
    };
    addKycResponseAPI.mutate(Payload);
  };

  // * API to upload customer document
  const uploadCustomerDocumentAPI = useMutation({
    mutationKey: ["uploadCustomerDocument"],
    mutationFn: (Payload) =>
      uploadCustomerDocument(Payload)
        .then(handleDocumentUploadSuccess)
        .catch(handleDocumentUploadError),
    retry: false,
  });
  const handleDocumentUploadSuccess = (data) => {
    const doc = data.data;

    if (doc.docType === "sanctionLetter") {
      let documentDetailsForKYC = {
        documentId: doc.id,
      };

      documentDetailsForKYC.variables = sanctionLetter.documentVariables;
      documentDetailsForKYC.stageKey = "Sanction Stage Status";

      // * Save KYC Reponse
      handleAddKycResponse(documentDetailsForKYC);

      if (existingSanctionLetter) {
        // * Update the status of the generated Sanction Letter.
        const sanctionUpdatedData = {
          contact_id: customerDetails.contactID,
          document_id: doc.id,
          document: sanctionLetter.documentWithSign,
          offerCancelledAt: null,
          offerSentAt: null,
          offerGeneratedAt: new Date(),
          status: "Generated",
          remark: "Sanction letter generated successfully.",
        };
        handleUpdateSanctionLetterDocument(sanctionUpdatedData);
      } else {
        // * Create an Sanction Letter
        handleAddSanctionLetterDocument(doc.id);
      }
    }

    return data;
  };
  const handleDocumentUploadError = (error) => {
    console.log("ERROR : ", error);
    toast.error("Something went wrong, please try again.");
    return error;
  };
  const uploadGeneratedSanctionAndAgreement = async (pdfBase64, document) => {
    const location = await getLocationPermission();

    const headers = {
      apiKey,
      apiSecret,
    };
    let Payload = {
      headers,
      contact_id: customerDetails.contactID,
      pipeline_id: customerDetails.pipelineID,
      lead_id: customerDetails.leadId,
      docUpload: pdfBase64,
      fileName: document.fileName,
      docNumber: "",
      docName: document.documentName,
      docFormat: "pdf",
      docType: document.docType,
      source_name: "custom",
    };
    if (location) {
      Payload = { ...Payload, ...location };
    }
    uploadCustomerDocumentAPI.mutate(Payload);
  };

  const uploadCustomerSelfie = async (clickedSelfie) => {
    const location = await getLocationPermission();
    const headers = {
      apiKey,
      apiSecret,
    };
    let Payload = {
      headers,
      contact_id: customerDetails.contactID,
      pipeline_id: customerDetails.pipelineID,
      lead_id: customerDetails.leadId,
      docUpload: clickedSelfie,
      fileName: "Sanction Letter Signature Selfie",
      docNumber: "",
      docName: "Sanction Letter Signature Selfie",
      docFormat: "image",
      docType: "sanctionLetterSignatureSelfie",
      source_name: "custom",
    };
    if (location) {
      Payload = { ...Payload, ...location };
    }
    uploadCustomerDocumentAPI.mutate(Payload);
  };

  return (
    <div className={style.slide}>
      <div className={style.slideHeader}>
        <h1>Executing Sanction Letter</h1>
      </div>

      <Signature
        documentType="sanctionLetter"
        document={sanctionLetter}
        setDocument={setSanctionLetter}
        uploadGeneratedSanctionAndAgreement={
          uploadGeneratedSanctionAndAgreement
        }
        handleUploadDocumentToDigio={handleUploadDocumentToDigio}
        uploadCustomerSelfie={uploadCustomerSelfie}
        docQuality={1}
        loading={
          saveSignedDocumentAPI.isPending ||
          uploadDocumentToDigio.isPending ||
          uploadCustomerDocumentAPI.isPending ||
          addSanctionLetterDocumentAPI.isPending ||
          addKycResponseAPI.isPending ||
          updateSanctionLetterDocumentAPI.isPending
        }
      />
    </div>
  );
}
