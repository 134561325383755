import axiosWithDomain from "../../axios";
import {
  GET_ALL_SALARY_SLIPS,
  GET_INTEREST_RATE,
} from "../../../constants/urls";

export const getAllSalarySlips = async (Payload) => {
  const { data, headers } = Payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${GET_ALL_SALARY_SLIPS}`,
    data,
    { headers }
  );
  return res?.data;
};

export const getInterestRate = async (Payload) => {
  const { data, headers } = Payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${GET_INTEREST_RATE}`,
    data,
    { headers }
  );
  return res?.data;
};
