import "./EditButton.css";
// import { useState } from "react";
import Loader from "../../Loader/Loader";
import RestoreIcon from "@mui/icons-material/Restore";
// import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { useContext } from "react";
import ErrorConext from "../../../context/ErrorProvider";

export default function EditButton({
  handler,
  label,
  loading,
  tabIndex,
  disabled = false,
  cardButton,
  isUdyamAadhaarSlide = false,
  isEdit,
}) {
  const { error } = useContext(ErrorConext);
  // const [isButtonClicked, setButtonClicked] = useState(false);

  // * Debounce
  // const handleClick = () => {
  //   if (!isButtonClicked) {
  //     setButtonClicked(true);
  //     handler();

  //     setTimeout(() => {
  //       setButtonClicked(false);
  //     }, 1000);
  //   }
  // };

  return (
    <div className="continueBtnContainer">
      {loading ? (
        <Loader />
      ) : (
        <button
          tabIndex={tabIndex && tabIndex}
          className={`editBtn`}
          // disabled={error.error || disabled ? true : false}
          onClick={handler}
        >
          {isEdit ? "Cancel" : label ? label : "Edit"}
        </button>
      )}
    </div>
  );
}
