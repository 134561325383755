// * Input 150000 => Output: 1,50,000
export const formatNumber = (number) => {
  const formatter = new Intl.NumberFormat("en-IN", {
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    compactDisplay: "long",
  });
  return formatter.format(number);
};
