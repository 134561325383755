import * as actions from "../actions/types";
import * as urls from "../../urls";
import { all, call, fork, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { DOMAIN_NAME } from "../../constants";

function verifyOtpApi(params) {
  let headerObj = params.headerData;
  let bodyObj = params.verifyOtpData;
  return axios.request({
    // withCredentials: true,
    method: "post",
    url: `${urls.VERIFY_OTP_URL}`,
    headers: {
      domain_name: DOMAIN_NAME,
      ...headerObj,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Methods": "*",
    },
    data: bodyObj,
  });
}

export function* verifyOtpActionEffect(verifyOtpAction) {
  const { payload, resolve, reject } = verifyOtpAction;

  try {
    const { data } = yield call(verifyOtpApi, payload);
    if (data) {
      if (resolve) resolve(data);
    } else {
      reject(data);
    }
  } catch (e) {
    if (reject) reject(e);
  }
}

export function* verifyOtpActionWatcher() {
  yield takeLatest(actions.VERIFY_OTP, verifyOtpActionEffect);
}

export default function* rootSaga() {
  yield all([fork(verifyOtpActionWatcher)]);
}
