import { useRef, useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// * Packages
import { useCookies } from "react-cookie";
import queryString from "query-string";
// * Styles
import SlideStyle from "../../../../styles/Slide/style.module.css";
// * Micro Components
import TextInputField from "../../../DataInputFields/TextInputField/TextInputField";
import ContinueButton from "../../../Buttons/SlideContinueButton/ContinueButton";
// ** Error messagres aand error types
import * as Errors from "../../../../utils/errors";
// * Helpers
import {
  calculateAge2,
  getCookiePathFromURL,
  trimString,
} from "../../../../helpers";
import { calculateAge } from "../../../../utils/common";
// * Utilities
import { formatDate } from "../../../../utils";
// * Contexts
import CustomerContext from "../../../../context/CustomerContext";
import ErrorContext from "../../../../context/ErrorProvider";
// * Constants
import { CUSTOMER_AGE, TOTAL_SLIDES, DOMAIN_NAME } from "../../../../constants";

import moment from "moment";
import EditButton from "../../../Buttons/SlideEditButton/EditButton";

const Slide = ({
  ageRef,
  loading,
  setLoading,
  fullNameDet,
  activeSlide,
  switchToNextSlide,
  setCustomerDetailsCookies,
  updateJourneyData,
  fullnameRef,
  smsTemplates,
  sendStageWiseSMS,
  handleCreateTimeline,
}) => {
  const CookiePath = getCookiePathFromURL(window.location.href);
  let queryValues = queryString.parse(window.location.search);
  const JourneyVersion = queryValues?.jrnyTyp;
  const { apiKey, apiSecret } = useParams();

  const [values, setValues] = useState({ name: "", dob: "" });

  const dobRef = useRef("");

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (fullnameRef.current) {
      fullnameRef.current.value =
        DOMAIN_NAME === "maxemocapital"
          ? fullNameDet?.toLowerCase()
          : customerDetails?.customerDetails?.full_name?.toLowerCase();
    }
    if (dobRef.current) {
      // let date = new Date(customerDetails?.customerDetails?.date_of_birth);
      console.log(
        "DOB=>",
        customerDetails?.customerDetails?.date_of_birth,
        moment(customerDetails?.customerDetails?.date_of_birth).format(
          "YYYY-DD-MM"
        )
      );
      dobRef.current.value =
        // moment(
        customerDetails?.customerDetails?.date_of_birth;
      // ).format("DD-MM-YYYY");
    }
  }, [isEdit]);

  // * Cookies
  const [_, setCookie] = useCookies(["user"]);

  // * Refs and States
  // const fullnameRef = useRef("");
  // const panRef = useRef("");

  const [dob, setDob] = useState(new Date());

  const { customerDetails, setCustomerDetails } = useContext(CustomerContext);
  // * State to handle Journey error
  const { error, setError } = useContext(ErrorContext);

  const handleSuccess = (data) => {
    const panData = data.data;
    if (panData.status === "1") {
      const mobileInPan = panData.result.mobile;
      const isAadhaarLinked = panData.result.aadharlink;
      if (JourneyVersion === "J3") {
        switchToNextSlide(10);
      } else {
        switchToNextSlide(activeSlide + 1);
      }
      if (mobileInPan == customerDetails.mobileNumber && isAadhaarLinked) {
      } else {
        setError({
          error: true,
          type: Errors.PAN_ERROR_TYPE,
          message: Errors.INVALID_PHONE_ERROR,
        });
      }
    } else if (
      panData.status == 2 ||
      panData.status == 3 ||
      panData.status == 4
    ) {
      setError({
        error: true,
        type: Errors.PAN_ERROR_TYPE,
        message: panData.message,
      });
    }
  };

  useEffect(() => {
    setValues({
      ...values,
      name: customerDetails?.compane_name,
      dob: customerDetails?.dob,
    });
    // if (customerDetails?.compane_name) {
    //   fullnameRef.current.value = customerDetails?.compane_name;
    // }
    // if (customerDetails?.dob) {
    //   dobRef.current.value = customerDetails?.dob;
    //   setDob(customerDetails?.dob);
    // }
  }, [customerDetails]);

  // * Main Function
  async function addIndividualDetails() {
    console.log("inside addIndividualDetails");
    const fullName =
      DOMAIN_NAME === "maxemocapital"
        ? fullNameDet
        : isEdit
        ? fullnameRef.current.value
        : customerDetails?.customerDetails?.full_name;
    // const DOB = dobRef.current.value;
    let newDOB = "";
    if (dobRef.current) {
      newDOB = dobRef.current.value;
    }
    const DOB = isEdit
      ? newDOB
      : customerDetails?.customerDetails?.date_of_birth;
    // const PAN = panRef.current.value;

    if (!handleFullName(fullName, Errors.FULLNAME_ERROR_TYPE)) {
      return false;
    }
    console.log("DOB=>", dob);
    if (!handleDOB(formatDate(DOB), Errors.DOB_ERROR_TYPE)) {
      return false;
    }
    // if (!handlePAN(PAN, Errors.PAN_ERROR_TYPE)) {
    //   return false;
    // }
    if (!error.error) {
      window.webengage.track("Full Name", {
        "Name Entered": fullName,
      });
      window.webengage.track("DOB", {
        "Value Entered": DOB,
      });
      window.webengage.user.setAttribute(
        "we_first_name",
        fullName?.split(" ")[0]
      );
      window.webengage.user.setAttribute(
        "we_last_name",
        fullName?.split(" ")[1]
      );
      window.webengage.user.setAttribute("we_birth_date", DOB);

      setError({
        error: false,
        type: "",
        message: "",
      });
      // setLoading(true);
      const data = {
        full_name: trimString(fullName),
        date_of_birth: DOB,
        // pan: PAN.toUpperCase(),
      };
      // * Saving Details to access through out the Journey.
      const details = {
        fullName: trimString(fullName),
        dateOfBirth: DOB,
        // pan: PAN,
      };
      setCustomerDetails({
        ...customerDetails,
        ...details,
      });
      const slidePayload = {
        slideName: "Individual Details",
        slideIndex: 3,
        slideIcon: "IndividualDetails",
        totalSlides: TOTAL_SLIDES,
      };

      const response = await updateJourneyData(data, slidePayload);
      console.log("before call update journey api", response);
      if (response) {
        console.log("inside response of update journey data");
        const customerAge = calculateAge2(DOB);
        ageRef.current = customerAge;
        setCookie(CUSTOMER_AGE, customerAge, { path: CookiePath });
        // * Setting Cookies
        details.customerAge = customerAge;
        setCustomerDetailsCookies(details);

        // * Send stage wise individual SMS
        const newLeadSmsTemplate = smsTemplates.filter(
          (template) => template.stage_name === "New Lead"
        );
        if (
          newLeadSmsTemplate &&
          newLeadSmsTemplate.length &&
          newLeadSmsTemplate[0].sms_template_id
        ) {
          sendStageWiseSMS(newLeadSmsTemplate[0].sms_template_id);

          // * Create a timeline
          const timelineData = {
            type: "SMS",
            message: "SMS trigged as lead status updated to New Lead.",
          };
          handleCreateTimeline(timelineData);
        }

        // await handleCheckPhoneOnPan();
        // setLoading(false);
        if (JourneyVersion === "J3") {
          switchToNextSlide(7); // 6
        } else {
          switchToNextSlide(activeSlide + 2);
        }
      }
      setLoading(false);
    }
  }

  async function editIndividualDetails() {
    // fullnameRef.current.value = customerDetails?.compane_name;
    // dobRef.current.value = customerDetails?.dob;
    setIsEdit(!isEdit);
  }

  // * Handlers
  function handleFullName(fullName, errorType) {
    if (fullName === "") {
      setError({
        error: true,
        type: errorType,
        message: Errors.FULLNAME_ERROR,
      });
      return false;
    }
    if (/[0-9]/.test(fullName)) {
      setError({
        error: true,
        type: errorType,
        message: Errors.FULLNAME_DIGIT_ERROR,
      });
      return false;
    }
    if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(fullName)) {
      setError({
        error: true,
        type: errorType,
        message: Errors.FULLNAME_SPECIAL_CHARACTER_ERROR,
      });
      return false;
    }
    if (error.error && error.type === errorType) {
      setError({
        error: false,
        type: "",
        message: "",
      });
      return true;
    }
    return true;
  }

  function handleDOB(DOB, errorType) {
    console.log("DOB check=>", DOB);
    setDob(DOB);

    if (DOB === "") {
      setError({
        error: true,
        type: errorType,
        message: Errors.DOB_ERROR,
      });
      return false;
    }
    const SeperateDOB = DOB.split("-");
    const currentYear = new Date().getFullYear();
    if (currentYear === SeperateDOB[0] || currentYear < SeperateDOB[0]) {
      setError({
        error: true,
        type: errorType,
        message: Errors.SAME_DATE_ERROR,
      });
      return false;
    }
    const Age = calculateAge(
      new Date(SeperateDOB[0], SeperateDOB[1], SeperateDOB[2])
    );
    if (Age < 18) {
      setError({
        error: true,
        type: errorType,
        message: Errors.DOB_NOT_ELIGIBLE_ERROR,
      });
      return false;
    }
    if (Age > 80) {
      setError({
        error: true,
        type: errorType,
        message: Errors.MAX_AGE_ERROR,
      });
      return false;
    }
    // dobRef.current = DOB;
    if (error.error && error.type === errorType) {
      setError({
        error: false,
        type: "",
        message: "",
      });
      return true;
    }
    return true;
  }
  return (
    <div className={SlideStyle.carouselSlideWrapper}>
      <div className={SlideStyle.typographyContainer}>
        <h1>Individual Details</h1>
        <p>Please check and confirm the below details.</p>
      </div>
      {/* Input Fields */}
      <div className={SlideStyle.autoFieldsContainer}>
        {/* Fullname Input Field */}
        {/* <TextInputField
          type="text"
          label="Full Name (As per PAN)"
          placeholder="Enter Full Name"
          name="fullname"
          id="fullname"
          reference={fullnameRef}
          // updateEdit={updateEdit}
          disabled={isEdit ? true : false}
          required={true}
          errorType={Errors.FULLNAME_ERROR_TYPE}
          handler={(fullName) =>
            handleFullName(fullName, Errors.FULLNAME_ERROR_TYPE)
          }
        /> */}
        {isEdit && DOMAIN_NAME !== "maxemocapital" ? (
          <TextInputField
            type="text"
            label="Full Name (As per PAN)"
            placeholder="Enter Full Name"
            name="fullname"
            id="fullname"
            reference={fullnameRef}
            required={true}
            errorType={Errors.FULLNAME_ERROR_TYPE}
            handler={(fullName) =>
              handleFullName(fullName, Errors.FULLNAME_ERROR_TYPE)
            }
          />
        ) : (
          <div className={SlideStyle.typographyContainer}>
            <p>Full Name (As per PAN)</p>
            <h1 className="capitalizeName">
              {DOMAIN_NAME === "maxemocapital"
                ? fullNameDet?.toLowerCase()
                : customerDetails?.customerDetails?.full_name?.toLowerCase()}
            </h1>
          </div>
        )}
        {/* <div className={SlideStyle.typographyContainer}>
          <p>Full Name (As per PAN)</p>
          <h1 className="capitalizeName">
            {DOMAIN_NAME === "maxemocapital"
              ? fullNameDet?.toLowerCase()
              : customerDetails?.customerDetails?.full_name?.toLowerCase()}
          </h1>
        </div> */}
        {/* Date of Birth Input Field */}
        {/* <TextInputField
          type="date"
          label="Date of Birth"
          placeholder="DD-MM-YYYY"
          name="dob"
          id="dob"
          minLength="1960-01-01"
          maxLength={`${new Date().getFullYear()}-01-01`}
          reference={dobRef}
          // updateEdit={updateEdit}
          disabled={isEdit ? true : false}
          required={true}
          errorType={Errors.DOB_ERROR_TYPE}
          handler={(dob) => handleDOB(dob, Errors.DOB_ERROR_TYPE)}
          onEnterHandler={addIndividualDetails}
        />*/}
        {isEdit ? (
          <TextInputField
            type="date"
            label="Date of Birth"
            placeholder="DD-MM-YYYY"
            name="dob"
            id="dob"
            minLength="1960-01-01"
            maxLength={`${new Date().getFullYear()}-01-01`}
            reference={dobRef}
            // updateEdit={updateEdit}
            // disabled={isEdit ? true : false}
            required={true}
            errorType={Errors.DOB_ERROR_TYPE}
            handler={(dob) => handleDOB(dob, Errors.DOB_ERROR_TYPE)}
            onEnterHandler={addIndividualDetails}
          />
        ) : (
          <div className={SlideStyle.typographyContainer}>
            <p>Date of Birth</p>
            <h1>
              {moment(customerDetails?.customerDetails?.date_of_birth).format(
                "DD-MMM-YYYY"
              )}
            </h1>
          </div>
        )}
      </div>

      {/* Continue Slide Button */}
      <div className={SlideStyle.continueBtn}>
        <ContinueButton
          loading={loading}
          isAutoFilled={isEdit ? false : true}
          handler={addIndividualDetails}
        />
      </div>
      <div>
        <EditButton isEdit={isEdit} handler={editIndividualDetails} />
      </div>
    </div>
  );
};

export default Slide;
