import style from "./CustomerAddressVerification.module.css";
import queryString from "query-string";
import { useState, useContext } from "react";
import { useParams } from "react-router-dom";

import AadhaarVerificationComponents from "../AadhaarVerification/AadhaarVerificationSlide/AadharVerificationSlideComponent";
import ImageInputField2 from "../../../DataInputFields/ImageInputField2/ImageInputField2";
import DropDownField from "../../../DataInputFields/DropDownField/DropDownField";
import ContinueButton from "../../../Buttons/SlideContinueButton/ContinueButton";
import { useLocation } from "react-router-dom";

// * API Handler
import { useMutation } from "@tanstack/react-query";
import {
  uploadCustomerDocument,
  verifyCustomerDocument,
} from "../../../../api/services/documents";

// * Constants
import { addressVerificationDocuments } from "../../../../constants";
import * as Errors from "../../../../utils/errors";

// * Cookies
import { useCookies } from "react-cookie";
// * Notificaiton
import { toast } from "react-toastify";

// * Helpers
import {
  convertBase64,
  getCookiePathFromURL,
  getLocationPermission,
  getFileSize,
} from "../../../../helpers";

// * Context
import CustomerContext from "../../../../context/CustomerContext";

// * Constants
import { LAST_ACTIVE_JOURNEY_SLIDE, TOTAL_SLIDES } from "../../../../constants";

export default function CustomerAddressVerification({
  activeSlide,
  switchToNextSlide,
  updateLastVisitSlide,
}) {
  const { apiKey, apiSecret } = useParams();
  const CookiePath = getCookiePathFromURL(window.location.href);

  let url = useLocation().search;
  let queryValues = queryString.parse(url);
  const JourneyVersion = queryValues?.jrnyTyp;

  const { customerDetails, setCustomerDetails } = useContext(CustomerContext);

  const [_, setCookie] = useCookies(["user"]);

  const initialState = {
    error: false,
    hasDocument: false,
    isVerified: false,
  };
  const [documentStatus, setDocumentStatus] = useState(initialState);
  const [selectedDocType, selectDocType] = useState(null);
  const [document, setDocument] = useState(null);

  // * API to upload the document
  const uploadCustomerDocumetAPI = useMutation({
    mutationKey: ["uploadCustomerDocument"],
    mutationFn: (Payload) => uploadCustomerDocument(Payload),
    onSuccess: (data) => handleDocumentUploadSuccess(data),
    onError: (error) => handleDocumentUploadError(error),
    retry: false,
  });
  const handleDocumentUploadSuccess = (data) => {
    setDocumentStatus({ ...documentStatus, error: false, hasDocument: true });

    const headers = {
      apiKey,
      apiSecret,
    };
    const slidePayload = {
      headers,
      leadId: customerDetails?.leadId,
      slideName: "Address Verification",
      slideIndex: 24,
      slideIcon: "AddressVerification",
      totalSlides: TOTAL_SLIDES,
      journeyVersion:JourneyVersion
    };
    // * Update Journey last visit slide
    updateLastVisitSlide(slidePayload);
    switchToNextSlide();
    setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 22, { path: CookiePath });

    // * Verify document
    // verifyCustomerDocumentAPI.mutate({
    //   ContactID: data.data.contact_id,
    //   DocumentID: data.data.id,
    //   headers,
    // });
  };
  const handleDocumentUploadError = (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("authToken");
      saveDocument();
    } else {
      handleDocumentDelete();
      toast.error("Something went wrong! please try again.");
    }
  };

  const saveDocument = async () => {
    if (document && !uploadCustomerDocumetAPI.isPending) {
      let base64 = null;

      if (typeof document === "object") {
        base64 = await convertBase64(document);
      } else {
        base64 = document;
      }

      const headers = {
        apiKey,
        apiSecret,
      };
      let Payload = {
        contact_id: customerDetails.contactID,
        pipeline_id: customerDetails.pipelineID,
        lead_id: customerDetails.leadId,
        docUpload: base64,
        fileName: document.name,
        docType: "customerAddress",
        docFormat: "image",
        docNumber: "",
        source_name: "Journey",
        headers,
      };

      const location = await getLocationPermission();
      if (location) {
        Payload = { ...Payload, ...location };
      }

      uploadCustomerDocumetAPI.mutate(Payload);
    }
  };
  const validateDocs = () => {
    const { hasDocument, isVerified } = documentStatus;

    if (hasDocument && isVerified) {
      switchToNextSlide();
    } else if (!hasDocument) {
      setDocumentStatus({ ...documentStatus, error: true });
    } else {
      saveDocument();
    }
  };

  // * API to verify the document
  const verifyCustomerDocumentAPI = useMutation({
    mutationKey: ["verifyCustomerDocument"],
    mutationFn: (Payload) => verifyCustomerDocument(Payload),
    onSuccess: (data) => handleVerifyCustomerDocumentSuccess(data),
    onError: (error) => handleVerifyCustomerDocumentError(error),
    retry: false,
  });
  const handleVerifyCustomerDocumentSuccess = (data) => {
    setDocumentStatus({ ...documentStatus, error: false, isVerified: true });
    setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 19, { path: CookiePath });
    handleDocumentDelete();

    switchToNextSlide();
  };
  const handleVerifyCustomerDocumentError = (error) => {
    handleDocumentDelete();
    toast.error(error.response.data.message);
  };

  const handleDocument = (file) => {
    const fileSize = getFileSize(file);
    if (fileSize > 700) {
      toast.error("File size cannot be more than 700kb.");
    } else {
      setDocument(file);
      setDocumentStatus((prev) => ({ ...prev, hasDocument: true }));
    }
  };
  const handleDocumentDelete = () => {
    setDocument(null);
    setDocumentStatus(initialState);
  };
  const handleSelectDocType = (value) => {
    selectDocType(value);
    setCustomerDetails((prev) => ({
      ...prev,
      addressVerificationDocumentType: value,
    }));
  };

  return (
    <div className={style.customerAddressVerificationSlide}>
      <div className={style.slideHeader}>
        <div>
          <h1>Address Verification</h1>
          <p>Please upload your address verification document.</p>
        </div>
      </div>

      <div className={style.inputsBox}>
          <DropDownField
            label="Choose Document For Verification"
            name="addressVerificationDocument"
            required={true}
            options={addressVerificationDocuments}
            nameKey="name"
            valueKey="value"
            errorType={Errors.ADDRESS_VERIFICATION_DOCUMENT}
            handler={(value) => handleSelectDocType(value)}
          />

          <div className={style.inputContainer}>
            {selectedDocType === "aadhaar" ? (
              <AadhaarVerificationComponents
                activeSlide={activeSlide}
                updateLastVisitSlide={updateLastVisitSlide}
                switchToNextSlide={switchToNextSlide}
              />
            ) : selectedDocType ? (
              <div className={style.documentsInput}>
                <ImageInputField2
                  accept="image/jpg, image/jpeg, image/png"
                  allowedFileTypes="jpeg, jpg, png"
                  id="addressDocumentUpload"
                  file={document}
                  handler={handleDocument}
                  handleDelete={handleDocumentDelete}
                  error={documentStatus.error}
                  isLoading={
                    uploadCustomerDocumetAPI.isPending ||
                    verifyCustomerDocumentAPI.isPending
                  }
                />
              </div>
            ) : null}
          </div>
        </div>

      {selectedDocType !== "aadhaar" ? (
        <div className={style.slideActions}>
          <ContinueButton
            label="Next"
            loading={
              uploadCustomerDocumetAPI.isPending ||
              verifyCustomerDocumentAPI.isPending
            }
            handler={validateDocs}
          />
        </div>
      ) : null}
    </div>
  );
}
