// * Icons
import SpeedRoundedIcon from "@mui/icons-material/SpeedRounded";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";

export default function Features() {
  return (
    <div className="featuresListBox">
      <div className="introBox">
        <p>Get instant loan approval upto Rs.100000</p>
      </div>
      <div className="featuresList">
        <div className="feature">
          <div className="featureIcon">
            <SpeedRoundedIcon sx={{ color: "#2e3092" }} />
          </div>
          <p>100% online loan application</p>
        </div>
        <div className="feature">
          <div className="featureIcon">
            <CurrencyRupeeRoundedIcon sx={{ color: "#2e3092" }} />
          </div>
          <p>Applying online takes less than 5 minutes</p>
        </div>
        <div className="feature">
          <div className="featureIcon">
            <VerifiedRoundedIcon sx={{ color: "#2e3092" }} />
          </div>
          <p>Quick decision</p>
        </div>
      </div>
    </div>
  );
}
