import { Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "../store";

import Landing from "../pages/Landing";
// import CreditCard from "../pages/CreditCard";
import HDFCPrivacyPolicy from "../pages/HDFCPrivacyPolicy";
import ConsentV1 from "../pages/ConsentV1";
import ConsentV2 from "../pages/ConsentV2";
import NewJourney from "../pages/Journey";
// import OldJourney from "../oldJourney/Journey/JourneyPage";

export default function Router() {
  return (
    <Provider store={store}>
      {/* <Suspense fallback={<FallBack />}> */}
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route
          path="/customer/personal_loan/:apiKey/:apiSecret"
          element={<NewJourney />}
        />
        {/* <Route
            path="/customer/personal_loan/:apiKey/:apiSecret"
            element={<OldJourney />}
          /> */}
        <Route
          path="/customized_privacy_policy_for_roopya_in_relation_to_collection_of_information_for_HDFC_Bank"
          element={<HDFCPrivacyPolicy />}
        />
        <Route
          path="/consent_v1_in_relation_to_requested_products"
          element={<ConsentV1 />}
        />
        <Route
          path="/consent_v2_in_relation_to_other_products"
          element={<ConsentV2 />}
        />
        {/* <Route path="/credit_card" element={<CreditCard />} /> */}
      </Routes>
      {/* </Suspense> */}
    </Provider>
  );
}
