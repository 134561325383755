import { useState, createContext } from "react";

const ErrorContext = createContext({});

export function ErrorProvider({ children }) {
  const initialState = { error: false, type: "", message: "" };
  const [error, setError] = useState(initialState);

  const resetError = () => setError(initialState);

  return (
    <ErrorContext.Provider value={{ error, setError, resetError }}>
      {children}
    </ErrorContext.Provider>
  );
}

export default ErrorContext;
