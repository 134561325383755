import * as actions from "./types"

export function getOtp(param, resolve, reject) {
  return {
    type: actions.GET_OTP,
    payload: param,
    resolve,
    reject,
  };
}

export function resendOtp(param, resolve, reject) {
  return {
    type: actions.RESEND_OTP,
    payload: param,
    resolve,
    reject,
  };
}