// import axios from "axios";
import axiosWithDomain from "../../axios";

import { GET_BANK_DETAILS, PENNY_DROP } from "../../../constants/urls";

// * API to analyze customer's Bank Statement (Arya)
export const verifyBankAccount = async (Payload) => {
  const { data, headers } = Payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${PENNY_DROP}`,
    data,
    { headers }
  );
  return res?.data;
};

export const fetchBankDetails = async (Payload) => {
  const {data, headers} = Payload;
  
  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${GET_BANK_DETAILS}`,
    data,
    { headers }
  );
  return res?.data;
}
