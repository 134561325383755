import { useRef, useContext } from "react";
import { useParams } from "react-router-dom";

// * Packages
import { useCookies } from "react-cookie";
import queryString from "query-string";
import SlideStyle from "../../../../styles/Slide/style.module.css";
import TextInputField from "../../../DataInputFields/TextInputField/TextInputField";
import ContinueButton from "../../../Buttons/SlideContinueButton/ContinueButton";
import * as Errors from "../../../../utils/errors";
import { getCookiePathFromURL } from "../../../../helpers";
import { calculateAge } from "../../../../utils/common";
// * Utilities
import { validatePAN } from "../../../../utils";
import CustomerContext from "../../../../context/CustomerContext";
import ErrorContext from "../../../../context/ErrorProvider";
import { TOTAL_SLIDES } from "../../../../constants";
import { useMutation } from "@tanstack/react-query";
import {
  addAuthorizedSignatoryPAN,
  checkPhoneOnPan,
  fetchPANDetails,
} from "../../../../api/services/documents";
import { toast } from "react-toastify";
import { getLocationPermission } from "../../../../helpers";

const AuthorizedSignatoryPANSlide = ({
  ageRef,
  loading,
  setLoading,
  activeSlide,
  switchToNextSlide,
  setCustomerDetailsCookies,
  updateJourneyData,
}) => {
  const CookiePath = getCookiePathFromURL(window.location.href);
  let queryValues = queryString.parse(window.location.search);
  const JourneyVersion = queryValues?.jrnyTyp;
  const { apiKey, apiSecret } = useParams();

  // * Cookies
  const [_, setCookie] = useCookies(["user"]);

  // * Refs and States
  //   const fullnameRef = useRef("");
  const panRef = useRef("");
  //   const dobRef = useRef("");
  //   const [dob, setDob] = useState(new Date());

  const { customerDetails, setCustomerDetails } = useContext(CustomerContext);
  // * State to handle Journey error
  const { error, setError } = useContext(ErrorContext);

  const checkPhoneOnPanAPI = useMutation({
    mutationFn: (payload) => checkPhoneOnPan(payload),
    // onSuccess: (data) => handleSuccess(data),
    // onError: (error) => handleError(error),
    retry: false,
  });

  //   const fetchCompanyDetailsAPI = useMutation({
  //     mutationFn: (payload) => fetchCompanyDetails(payload),
  //     retry: false
  //   })

  const addAuthorizedSignatoryPANAPI = useMutation({
    mutationFn: (payload) => addAuthorizedSignatoryPAN(payload),
    onSuccess: (response) => addAuthorizePANSuccess(response),
    retry: false,
  });

  const addAuthorizePANSuccess = (response) => {
    console.log("response=>", response);
    if (JourneyVersion === "J2") {
      switchToNextSlide(45);
    } else {
      switchToNextSlide();
    }
  };

  const onSuccessHandler = (data) => {
    console.log("Data=>", data);
    if (data?.data?.status) {
      setError({
        error: false,
        type: "",
        message: "",
      });
    } else {
      setError({
        error: true,
        type: Errors.PAN_ERROR_TYPE,
        message: Errors.INVALID_PAN_ERROR,
      });
    }
  };

  const fetchPANDetailsAPI = useMutation({
    mutationFn: (payload) => fetchPANDetails(payload),
    onSuccess: (data) => onSuccessHandler(data),
    retry: false,
  });

  const handleSuccess = (data) => {
    const panData = data.data;
    if (panData.status === "1") {
      const mobileInPan = panData.result.mobile;
      const isAadhaarLinked = panData.result.aadharlink;
      if (JourneyVersion === "J3") {
        switchToNextSlide(10);
      } else {
        switchToNextSlide(activeSlide + 1);
      }
      if (mobileInPan == customerDetails.mobileNumber && isAadhaarLinked) {
      } else {
        setError({
          error: true,
          type: Errors.PAN_ERROR_TYPE,
          message: Errors.INVALID_PHONE_ERROR,
        });
      }
    } else if (
      panData.status == 2 ||
      panData.status == 3 ||
      panData.status == 4
    ) {
      setError({
        error: true,
        type: Errors.PAN_ERROR_TYPE,
        message: panData.message,
      });
    }
  };
  const handleError = (error) => {
    console.log("ERROR : ", error);
    toast.error("Something went wrong please try again.");
  };
  const handleCheckPhoneOnPan = async () => {
    const location = await getLocationPermission();
    const PAN = panRef.current.value;

    const headers = { apiKey, apiSecret };
    let payload = {
      headers,
      pan: PAN.toUpperCase(),
      contactId: customerDetails.contactID,
      mobileNumber: customerDetails.mobileNumber,
    };

    if (location) {
      payload = { ...payload, ...location };
    }
    checkPhoneOnPanAPI.mutate(payload);
  };

  // * Main Function
  async function addPAN() {
    const PAN = panRef.current.value;
    // if (!handlePAN(PAN, Errors.PAN_ERROR_TYPE)) {
    //   return false;
    // }
    if (!error.error) {
      setError({
        error: false,
        type: "",
        message: "",
      });
      setLoading(true);
      const data = {
        // pan: PAN.toUpperCase(),
      };
      const details = {
        pan: PAN,
      };
      setCustomerDetails({
        ...customerDetails,
        ...details,
      });
      const slidePayload = {
        slideName: "Authorized Signatory PAN",
        slideIndex: 43,
        slideIcon: "Pan",
        totalSlides: TOTAL_SLIDES,
      };
      const response = await updateJourneyData(data, slidePayload);

      if (response) {
        console.log("fetch data=>", response);
        setCustomerDetailsCookies(details);

        await handleCheckPhoneOnPan();

        const headers = { apiKey, apiSecret };
        data.headers = headers;
        data.contactId = customerDetails.contactID;
        data.pan = panRef.current.value;
        data.name = fetchPANDetailsAPI?.data?.data?.full_name;
        console.log(
          "Name=>",
          PAN,
          fetchPANDetailsAPI,
          fetchPANDetailsAPI?.data?.data?.full_name
        );
        addAuthorizedSignatoryPANAPI.mutate(data);
        // switchToNextSlide(activeSlide + 1);
      }
      setLoading(false);
    }
  }

  // * Handlers
  function handleFullName(fullName, errorType) {
    if (fullName === "") {
      setError({
        error: true,
        type: errorType,
        message: Errors.FULLNAME_ERROR,
      });
      return false;
    }
    if (/[0-9]/.test(fullName[0])) {
      setError({
        error: true,
        type: errorType,
        message: Errors.FULLNAME_DIGIT_ERROR,
      });
      return false;
    }
    if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(fullName)) {
      setError({
        error: true,
        type: errorType,
        message: Errors.FULLNAME_SPECIAL_CHARACTER_ERROR,
      });
      return false;
    }
    if (error.error && error.type === errorType) {
      setError({
        error: false,
        type: "",
        message: "",
      });
      return true;
    }
    return true;
  }

  function handleDOB(DOB, errorType) {
    setDob(DOB);

    if (DOB === "") {
      setError({
        error: true,
        type: errorType,
        message: Errors.DOB_ERROR,
      });
      return false;
    }
    const SeperateDOB = DOB.split("-");
    const currentYear = new Date().getFullYear();
    if (currentYear === SeperateDOB[0] || currentYear < SeperateDOB[0]) {
      setError({
        error: true,
        type: errorType,
        message: Errors.SAME_DATE_ERROR,
      });
      return false;
    }
    const Age = calculateAge(
      new Date(SeperateDOB[0], SeperateDOB[1], SeperateDOB[2])
    );
    if (Age < 18) {
      setError({
        error: true,
        type: errorType,
        message: Errors.DOB_NOT_ELIGIBLE_ERROR,
      });
      return false;
    }
    if (Age > 80) {
      setError({
        error: true,
        type: errorType,
        message: Errors.MAX_AGE_ERROR,
      });
      return false;
    }
    dobRef.current = DOB;
    if (error.error && error.type === errorType) {
      setError({
        error: false,
        type: "",
        message: "",
      });
      return true;
    }
    return true;
  }

  function handlePAN(PAN, errorType) {
    // if (PAN === "") {
    //   setError({
    //     error: true,
    //     type: errorType,
    //     message: Errors.PAN_ERROR,
    //   });
    //   return false;
    // }
    if (!validatePAN(PAN)) {
      setError({
        error: true,
        type: errorType,
        message: Errors.INVALID_PAN_ERROR,
      });
      return false;
    }
    if (error.error && error.type === errorType) {
      const data = {
        pan: PAN.toUpperCase(),
      };
      const headers = { apiKey, apiSecret };
      data.headers = headers;
      data.contactId = customerDetails.contactID;
      fetchPANDetailsAPI.mutate(data);
      setError({
        error: false,
        type: "",
        message: "",
      });
      return true;
    }
    return true;
  }

  return (
    <div className={SlideStyle.carouselSlideWrapper}>
      <div className={SlideStyle.typographyContainer}>
        <h1>PAN of Authorized Signatory</h1>
        {/* <h1>{fetchPANDetailsAPI}</h1> */}
        {/* <p>Please fill all the details carefully.</p> */}
      </div>
      {/* Input Fields */}
      <div className={SlideStyle.dataFieldsContainer}>
        {/* PAN */}
        <TextInputField
          type="text"
          label="PAN"
          maxLength="10"
          minLength="10"
          placeholder="Enter PAN"
          name="pan"
          id="pan"
          reference={panRef}
          // required={true}
          uppercase={true}
          errorType={Errors.PAN_ERROR_TYPE}
          handler={(pan) => handlePAN(pan, Errors.PAN_ERROR_TYPE)}
        />
        <p>
          {!fetchPANDetailsAPI?.isPending &&
            error.message == "" &&
            fetchPANDetailsAPI?.data?.data?.full_name}
        </p>
      </div>

      {/* Continue Slide Button */}
      <div className={SlideStyle.continueBtn}>
        <ContinueButton
          loading={fetchPANDetailsAPI?.isPending ? true : false}
          disabled={addAuthorizedSignatoryPANAPI.isPending}
          handler={addPAN}
        />
      </div>
    </div>
  );
};

export default AuthorizedSignatoryPANSlide;
